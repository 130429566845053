import { notifications } from "@mantine/notifications";
function showMessage(message: string) {
  notifications.show({
    withBorder: true,
    title: "Redux Notification",
    message: message,
    autoClose: 3000,
  });
}
function showError(error: any) {
  console.log(error);
  let message = error.response.data.message
    ? error.response.data.message
    : error.message + " - " + error.response.data.Title;
  notifications.show({
    withBorder: true,
    title: "Redux Error",
    message: message,
    color: "red",
  });
}

function showClientError(error: any) {
  let message = error.message;
  notifications.show({
    withBorder: true,
    title: "Redux Error",
    message: message,
    color: "red",
  });
}

const alerts = {
  showMessage,
  showError,
  showClientError,
};
export default alerts;
