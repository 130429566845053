import {
  Box,
  Button,
  Group,
  NativeSelect,
  Stepper,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import commonApi from "../../api/commonApi";
import { useEffect, useState } from "react";
import OptionItem from "../../types/common/optionItem";
import alerts from "../common/alerts";
import CreditorAddressManage from "./creditorAddressManage";
import Creditor from "../../types/creditor/creditor";

export default function CreditorAddForm() {
  const navigate = useNavigate();
  const form = useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [creditorToCreate, setCreditorToCreate] = useState<Creditor>({});

  const getCreditoForCreation = async () => {
    try {
      let data = await commonApi.getApiCall("/creditors/new");
      form.setValues(data);
    } catch (error) {}
  };

  const [currencyOptions, setCurrencyOptions] = useState<OptionItem[]>([]);
  const getCurrencyOptions = async () => {
    let data = null;
    try {
      commonApi.setPageNumber(1);
      commonApi.setPageSize(100);
      data = await commonApi.getApiCall("/currencies");
    } catch (error) {}

    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setCurrencyOptions(data2);
    }
  };

  useEffect(() => {
    getCreditoForCreation();
    getCurrencyOptions();
  }, []);

  const onSubmitHandler = async (values: any) => {
    let creditor = creditorToCreate;

    try {
      await commonApi.postApiCall("/creditors", creditor).then((response) => {
        alerts.showMessage("Creditor added");
        navigate("/Finance/Creditor/Edit/" + response.creditorID);
      });
    } catch (error) {
      alerts.showError(error);
    }
  };

  const handleNextStep = () => {
    if (currentStep < 2) {
      if (currentStep === 0) {
        //create creditor
        let creditor: Creditor = {
          creditorID: "0",
          creditorAddress: [],
          name: form.values.name ? form.values.name.toString() : "",
          phone: form.values.phone ? form.values.phone.toString() : "",
          email: form.values.email ? form.values.email.toString() : "",
          website: form.values.website ? form.values.website.toString() : "",
          currencyID: form.values.currencyID
            ? parseInt(form.values.currencyID.toString())
            : 0,
        };

        if (creditorToCreate.creditorID) {
          creditor.creditorAddress = creditorToCreate.creditorAddress;
        }

        setCreditorToCreate(creditor);
      }

      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <>
      <Box className="fade-in-block width100">
        <Stepper
          active={currentStep}
          onStepClick={setCurrentStep}
          orientation="horizontal"
        >
          <Stepper.Step label="Basic Information">
            <Box>
              <form
                onSubmit={form.onSubmit((values) => {
                  onSubmitHandler(values);
                })}
              >
                <Box className="fade-in-block width33">
                  <TextInput
                    withAsterisk
                    label="Name"
                    placeholder="Enter a creditor name"
                    {...form.getInputProps("name")}
                  />
                  <TextInput
                    withAsterisk
                    label="Phone"
                    placeholder="Enter a phone"
                    {...form.getInputProps("phone")}
                  />
                  <TextInput
                    withAsterisk
                    label="Email"
                    placeholder="Enter a email"
                    {...form.getInputProps("email")}
                  />
                  <TextInput
                    withAsterisk
                    label="Website"
                    placeholder="Enter a email"
                    {...form.getInputProps("website")}
                  />
                  <NativeSelect
                    label="Currency"
                    withAsterisk
                    data={currencyOptions}
                    {...form.getInputProps("currencyID")}
                  />
                </Box>
              </form>
            </Box>
          </Stepper.Step>
          <Stepper.Step label="Address Data">
            <CreditorAddressManage
              creditor={creditorToCreate}
              setCreditor={setCreditorToCreate}
            />
          </Stepper.Step>
        </Stepper>
        <Group justify="flex-end" mt="md">
          <Button onClick={handlePrevStep} disabled={currentStep === 0}>
            Back
          </Button>
          {currentStep < 1 ? (
            <Button onClick={handleNextStep} disabled={currentStep === 1}>
              Next
            </Button>
          ) : (
            <form onSubmit={form.onSubmit(onSubmitHandler)}>
              <Button type="submit">Add Creditor</Button>
            </form>
          )}

          <Button onClick={() => navigate("/Finance/Creditor/List")}>
            Cancel
          </Button>
        </Group>
      </Box>
    </>
  );
}
