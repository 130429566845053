import { Notification } from '@mantine/core';

function NotFound() {
  return (
    <Notification withCloseButton={false} withBorder color="red" radius="xs" title="404: Page Not Found">
        The page you are looking for cannot be found
    </Notification>
  );
}

export default NotFound;