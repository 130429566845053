import {
  Box,
  Button,
  Fieldset,
  Group,
  NativeSelect,
  SimpleGrid,
  Switch,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import alerts from "../../common/alerts";
import OptionItem from "../../../types/common/optionItem";
import AddressFormAdd from "../../address/addressFormAdd";

export default function SiteFormAdd() {
  const navigate = useNavigate();
  const form = useForm();
  const [taxSchedules, setTaxSchedules] = useState<OptionItem[]>([]);

  const getSiteForCreation = async () => {
    try {
      let data = await commonApi.getApiCall("/inventory/sites/new");
      form.setValues(data);
    } catch (error) {}
  };

  const getTaxSchedulesOptions = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/schedules");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setTaxSchedules(data2);
    }
  };
  const onSubmitHandler = async (values: any) => {
    try {
      var data = await commonApi.postApiCall("/inventory/sites", values);
      alerts.showMessage("Site added");
      navigate("/Inventory/Site/Edit/" + data.siteID);
    } catch (error) {
      alerts.showError(error);
    }
  };

  useEffect(() => {
    getSiteForCreation();
    getTaxSchedulesOptions();
  }, []);

  return (
    <Box>
      <form
        onSubmit={form.onSubmit((values) => {
          onSubmitHandler(values);
        })}
      >
        <SimpleGrid cols={2}>
          <Fieldset legend="Details">
            <TextInput
              name="name"
              label="Name"
              required
              {...form.getInputProps("name")}
            />

            <TextInput
              name="code"
              label="Code"
              required
              {...form.getInputProps("code")}
            />

            <TextInput
              name="phone"
              label="Phone"
              required
              {...form.getInputProps("phone")}
            />

            <TextInput name="fax" label="Fax" {...form.getInputProps("fax")} />

            <TextInput
              name="email"
              label="Email"
              required
              {...form.getInputProps("email")}
            />

            <Switch
              name="disabled"
              label="Disabled"
              {...form.getInputProps("disabled")}
            />
          </Fieldset>
          <Fieldset legend="Additional Properties">
            <AddressFormAdd form={form} />
            <NativeSelect
              name="salesTaxScheduleID"
              label="Sales Tax Schedule"
              description="The tax schedule to use for sales transactions"
              data={taxSchedules}
              {...form.getInputProps("salesTaxScheduleID")}
            />
            <NativeSelect
              name="purchaseTaxScheduleID"
              label="Purchase Tax Schedule"
              description="The tax schedule to use for purchase transactions"
              data={taxSchedules}
              {...form.getInputProps("purchaseTaxScheduleID")}
            />
            <Group justify="flex-end" mt="md">
              <Button
                onClick={(event) => {
                  navigate("/Inventory/Site/List");
                }}
              >
                Back
              </Button>
              <Button type="submit">Add</Button>
            </Group>
          </Fieldset>
        </SimpleGrid>
      </form>
    </Box>
  );
}
