import { useNavigate, useParams } from "react-router-dom";
import ExchangeRate from "../../types/company/exchangeRate";
import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import commonApi from "../../api/commonApi";
import alerts from "../common/alerts";
import {
  Box,
  Button,
  Group,
  NumberInput,
  Select,
  TextInput,
} from "@mantine/core";
import Currency from "../../types/company/currenty";

type SelectType = {
  value: string;
  label: string;
  disabled: boolean;
};

export default function ExchangeRateFormEdit() {
  const navigate = useNavigate();
  const [exchangeRate, setExchangeRate] = useState<ExchangeRate>(
    {} as ExchangeRate
  );
  const { exchangeRateId } = useParams();
  const [currencyList, setCurrencyList] = useState<Currency[]>([]);
  const [currencyData, setCurrencyData] = useState<SelectType[]>([]);

  const form = useForm({
    initialValues: {
      exchangeRateID: 0,
      companyID: 0,
      name: "",
      detail: "",
      rateValue: 0,
      sourceCurrencyID: "0",
      targetCurrencyID: "0",
    },
    validate: {
      name: (value) =>
        value.length < 1 ? "Name must have at least 1 characters" : null,
      detail: (value) =>
        value.length < 1 ? "Detail must have at least 1 characters" : null,
      rateValue: (value) =>
        value <= 0 ? "Rate value must be greater than zero" : null,
      sourceCurrencyID: (value) =>
        value === "0" || value === "" || !value
          ? "Please select source currency value"
          : null,
      targetCurrencyID: (value) =>
        value === "0" || value === "" || !value
          ? "Please select target currency value"
          : null,
    },
  });

  const getExchangeRateById = async () => {
    let data = null;
    try {
      await commonApi
        .getApiCall("/exchangerates/" + exchangeRateId)
        .then((response: any) => {
          if (response) {
            data = response;
            setExchangeRate(response);
            if (data) {
              form.setValues({
                companyID: data.companyID,
                exchangeRateID: data.exchangeRateID,
                name: data.name,
                detail: data.detail,
                sourceCurrencyID: data.sourceCurrencyID.toString(),
                targetCurrencyID: data.targetCurrencyID.toString(),
                rateValue: data.rateValue,
              });
            }
          }
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  let list: SelectType[] = [];
  const getCurrencies = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/currencies");
    } catch (error) {}

    if (data) {
      setCurrencyList(data);
      data.map((currency: Currency) => {
        let d: SelectType = {
          label: currency.name,
          value: currency.systemCurrencyID.toString(),
          disabled: false,
        };

        list.push(d);
      });
      setCurrencyData(list);
    }
  };

  const onSubmitHandler = async (values: any) => {
    try {
      await commonApi
        .putApiCall("/exchangerates/" + values.exchangeRateID, values)
        .then((response) => {
          if (response === "") {
            alerts.showMessage("Exchange rate details updated");
          }
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  useEffect(() => {
    getCurrencies();
    getExchangeRateById();
  }, []);

  return (
    <div>
      <Box className="fade-in-block width33">
        <form
          onSubmit={form.onSubmit((values) => {
            onSubmitHandler(values);
          })}
        >
          <TextInput
            withAsterisk
            label="Name"
            placeholder="exchange rate name"
            {...form.getInputProps("name")}
          />
          <TextInput
            withAsterisk
            label="Detail"
            placeholder="exchange rate detail"
            {...form.getInputProps("detail")}
          />
          <NumberInput
            label="Rate Value"
            placeholder="rate value"
            allowNegative={false}
            decimalScale={2}
            {...form.getInputProps("rateValue")}
          />
          <Select
            label="Source Currency"
            withAsterisk
            placeholder="source currency"
            data={currencyData}
            allowDeselect={false}
            clearable
            searchable
            nothingFoundMessage="Nothing found..."
            {...form.getInputProps("sourceCurrencyID")}
          />
          <Select
            label="Target Currency"
            withAsterisk
            placeholder="target currency"
            data={currencyData}
            allowDeselect={false}
            clearable
            searchable
            nothingFoundMessage="Nothing found..."
            {...form.getInputProps("targetCurrencyID")}
          />
          <Group justify="flex-end" mt="md">
            <Button
              onClick={(event) => {
                event.preventDefault();
                navigate("/ExchangeRate/List");
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Update</Button>
          </Group>
        </form>
      </Box>
    </div>
  );
}
