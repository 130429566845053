import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import commonApi from "../../../api/commonApi";
import LedgerView from "../../../types/generalLedger/ledgerView";
import PaginationSection from "../../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../../common/Sections/Pagination/PaginationSummary";
import LedgerSearch from "./ledgerSearch";
import { ActionIcon, Box, Group, Text } from "@mantine/core";
import { IconEdit, IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import { modals, openModal } from "@mantine/modals";
import { BrowserRouter, useNavigate } from "react-router-dom";
import LedgerFormView from "./ledgerFormView";
import extensionMethods from "../../../common/extensionMethods";
import alerts from "../../common/alerts";

export default function LedgerList() {
  const navigate = useNavigate();
  const [ledgerViews, setLedgerViews] = useState<LedgerView[]>([]);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [params, setParams] = useState({});
  const [totalRecords, setTotalRecords] = useState(1);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<LedgerView>>(
    {
      columnAccessor: "",
      direction: "asc",
    }
  );
  const getLedgerViewList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/LedgerViews?" + new URLSearchParams(params).toString()
      );
      if (data) {
        setTotalRecords(data.length);
        setLedgerViews(data);
      }
    } catch (error) {
      alerts.showClientError(error);
    }
  };

  useEffect(() => {
    getLedgerViewList();
  }, [sortStatus, page, pageSize, params]);

  function showViewModal(ledgerID: number) {
    openModal({
      modalId: "edit",
      title: "Ledger View Popup",
      size: "100vw",
      children: (
        <BrowserRouter>
          <LedgerFormView id={ledgerID.toString()} />
        </BrowserRouter>
      ),
    });
  }
  return (
    <div className="fade-in-block width100">
      <LedgerSearch setParamsFunction={setParams} />
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        records={ledgerViews}
        idAccessor="LedgerID"
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        columns={[
          {
            accessor: "subLedgerName",
            title: "Detail",
            textAlign: "left",
            sortable: true,
            render: (ledgerView) => ledgerView.subLedgerName,
          },
          {
            accessor: "postingYearName",
            title: "Year",
            textAlign: "left",
            sortable: true,
            width: "8%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "postingPeriodName",
            title: "Period",
            textAlign: "left",
            sortable: true,
            width: "8%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "postingDate",
            title: "Date",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: ({ postingDate }) =>
              extensionMethods.dateDisplay(postingDate),
            width: "8%",
          },
          {
            accessor: "subLedgerTypeName",
            title: "Type",
            textAlign: "left",
            sortable: true,
            width: "8%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: (ledgerView) =>
              ledgerView.subLedgerTypeName +
              " " +
              ledgerView.subLedgerTypeSourceID,
          },
          {
            accessor: "creditSourceTotal",
            title: "Source Credit",
            textAlign: "right",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: (ledgerView) =>
              extensionMethods.decimalDisplay(
                ledgerView.creditSourceTotal,
                true
              ) +
              (ledgerView.creditSourceTotal === 0
                ? ""
                : " " + ledgerView.sourceCurrencyCode),
            width: "7.5%",
          },
          {
            accessor: "targetSourceTotal",
            title: "Target Credit",
            textAlign: "right",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: (ledgerView) =>
              extensionMethods.decimalDisplay(
                ledgerView.creditTargetTotal,
                true
              ) +
              (ledgerView.creditTargetTotal === 0
                ? ""
                : " " + ledgerView.targetCurrencyCode),
            width: "7.5%",
          },
          {
            accessor: "debitSourceTotal",
            title: "Source Debit",
            textAlign: "right",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: (ledgerView) =>
              extensionMethods.decimalDisplay(
                ledgerView.debitSourceTotal,
                true
              ) +
              (ledgerView.debitSourceTotal === 0
                ? ""
                : " " + ledgerView.sourceCurrencyCode),
            width: "7.5%",
          },
          {
            accessor: "debitTargetTotal",
            title: "Target Debit",
            textAlign: "right",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: (ledgerView) =>
              extensionMethods.decimalDisplay(
                ledgerView.debitTargetTotal,
                true
              ) +
              (ledgerView.debitTargetTotal === 0
                ? ""
                : " " + ledgerView.targetCurrencyCode),
            width: "7.5%",
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (ledgerView) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showViewModal(ledgerView.ledgerID);
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/Ledger/View/" + record.ledgerID);
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
