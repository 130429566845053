import {
  TextInput,
  Checkbox,
  Button,
  Group,
  Box,
  NativeSelect,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState, useEffect } from "react";
import OptionItem from "../../../types/common/optionItem";
import ButtonGroup from "../../common/ButtonGroup";
import SearchSection from "../../common/Sections/Search/SearchSection";

export default function PostingPeriodSearch(props: any) {
  const form = useForm({
    initialValues: {
      search: "",
      open: "",
    },
  });
  const [statusItems, setStatusItems] = useState<OptionItem[]>([]);
  useEffect(() => {
    setStatusItems([
      { label: "All", value: "" },
      { label: "Open", value: "true" },
      { label: "Closed", value: "false" },
    ]);
  }, []);
  return (
    <form onSubmit={form.onSubmit((values) => props.setParamsFunction(values))}>
      <SearchSection
        buttonSection={
          <ButtonGroup
            cancelLabel="Reset"
            cancelClick={form.reset}
            okLabel="Search"
          />
        }
      >
        <TextInput
          label="Name"
          placeholder="search by name"
          {...form.getInputProps("search")}
        />
        <NativeSelect
          label="Status"
          data={statusItems}
          {...form.getInputProps("open")}
        />
      </SearchSection>
    </form>
  );
}
