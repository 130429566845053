import { useForm } from "@mantine/form";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import alerts from "../../common/alerts";
import { Box, TextInput, Group, Button } from "@mantine/core";
import AddressFormAdd from "../../address/addressFormAdd";

export default function BankFormEdit({
  id,
  setState,
}: {
  id?: string;
  setState?: Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const form = useForm();
  const { bankId } = useParams();
  let isPopup: boolean = true;
  if (id == null) {
    isPopup = false;
    id = bankId;
  }
  const getBankById = async () => {
    try {
      let data = await commonApi.getApiCall("/banks/" + id);
      form.setValues(data);
    } catch (error) {}
  };
  useEffect(() => {
    getBankById();
  }, [id]);
  const onSubmitHandler = async (values: any) => {
    try {
      await commonApi.putApiCall("/banks/" + id, values).then(() => {
        alerts.showMessage("Bank updated");
        if (isPopup) {
          if (setState) {
            setState(true);
          }
        }
        navigate("/Bank/List");
      });
    } catch (error) {
      alerts.showError(error);
    }
  };
  return (
    <>
      <form
        onSubmit={form.onSubmit((values) => {
          onSubmitHandler(values);
        })}
      >
        <Box className="fade-in-block width50">
          <TextInput
            withAsterisk
            label="Bank Name"
            placeholder="Enter the bank name"
            {...form.getInputProps("bankName")}
          />
          <TextInput
            withAsterisk
            label="Contact Name"
            placeholder="Enter the name of the bank contact"
            {...form.getInputProps("contactName")}
          />
          <TextInput
            withAsterisk
            label="Email Address"
            placeholder="Enter the email address to contact this bank"
            {...form.getInputProps("emailAddress")}
          />
          <TextInput
            withAsterisk
            label="Phone Number"
            placeholder="Enter the phone number to contact this bank"
            {...form.getInputProps("phoneNumber")}
          />
        </Box>
        <Box className="fade-in-block width50">
          <AddressFormAdd form={form} />
          <Group justify="flex-end" mt="md">
            <Button
              onClick={(event) => {
                navigate("/Bank/List");
              }}
            >
              Back
            </Button>
            <Button type="submit">Update</Button>
          </Group>
        </Box>
      </form>
    </>
  );
}
