import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import { useForm } from "@mantine/form";
import { Box, TextInput, Group, Button, Switch } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import OptionItem from "../../../types/common/optionItem";
import alerts from "../../common/alerts";
import { DateInput } from "@mantine/dates";
import extensionMethods from "../../../common/extensionMethods";

export default function PostingYearFormEdit({
    id,
    setState
  }: {
    id?: string;
    setState?: Dispatch<SetStateAction<boolean>>;
  })
  {
    const navigate = useNavigate();
    const form = useForm({
      initialValues: {
          postingYearID: 0,
          name: "",
          startDate: new Date(),
          endDate: new Date(),
          open: true
      }        
    });  
    const {postingYearID} = useParams();
    let isPopup: boolean = true;
    if (id == null){
      isPopup = false;
      id = postingYearID;
    } 
    const [open, setOpen] = useState(true);
    const getPostingYearById = async () => {
      try {
        let data = await commonApi.getApiCall("/posting/years/" + id);
        setOpen(data.open);
        form.setValues({
          postingYearID: data.postingYearID,
          name: data.name,
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate),
          open: data.open});   
      } catch (error) {}
    };    
    useEffect(() => {      
      getPostingYearById();
    }, 
    []);    
    const onSubmitHandler = async (values: any) => {
      try {
        values.endDate = extensionMethods.dateMinusTimezoneOffset(values.endDate);
        values.startDate = extensionMethods.dateMinusTimezoneOffset(values.startDate);
        values.open = open;
        await commonApi.putApiCall("/posting/years/" + id, values).then(() => {
          alerts.showMessage("Posting Year updated");
          if (isPopup) {
            if (setState){
              setState(true);
            }
            closeAllModals();
          } 
          else {
            navigate("/PostingYear/List");
          }
        });
      } 
      catch (error) {
        alerts.showError(error);
      }
    };    
    return (
      <>
        <Box mx="auto">
          <form
            onSubmit={form.onSubmit((values) => {
              onSubmitHandler(values);
            })}>
            <TextInput
              withAsterisk
              label="Name"
              placeholder="Name of Posting Year"
              {...form.getInputProps("name")}/>     
            <DateInput
                withAsterisk
                label="Start of Posting Year"
                placeholder="from date"
                {...form.getInputProps("startDate")}/>      
            <DateInput
                withAsterisk
                label="End of Posting Year"
                placeholder="to date"
                {...form.getInputProps("endDate")}/>     
            <Switch
              label="Open"
              checked={open}
              onChange={(event) => setOpen(event.currentTarget.checked)}/> 
            <Group justify="flex-end" mt="md">
              <Button
                onClick={(event) => {
                  if (isPopup) {
                    closeAllModals();
                  } else {
                    event.preventDefault();
                    navigate("/PostingYear/List");
                  }
                }}>
                Back
              </Button>
              <Button type="submit">Update</Button>
            </Group>
          </form>
        </Box>
      </>
    );
  }