import { Box, ActionIcon, Group, Button, Text } from "@mantine/core";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import Company from "../../types/company/company";
import { IconEdit, IconTrash, IconMoodConfuzed } from "@tabler/icons-react";
import { modals, openModal } from "@mantine/modals";
import { BrowserRouter, useNavigate } from "react-router-dom";
import PaginationSection from "../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../common/Sections/Pagination/PaginationSummary";
import commonApi from "../../api/commonApi";
import AdminCompanySearch from "./adminCompanySearch";
import AdminCompanyFormEdit from "./adminCompanyFormEdit";
import alerts from "../common/alerts";

function showModal(
  companyID: number,
  setState?: Dispatch<SetStateAction<boolean>>
) {
  openModal({
    modalId: "edit",
    title: "Showing user information",
    children: (
      <BrowserRouter>
        <AdminCompanyFormEdit id={companyID} setState={setState} />
      </BrowserRouter>
    ),
  });
}

export default function AdminCompanyList() {
  const navigate = useNavigate();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Company>>({
    columnAccessor: "name",
    direction: "asc",
  });
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [totalRecords, setTotalRecords] = useState(1);
  const [params, setParams] = useState({});
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<Company[]>([]);

  const getCompanyList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/admin/companyViews?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      setTotalRecords(data.length);
      setCompanies(data);
    }
  };

  const openDeleteModal = (company?: Company, selectedRecords?: Company[]) => {
    modals.openConfirmModal({
      title: "Delete company",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this company record/s.
        </Text>
      ),
      labels: { confirm: "Delete user", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        if (company) {
          if (company.companyID) {
            try {
              await commonApi
                .deleteApiCall("/companies/" + company.companyID)
                .then(() => {
                  alerts.showMessage("Company deleted");
                  getCompanyList();
                });
            } catch (error) {
              alerts.showError(error);
            }
          }
        }

        if (selectedRecords) {
          let selectedCount = selectedRecords.length;
          let counter = 0;
          selectedRecords.forEach(async (company) => {
            if (company.companyID) {
              await commonApi
                .deleteApiCall("/companies/" + company.companyID)
                .then(() => {
                  counter++;
                });

              if (counter === selectedCount) {
                alerts.showMessage("Company delete success");
                setSelectedRecords([]);
                getCompanyList();
              }
            }
          });
        }
      },
    });
  };

  useEffect(() => {
    getCompanyList();
  }, [isSuccessUpdate, sortStatus, page, pageSize, params]);

  return (
    <div>
      <AdminCompanySearch setParamsFunction={setParams} />
      <Group mt="md" justify="flex-end">
        <Button
          onClick={() => {
            navigate("/Admin/Company/Add");
          }}
        >
          Add
        </Button>
        <Button
          onClick={() => {
            if (selectedRecords.length > 0) {
              openDeleteModal(undefined, selectedRecords);
            } else {
              alerts.showClientError({
                message: "No selected record to delete",
              });
            }
          }}
        >
          Delete
        </Button>
      </Group>
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        records={companies}
        idAccessor="companyID"
        isRecordSelectable={(record) => record.canBeDeleted === true}
        pinLastColumn
        columns={[
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "companyGroupName",
            title: "Company Group",
            textAlign: "left",
            sortable: true,
            width: "15%",
          },
          {
            accessor: "currencyName",
            title: "Currency",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "postingPeriod",
            title: "Open Posting Period",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "userCount",
            title: "Users",
            textAlign: "center",
            sortable: true,
            width: "5%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.lg})`,
          },
          {
            accessor: "accountCount",
            title: "Accounts",
            textAlign: "center",
            sortable: true,
            width: "5%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.lg})`,
          },
          {
            accessor: "status",
            title: "Status",
            textAlign: "center",
            sortable: true,
            width: "5%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.lg})`,
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (company) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showModal(company.companyID, setIsSuccessUpdate);
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  disabled={!company.canBeDeleted}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    openDeleteModal(company);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/Admin/Company/Edit/" + record.companyID);
        }}
        selectedRecords={selectedRecords}
        onSelectedRecordsChange={setSelectedRecords}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
