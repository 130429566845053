import { Box, Button, Group, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import Currency from "../../../types/company/currenty";
import alerts from "../../common/alerts";
import { useState } from "react";

export default function CurrencyForm() {
  const navigate = useNavigate();
  const [currency, setCurrency] = useState<Currency>({} as Currency);

  const form = useForm({
    initialValues: {
      systemCurrencyID: 0,
      name: "",
      symbol: "",
      isoCode: "",
      fractionalUnit: "",
    },
    validate: {
      name: (value) =>
        value.length < 1 ? "Name must have at least 1 characters" : null,
      symbol: (value) =>
        value.length < 1 ? "Symbol must have at least 1 characters" : null,
      isoCode: (value) =>
        value.length < 1 ? "ISO code must have at least 1 characters" : null,
      fractionalUnit: (value) =>
        value.length < 1
          ? "Fractional Unit must have at least 1 characters"
          : null,
    },
  });

  const onSubmitHandler = async (values: any) => {
    let currency: Currency = {
      systemCurrencyID: 0,
      name: values.name,
      symbol: values.symbol,
      isoCode: values.isoCode,
      fractionalUnit: values.fractionalUnit,
    };

    try {
      await commonApi.postApiCall("/currencies", currency).then((response) => {
        navigate("/Admin/Currency/List");
      });
    } catch (error) {
      alerts.showError(error);
    }
  };

  return (
    <div>
      <Box className="fade-in-block width33">
        <form
          onSubmit={form.onSubmit((values) => {
            onSubmitHandler(values);
          })}
        >
          <TextInput
            withAsterisk
            label="Name"
            placeholder="name"
            {...form.getInputProps("name")}
          />
          <TextInput
            withAsterisk
            label="Symbol"
            placeholder="symbol"
            {...form.getInputProps("symbol")}
          />
          <TextInput
            withAsterisk
            label="ISO Code"
            placeholder="iso code"
            {...form.getInputProps("isoCode")}
          />
          <TextInput
            withAsterisk
            label="Fractional Unit"
            placeholder="exchange rate detail"
            {...form.getInputProps("fractionalUnit")}
          />
          <Group justify="flex-end" mt="md">
            <Button
              onClick={(event) => {
                event.preventDefault();
                navigate("/Currency/List");
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Submit</Button>
          </Group>
        </form>
      </Box>
    </div>
  );
}
