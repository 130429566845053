import { TextInput, Button, Group, NativeSelect, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import OptionItem from "../../types/common/optionItem";
import commonApi from "../../api/commonApi";
import Account from "../../types/generalLedger/account";
import alerts from "../common/alerts";
import SearchSection from "../common/Sections/Search/SearchSection";
import ButtonGroup from "../common/ButtonGroup";

type SelectType = {
  value: string;
  label: string;
};

export default function RateDetailSearch(props: any) {
  const [accountSelectData, setAccountSelectData] = useState<SelectType[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const form = useForm({
    initialValues: {
      name: "",
      accountID: "",
    },
  });

  const getAccountsList = async () => {
    let data = null;
    let accountList: SelectType[] = [];
    let defaultValue: SelectType = {
      label: "All",
      value: "",
    };
    accountList.push(defaultValue);

    try {
      await commonApi.getApiCall("/accounts").then((response) => {
        data = response;
        if (data) {
          data.map((account: Account) => {
            let a: SelectType = {
              label: account.name,
              value: account.accountID.toString(),
            };
            accountList.push(a);
          });
          setAccountSelectData(accountList);
          setAccounts(data);
        }
      });
    } catch (error) {
      alerts.showError(error);
    }
  };

  useEffect(() => {
    getAccountsList();
  }, []);

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        props.setParamsFunction(values);
      })}
    >
      <SearchSection
        buttonSection={
          <ButtonGroup
            cancelLabel="Reset"
            cancelClick={form.reset}
            okLabel="Search"
          />
        }
      >
        <TextInput
          label="Name"
          placeholder="search by rate name"
          {...form.getInputProps("name")}
        />
        <NativeSelect
          label="Account"
          data={accountSelectData}
          {...form.getInputProps("accountID")}
        />
      </SearchSection>
    </form>
  );
}
