import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import RateDetail from "../../types/tax/rateDetail";
import commonApi from "../../api/commonApi";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import alerts from "../common/alerts";
import { Box, Button, Group, Text } from "@mantine/core";
import PaginationSection from "../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../common/Sections/Pagination/PaginationSummary";
import { IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import { closeAllModals, closeModal } from "@mantine/modals";
import ScheduleRateDetail from "../../types/tax/scheduleRateDetail";
import RateDetailSearch from "./rateDetailSearch";

export default function RateDetailListPopup({
  scheduleID,
  assignedRates,
  setState,
  isSuccessUpdate,
  isPopup,
}: {
  scheduleID?: number;
  assignedRates?: ScheduleRateDetail[];
  setState?: Dispatch<SetStateAction<ScheduleRateDetail[]>>;
  isSuccessUpdate?: Dispatch<SetStateAction<boolean>>;
  isPopup?: boolean;
}) {
  const navigate = useNavigate();
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [totalRecords, setTotalRecords] = useState(1);
  const [params, setParams] = useState({});
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<RateDetail>>(
    {
      columnAccessor: "",
      direction: "asc",
    }
  );
  const [selectedRecords, setSelectedRecords] = useState<RateDetail[]>([]);
  const [rateDetails, setRateDetails] = useState<RateDetail[]>([]);
  const [buttonText, setButtonText] = useState("Add");

  const refreshSelectedRecords = () => {
    if (assignedRates) {
      var rateDetails: RateDetail[] = [];
      assignedRates.forEach((item) => {
        var rateDetail: RateDetail = {
          rateDetailID: item.rateDetailID,
          name: item.name,
          ratePercentage: item.ratePercentage,
        };
        rateDetails.push(rateDetail);
      });
      setSelectedRecords(rateDetails);
    }
  };

  const getRatedetailList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      await commonApi
        .getApiCall("/ratedetails?" + new URLSearchParams(params).toString())
        .then((response) => {
          data = response;
          if (data) {
            setTotalRecords(data.length);
            setRateDetails(data);
            if (assignedRates) {
              var rateDetails: RateDetail[] = [];
              assignedRates.forEach((item) => {
                var rateDetail: RateDetail = {
                  rateDetailID: item.rateDetailID,
                  name: item.name,
                  ratePercentage: item.ratePercentage,
                };
                rateDetails.push(rateDetail);
              });
              setSelectedRecords(rateDetails);
            }
          }
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  useEffect(() => {
    if (scheduleID) setButtonText("Update");
    getRatedetailList();
  }, [assignedRates, sortStatus, page, pageSize, params]);

  return (
    <div>
      <RateDetailSearch setParamsFunction={setParams} />
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        pinLastColumn
        idAccessor="rateDetailID"
        records={rateDetails}
        columns={[
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "account.name",
            title: "Account",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "ratePercentage",
            title: "Rate Percentage",
            textAlign: "left",
            sortable: true,
          },
        ]}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        selectedRecords={selectedRecords}
        onSelectedRecordsChange={setSelectedRecords}
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
      <Group justify="flex-end" mt="md">
        <Button
          onClick={(event) => {
            event.preventDefault();
            if (isPopup) {
              closeModal("editratedetailpopup");
            } else {
              closeAllModals();
            }
            refreshSelectedRecords();
          }}
        >
          Back
        </Button>
        <Button
          onClick={async (event) => {
            event.preventDefault();
            if (setState) {
              var selectedSchedRate: ScheduleRateDetail[] = [];
              selectedRecords.forEach((item) => {
                var schedRateDetail: ScheduleRateDetail = {
                  name: item.name,
                  ratePercentage: item.ratePercentage,
                  rateDetailID: item.rateDetailID,
                  scheduleID: scheduleID ? scheduleID : 0,
                  canBeDeleted: true,
                };
                selectedSchedRate.push(schedRateDetail);
              });
              setState(selectedSchedRate);

              if (scheduleID) {
                try {
                  await commonApi
                    .putApiCall(
                      "/scheduleratedetails/" + scheduleID,
                      selectedSchedRate
                    )
                    .then((resp) => {
                      if (isSuccessUpdate) isSuccessUpdate(true);
                      if (isPopup) {
                        closeModal("editratedetailpopup");
                      } else {
                        closeAllModals();
                      }
                    });
                } catch (error) {
                  alerts.showError(error);
                }
              } else {
                closeAllModals();
              }
            }
          }}
          type="submit"
        >
          {buttonText}
        </Button>
      </Group>
    </div>
  );
}
