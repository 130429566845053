import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import { useForm } from "@mantine/form";
import { Box, TextInput, NativeSelect, Button, Group, Textarea } from "@mantine/core";
import OptionItem from "../../../types/common/optionItem";
import alerts from "../../common/alerts";
import { DateInput } from "@mantine/dates";
import extensionMethods from "../../../common/extensionMethods";

export default function PostingYearFormAdd()
  {
    const navigate = useNavigate();   
    const form = useForm({
        initialValues: {
            name: "",
            startDate: new Date(),
            endDate: new Date()
        }        
      }); 
    const getNextPostingYear = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/posting/years/next");
      } catch (error) {}
      if (data) {
          form.setValues({
              name: data.name,
              startDate: new Date(data.startDate),
              endDate: new Date(data.endDate)});
      }
    };   
    useEffect(() => {      
        getNextPostingYear();
    }, 
    []);    
    const onSubmitHandler = async (values: any) => {
      try {
        values.endDate = extensionMethods.dateMinusTimezoneOffset(values.endDate);
        values.startDate = extensionMethods.dateMinusTimezoneOffset(values.startDate);
        await commonApi.postApiCall("/posting/years", values).then(() => {
            alerts.showMessage("Posting Year added");
            navigate("/PostingYear/List");
        });
      } 
      catch (error) {
        alerts.showError(error);
      }
    };    
    return (
      <>
        <Box mx="auto">
          <form
            onSubmit={form.onSubmit((values) => {
                onSubmitHandler(values);
            })}>   
            <TextInput
                withAsterisk
                label="Name"
                placeholder="Name of Posting Year"
                {...form.getInputProps("name")}/>      
            <DateInput
                withAsterisk
                label="Start of Posting Year"
                placeholder="from date"
                {...form.getInputProps("startDate")} />
            <DateInput
                withAsterisk
                label="End of Posting Year"
                placeholder="to date"
                {...form.getInputProps("endDate")} />
            <Group justify="flex-end" mt="md">
                <Button
                    onClick={(event) => {
                        navigate("/PostingYear/List")
                    }}>
                    Back
                </Button>
                <Button type="submit">Add</Button>
            </Group> 
          </form>
        </Box>
      </>
    );
  }