import { Group, Flex, Pagination, Select } from "@mantine/core";
import { Dispatch, SetStateAction, useEffect } from "react";
import commonApi from "../../../../api/commonApi";
import styles from "./Pagination.module.css";

type PaginationSectionProps = {
  page: number;
  setPageFunction: Dispatch<SetStateAction<number>>;
  pageSize: number;
  setPageSizeFunction: Dispatch<SetStateAction<string | null>>;
  recordCount: number;
};

const PaginationSection = ({
  page,
  setPageFunction,
  pageSize,
  setPageSizeFunction,
  recordCount,
}: PaginationSectionProps) => {
  const SIZES = [5, 10, 20, 50, 100, 200, 500];
  const sizesData = SIZES.map((size) => {
    return { value: size.toString(), label: `${size}` };
  });

  useEffect(() => {
    setPageFunction(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalRecords = recordCount === 0 ? 0 : commonApi.getRecordCount();

  const totalPages =
    totalRecords === 0 ? 1 : Math.ceil(totalRecords / pageSize);

  return (
    <Flex justify="space-between" mb="sm" mt="md">
      <Group>
        <span>Entries per page: </span>
        <Select
          classNames={{ root: styles.selectorPageSize }}
          data={sizesData}
          defaultValue={pageSize.toString()}
          onChange={(size) => {
            setPageFunction(1);
            setPageSizeFunction(size);
          }}
        />
      </Group>

      <Pagination.Root
        total={totalPages}
        value={page}
        onChange={setPageFunction}
        siblings={1}
        boundaries={2}
      >
        <Group gap={5} justify="flex-end">
          <Pagination.First />
          <Pagination.Previous />
          <Pagination.Items />
          <Pagination.Next />
          <Pagination.Last />
        </Group>
      </Pagination.Root>
    </Flex>
  );
};
export default PaginationSection;
