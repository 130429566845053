import {
  TextInput,
  Checkbox,
  Button,
  Group,
  Box,
  NativeSelect,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState, useEffect } from "react";
import OptionItem from "../../types/common/optionItem";
import commonApi from "../../api/commonApi";
import ButtonGroup from "../common/ButtonGroup";
import SearchSection from "../common/Sections/Search/SearchSection";

export default function JournalSearch(props: any) {
  const form = useForm({
    initialValues: {
      search: "",
      journalTypeID: 0,
      transctionTypeID: 0,
    },
  });
  const [journalTypes, setJournalTypes] = useState<OptionItem[]>([]);
  const [transactionTypes, setTransactionTypes] = useState<OptionItem[]>([]);
  const getJournalTypeList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/journal/types");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { name: string; journalTypeID: string }) => ({
          label: s.name,
          value: s.journalTypeID,
        })
      );
      data2.splice(0, 0, { label: "All", value: "0" });
      setJournalTypes(data2);
    }
  };
  const getTranactionTypeList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/journal/system/transactiontypes");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { name: string; systemTransactionTypeID: string }) => ({
          label: s.name,
          value: s.systemTransactionTypeID,
        })
      );
      data2.splice(0, 0, { label: "All", value: "0" });
      setTransactionTypes(data2);
    }
  };
  useEffect(() => {
    getJournalTypeList();
    getTranactionTypeList();
  }, []);
  return (
    <form onSubmit={form.onSubmit((values) => props.setParamsFunction(values))}>
      <SearchSection
        buttonSection={
          <ButtonGroup
            cancelLabel="Reset"
            cancelClick={form.reset}
            okLabel="Search"
          />
        }
      >
        <TextInput
          label="Name"
          placeholder="search by name"
          {...form.getInputProps("search")}
        />
        <NativeSelect
          label="Journal Type"
          data={journalTypes}
          {...form.getInputProps("journalTypeID")}
        />
        <NativeSelect
          label="Transation Type"
          data={transactionTypes}
          {...form.getInputProps("transactionTypeID")}
        />
      </SearchSection>
    </form>
  );
}
