import { Flex, Burger, AppShell, Title, Image } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import UserMenu from "../components/user/UserMenu";
import styles from "./Navigation.module.css";

const Header = ({ toggle, opened }: any) => {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate("/");
  };

  return (
    <AppShell.Header>
      <Flex
        justify="space-between"
        align="center"
        style={{ padding: "10px 20px" }}
        classNames={{ root: styles.headerContainer }}
      >
        <Burger opened={opened} onClick={toggle} hiddenFrom="sm" />
        <Flex justify={"space-between"} align={"center"} onClick={navigateHome}>
          <Image src="/logo192.png" h={30} w="auto" pr={20} />
          <Title order={2} mb={0}>
            GP Redux
          </Title>
        </Flex>
        <UserMenu />
      </Flex>
    </AppShell.Header>
  );
};

export default Header;
