import { useForm } from "@mantine/form";
import SearchSection from "../common/Sections/Search/SearchSection";
import { TextInput } from "@mantine/core";
import ButtonGroup from "../common/ButtonGroup";

function CreditorSearch(props: any) {
    const form = useForm({
        initialValues: {
            search: ""
        }
    })
    return (
        <form onSubmit={form.onSubmit((values) => props.setParamsFunction(values))}>
          <SearchSection
            buttonSection={
              <ButtonGroup
                cancelLabel="Reset"
                cancelClick={form.reset}
                okLabel="Search"
              />
            }
          >
            <TextInput
              label="Search"
              placeholder="search by name, contact, phone, email etc.."
              {...form.getInputProps("search")}
            />
          </SearchSection>
        </form>
      );
}

export default CreditorSearch