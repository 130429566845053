import { useContext } from "react";
import { AuthContext, AuthContextProps } from "./AuthContext";
import { useLocalStorage } from "../common/useLocalStorage";
import LoggedUser from "../../types/login/loggedUser";

export const useUser = () => {
  const { user, updateUser } = useContext(AuthContext) as AuthContextProps;
  const { setItem } = useLocalStorage();

  const addUser = (user: LoggedUser) => {
    updateUser(user);
    setItem("user", JSON.stringify(user));
  };

  const removeUser = () => {
    updateUser({
      email: "",
      family_name: "",
      given_name: "",
      hd: "",
      name: "",
      picture: "",
    });
    setItem("user", "");
  };

  return { user, addUser, removeUser, updateUser };
};
