import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  BrowserRouter,
  NavLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import commonApi from "../../api/commonApi";
import { useForm } from "@mantine/form";
import {
  Box,
  TextInput,
  NumberInput,
  NativeSelect,
  Button,
  Group,
  Text,
  ActionIcon,
  Menu,
} from "@mantine/core";
import OptionItem from "../../types/common/optionItem";
import alerts from "../common/alerts";
import { DateInput } from "@mantine/dates";
import extensionMethods from "../../common/extensionMethods";
import JournalReversingDate from "./journalReversingDate";
import { closeAllModals, modals, openModal } from "@mantine/modals";
import JournalDetail from "../../types/journal/journalDetail";
import { IconMoodConfuzed, IconEdit, IconTrash } from "@tabler/icons-react";
import { DataTable } from "mantine-datatable";
import JournalDetailFormEdit from "./journalDetailFormEdit";
import JournalDetailFormAdd from "./journalDetailFormAdd";
import LedgerFormPreview from "../generalLedger/ledger/ledgerFormPreview";
import LedgerFormView from "../generalLedger/ledger/ledgerFormView";
import Label from "../common/label";
import ReadOnly from "../common/readOnly";
import NavLinkIcon from "../../navigation/navLinkIcon";
import Journal from "../../types/journal/journal";
export default function JournalFormEdit({
  id,
  setState,
}: {
  id?: string;
  setState?: Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const form = useForm({
    initialValues: {
      journalID: 0,
      subLedgerTypeID: 0,
      status: "",
      detail: "",
      exchangeRateID: 0,
      journalDate: new Date(),
      journalTypeID: 0,
      transactionTypeID: 1,
      reversingDate: new Date(),
      sourceCurrencyCode: "",
      targetCurrencyCode: "",
      ledgerID: 0,
      createdByName: "",
      createdDate: new Date(),
      updatedByName: "",
      updatedDate: new Date(),
      canPost: false,
      canUpdate: false,
      canPreviewGL: false,
      canViewGL: false,
      canDelete: false,
      canReverse: false,
    },
  });
  const { journalID } = useParams();
  let isPopup: boolean = true;
  if (id == null) {
    isPopup = false;
    id = journalID;
  }
  const [transactionTypeID, setTransactionTypeID] = useState(0);
  const [journalDetails, setJournalDetails] = useState<JournalDetail[]>([]);
  const [reversedJournals, setReversedJournals] = useState<Journal[]>([]);
  const [sourceJournals, setSourceJournals] = useState<Journal[]>([]);
  const getJournalById = async () => {
    try {
      let data = await commonApi.getApiCall("/journals/" + id);
      setTransactionTypeID(data.transactionTypeID);
      setJournalDetails(data.journalDetails);
      setReversedJournals(data.reversedJournals);
      setSourceJournals(data.sourceJournals);
      form.setValues({
        journalID: data.journalID,
        subLedgerTypeID: data.subLedgerTypeID,
        status: data.status,
        detail: data.detail,
        journalDate: new Date(data.journalDate),
        exchangeRateID: data.exchangeRateID,
        journalTypeID: data.journalTypeID,
        transactionTypeID: data.transactionTypeID,
        reversingDate:
          data.reversingDate === null
            ? new Date()
            : new Date(data.reversingDate),
        sourceCurrencyCode: data.sourceCurrencyCode,
        targetCurrencyCode: data.targetCurrencyCode,
        ledgerID: data.ledgerID,
        createdByName: data.createdByName,
        createdDate: new Date(data.createdDate),
        updatedByName: data.updatedByName,
        updatedDate: new Date(data.updatedDate),
        canPost: data.canPost,
        canUpdate: data.canUpdate,
        canPreviewGL: data.canPreviewGL,
        canViewGL: data.canViewGL,
        canDelete: data.canDelete,
        canReverse: data.canReverse,
      });
    } catch (error) {}
  };
  const [exchangeRates, setExchangeRates] = useState<OptionItem[]>([]);
  const getExchangeRateList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/exchangerates");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { detail: string; exchangeRateID: string; rateValue: number }) => ({
          label:
            extensionMethods.decimalDisplay(s.rateValue, false) +
            " " +
            s.detail,
          value: s.exchangeRateID,
        })
      );
      setExchangeRates(data2);
    }
  };
  const [journalTypes, setJournalTypes] = useState<OptionItem[]>([]);
  const getJournalTypeList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/journal/types");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { name: string; journalTypeID: string }) => ({
          label: s.name,
          value: s.journalTypeID,
        })
      );
      setJournalTypes(data2);
    }
  };
  const [transactionTypes, setTransactionTypes] = useState<OptionItem[]>([]);
  const getTranactionTypeList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/journal/system/transactiontypes");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { name: string; systemTransactionTypeID: string }) => ({
          label: s.name,
          value: s.systemTransactionTypeID,
        })
      );
      setTransactionTypes(data2);
    }
  };
  const onTransactionTypeChange = (e: any) => {
    setTransactionTypeID(e.currentTarget.value);
  };
  const [accounts, setAccounts] = useState<OptionItem[]>([]);
  const getAccountList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/accounts");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { name: string; accountID: string }) => ({
          label: s.name,
          value: s.accountID,
        })
      );
      setAccounts(data2);
    }
  };
  function creditTotal() {
    var output: number = 0;
    if (journalDetails) {
      journalDetails.forEach((element) => {
        output += element.creditSourceValue;
      });
    }
    return output;
  }
  function debitTotal() {
    var output: number = 0;
    if (journalDetails) {
      journalDetails.forEach((element) => {
        output += element.debitSourceValue;
      });
    }
    return output;
  }
  useEffect(() => {
    getJournalById();
    getExchangeRateList();
    getJournalTypeList();
    getTranactionTypeList();
    getAccountList();
  }, [id]);
  const onSubmitHandler = async (values: any) => {
    try {
      values.journalDate = extensionMethods.dateMinusTimezoneOffset(
        values.journalDate
      );
      values.reversingDate = extensionMethods.dateMinusTimezoneOffset(
        values.reversingDate
      );
      await commonApi.putApiCall("/journals/" + id, values).then(() => {
        alerts.showMessage("Journal updated");
        if (isPopup) {
          if (setState) {
            setState(true);
          }
        }
        getJournalById();
      });
    } catch (error) {
      alerts.showError(error);
    }
  };
  function showEditModal(
    journalDetailID: number,
    setState?: Dispatch<SetStateAction<boolean>>
  ) {
    if (isPopup) {
      openModal({
        modalId: "edit",
        title: "Showing journal detail information",
        size: "100vw",
        children: (
          <BrowserRouter>
            <JournalDetailFormEdit
              id={journalDetailID.toString()}
              setState={setState}
            />
          </BrowserRouter>
        ),
      });
    } else {
      navigate("/JournalDetail/Edit/" + journalDetailID.toString());
    }
  }
  function showGLPreviewModal(journalID: number, subLedgerTypeID: number) {
    if (isPopup) {
      openModal({
        modalId: "add",
        title: "Showing new journal detail information",
        size: "100vw",
        children: (
          <BrowserRouter>
            <LedgerFormPreview
              id={journalID.toString()}
              typeId={subLedgerTypeID.toString()}
            />
          </BrowserRouter>
        ),
      });
    } else {
      navigate(
        "/Ledger/Preview/" +
          journalID.toString() +
          "/" +
          subLedgerTypeID.toString()
      );
    }
  }
  function showGLViewModal(ledgerID: number) {
    if (isPopup) {
      openModal({
        modalId: "add",
        title: "Showing GL information",
        size: "100vw",
        children: (
          <BrowserRouter>
            <LedgerFormView id={ledgerID.toString()} />
          </BrowserRouter>
        ),
      });
    } else {
      navigate("/Ledger/View/" + ledgerID.toString());
    }
  }
  function showAddModal(
    journalID: number,
    setState?: Dispatch<SetStateAction<boolean>>
  ) {
    if (isPopup) {
      openModal({
        modalId: "add",
        title: "Showing new journal detail information",
        size: "100vw",
        children: (
          <BrowserRouter>
            <JournalDetailFormAdd
              id={journalID.toString()}
              setState={setState}
            />
          </BrowserRouter>
        ),
      });
    } else {
      navigate("/JournalDetail/Add/" + journalID.toString());
    }
  }
  function showDeleteModal(journalID: number) {
    modals.openConfirmModal({
      title: "Delete journal",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this journal/s.</Text>
      ),
      labels: { confirm: "Delete journal", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        try {
          await commonApi.deleteApiCall("/journal/" + journalID).then(() => {
            alerts.showMessage("Journal deleted");
            navigate("/Journal/List");
          });
        } catch (error) {
          alerts.showError(error);
        }
      },
    });
  }
  function showDeleteDetailModal(journalDetailID: number) {
    modals.openConfirmModal({
      title: "Delete journal detail",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this journal detail/s.
        </Text>
      ),
      labels: { confirm: "Delete journal detail", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        try {
          await commonApi
            .deleteApiCall("/journal/details/" + journalDetailID)
            .then(() => {
              alerts.showMessage("Journal detail deleted");
              getJournalById();
            });
        } catch (error) {
          alerts.showError(error);
        }
      },
    });
  }
  function showPostModal(journalID: number) {
    modals.openConfirmModal({
      title: "Post journal to GL",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to post this journal/s.</Text>
      ),
      labels: { confirm: "Post journal", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        try {
          await commonApi
            .putApiCall("/journal/post/" + journalID, [])
            .then(() => {
              alerts.showMessage("Journal posted");
              getJournalById();
            });
        } catch (error) {
          alerts.showError(error);
        }
      },
    });
  }
  function showReverseModal(journalID: number) {
    modals.openConfirmModal({
      title: "Create Reversing Journal",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to reverse this journal/s.</Text>
      ),
      labels: { confirm: "Reverse journal", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        try {
          await commonApi
            .putApiCall("/journal/reverse/" + journalID, [])
            .then(() => {
              alerts.showMessage(
                "Journal reversed - post the new to complete - link is on this page"
              );
              getJournalById();
            });
        } catch (error) {
          alerts.showError(error);
        }
      },
    });
  }
  function displayReversedJournals() {
    let elements = reversedJournals.map((item: Journal) => {
      return (
        <>
          <Label name="Reversed Journal" />
          <ReadOnly
            value={
              <NavLink to={"/Journal/Edit/" + item.journalID}>
                {"Journal " + item.journalID}
              </NavLink>
            }
          />
        </>
      );
    });
    return elements;
  }
  function displaySourceJournals() {
    let elements = sourceJournals.map((item: Journal) => {
      return (
        <>
          <Label name="Source Journal" />
          <ReadOnly
            value={
              <NavLink to={"/Journal/Edit/" + item.journalID}>
                {"Journal " + item.journalID}
              </NavLink>
            }
          />
        </>
      );
    });
    return elements;
  }
  return (
    <>
      <Box className="fade-in-block width33">
        <h3>
          Journal Header{" "}
          <Menu trigger="hover" openDelay={100} closeDelay={400} shadow="md">
            <Menu.Target>
              <Button>options</Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>Journal Functions</Menu.Label>
              <Menu.Item
                disabled={form.values.canReverse ? false : true}
                leftSection={NavLinkIcon("IconAction", false)}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  showReverseModal(form.values.journalID);
                }}
              >
                Reverse
              </Menu.Item>
              <Menu.Item
                disabled={form.values.canDelete ? false : true}
                leftSection={NavLinkIcon("IconDelete", false)}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  showDeleteModal(form.values.journalID);
                }}
              >
                Delete
              </Menu.Item>
              <Menu.Label>General Ledger Functions</Menu.Label>
              <Menu.Item
                disabled={form.values.canPreviewGL ? false : true}
                leftSection={NavLinkIcon("IconView", false)}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  showGLPreviewModal(
                    form.values.journalID,
                    form.values.subLedgerTypeID
                  );
                }}
              >
                Preview
              </Menu.Item>
              <Menu.Item
                disabled={form.values.canPost ? false : true}
                leftSection={NavLinkIcon("IconAction", false)}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  showPostModal(form.values.journalID);
                }}
              >
                Post
              </Menu.Item>
              <Menu.Item
                disabled={form.values.canViewGL ? false : true}
                leftSection={NavLinkIcon("IconView", false)}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  showGLViewModal(form.values.ledgerID);
                }}
              >
                View
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </h3>
        <form
          onSubmit={form.onSubmit((values) => {
            onSubmitHandler(values);
          })}
        >
          <Label name="Status" />
          <ReadOnly value={form.values.status} />
          {displayReversedJournals()}
          {displaySourceJournals()}
          <TextInput
            withAsterisk
            label="Detail"
            placeholder="Detail of journal"
            {...form.getInputProps("detail")}
          />
          <DateInput
            withAsterisk
            label="Date"
            placeholder="Date of the journal"
            {...form.getInputProps("journalDate")}
          />
          <NativeSelect
            withAsterisk
            label="Journal Type"
            data={journalTypes}
            {...form.getInputProps("journalTypeID")}
          />
          <NativeSelect
            withAsterisk
            label="Transaction Type"
            data={transactionTypes}
            {...form.getInputProps("transactionTypeID")}
            onChange={(e) => {
              onTransactionTypeChange(e);
              form.getInputProps("transactionTypeID").onChange(e);
            }}
          />
          <JournalReversingDate
            transactionTypeID={transactionTypeID}
            form={form}
          />
          <NativeSelect
            withAsterisk
            label="Exchange Rate"
            data={exchangeRates}
            {...form.getInputProps("exchangeRateID")}
          />
          <Group justify="flex-end" mt="md">
            <Button
              onClick={(event) => {
                if (isPopup) {
                  closeAllModals();
                } else {
                  event.preventDefault();
                  navigate("/Journal/List");
                }
              }}
            >
              Back
            </Button>
            <Button
              disabled={form.values.canUpdate ? false : true}
              type="submit"
            >
              Update
            </Button>
          </Group>
        </form>
      </Box>
      <Box className="fade-in-block width66">
        <h3>
          Journal Lines{" "}
          <Button
            disabled={form.values.canUpdate ? false : true}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              showAddModal(form.values.journalID, setIsSuccessUpdate);
            }}
          >
            Add
          </Button>
        </h3>
        <Label
          name={
            "These are the journal lines that will be converted to general ledger lines"
          }
        />
        <DataTable
          withTableBorder
          borderRadius="sm"
          withColumnBorders
          striped
          highlightOnHover
          textSelectionDisabled
          records={journalDetails}
          idAccessor="JournalID"
          minHeight={150}
          noRecordsIcon={
            <Box p={4} mb={4}>
              <IconMoodConfuzed size={36} strokeWidth={1.5} />
            </Box>
          }
          noRecordsText="No records found"
          columns={[
            {
              accessor: "name",
              title: "Name",
              textAlign: "left",
              sortable: false,
              width: "25%",
              visibleMediaQuery: (theme) =>
                `(min-width: ${theme.breakpoints.md})`,
            },
            {
              accessor: "accountNumber",
              title: "Account",
              textAlign: "left",
              sortable: false,
              render: (journalDetail) => (
                <NavLink to={"/Account/Edit/" + journalDetail.accountID}>
                  {journalDetail.accountNumber}
                  <div className="smallText">{journalDetail.accountName}</div>
                </NavLink>
              ),
            },
            {
              accessor: "creditSourceValue",
              title: "Source Credit",
              textAlign: "right",
              sortable: false,
              width: "15%",
              render: (journalDetail) =>
                extensionMethods.decimalDisplay(
                  journalDetail.creditSourceValue,
                  true
                ),
              footer:
                extensionMethods.decimalDisplay(creditTotal(), false) +
                " " +
                form.values.sourceCurrencyCode,
            },
            {
              accessor: "debitSourceValue",
              title: "Source Debit",
              textAlign: "right",
              sortable: false,
              width: "15%",
              render: (journalDetail) =>
                extensionMethods.decimalDisplay(
                  journalDetail.debitSourceValue,
                  true
                ),
              footer:
                extensionMethods.decimalDisplay(debitTotal(), false) +
                " " +
                form.values.sourceCurrencyCode,
            },
            {
              accessor: "balanceSourceValue",
              title: "Source Balance",
              textAlign: "right",
              sortable: false,
              width: "15%",
              render: (journalDetail) =>
                extensionMethods.decimalDisplay(
                  journalDetail.balanceSourceValue,
                  true
                ),
            },
            {
              accessor: "actions",
              title: "",
              textAlign: "right",
              width: "1%",
              render: (journalDetail) => (
                <Group gap={10} justify="space-around" wrap="nowrap">
                  <ActionIcon
                    size="sm"
                    variant="subtle"
                    color="blue"
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      showEditModal(
                        journalDetail.journalDetailID,
                        setIsSuccessUpdate
                      );
                    }}
                  >
                    <IconEdit size={16} />
                  </ActionIcon>
                  <ActionIcon
                    disabled={form.values.canUpdate ? false : true}
                    size="sm"
                    variant="subtle"
                    color="red"
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      showDeleteDetailModal(journalDetail.journalDetailID);
                    }}
                  >
                    <IconTrash size={16} />
                  </ActionIcon>
                </Group>
              ),
            },
          ]}
        />
        <div className="log">
          Created by {form.values.createdByName}{" "}
          {extensionMethods.dateTimeDisplay(form.values.createdDate)}
        </div>
        <div className="log">
          Updated by {form.values.updatedByName}{" "}
          {extensionMethods.dateTimeDisplay(form.values.updatedDate)}
        </div>
      </Box>
    </>
  );
}
