import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "@mantine/form";
import { Box, TextInput, Switch, Group, Button } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import CompanyUser from "../../types/company/companyUser";
import { useListState } from "@mantine/hooks";
import Role from "../../types/user/role";
import CompanyGroupUserRoleList from "./companyGroupUserRoleList";
import User from "../../types/user/user";
import CompanyGroupUser from "../../types/company/companyGroupUser";
import alerts from "../common/alerts";
import commonApi from "../../api/commonApi";

export default function CompanyGroupUserFormEdit({
  userId,
  setState,
}: {
  userId?: number;
  setState?: Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const { userID } = useParams();
  const [disabled, setDisabled] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [user, setUser] = useState<User>();
  const [companyUserData, setCompanyUserdata] = useState<CompanyUser[]>([]);
  const [roleData, setRoleData] = useState<Role[]>([]);
  const [roleValues, handlers] = useListState<Role>([]);
  const [canBeDisabled, setCanBeDisabled] = useState(false);

  const form = useForm({
    initialValues: {
      username: "",
      email: "",
      phone: "",
      disabled: false,
      userId: parseInt(userID as string),
    },
    validate: {
      username: (value) =>
        value.length < 2 ? "Name must have at least 2 characters" : null,
      email: (value) =>
        value.length < 2
          ? "Email address must have at least 2 characters"
          : null,
      phone: (value) =>
        value.length < 2
          ? "Phone number must have at least 2 characters"
          : null,
    },
  });

  const getUserById = async () => {
    let id = 0,
      data = null;
    if (userID) {
      id = parseInt(userID as string);
    } else {
      id = userId === undefined ? 0 : userId;
    }
    data = await commonApi.getApiCall("/users/" + id);

    if (data) {
      setUser(data);
      form.setValues({
        userId: data.userID,
        username: data.name,
        disabled: data.disabled,
        phone: data.phone,
        email: data.email,
      });
      const companyUserData = data.companyUsers;
      setCompanyUserdata(companyUserData);
      setCanBeDisabled(data.canBeDisabled);
      setDisabled(data.disabled);
    }
  };

  const getRoleList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/users/roles");
    } catch (error) {}

    if (data) {
      setRoleData(data);
      let chekboxItems: Role[] = [];
      data.map((item: Role) => {
        let chkbox: Role = {
          checked: false,
          roleID: item.roleID,
          name: item.name,
        };
        chekboxItems.push(chkbox);
      });
      handlers.setState(chekboxItems);
    }
  };

  const generateCompanyUserRoleList = (companyUserData: any) => {
    let checkboxJSX: JSX.Element[] = [];
    if (companyUserData) {
      companyUserData.forEach((compUser: CompanyUser) => {
        if (compUser.roleUser) {
          compUser.roleUser.forEach((role: any) => {
            let existRole = roleValues.find((x) => x.roleID === role.roleID);
            if (existRole) {
              existRole.checked = true;
            }
          });

          checkboxJSX.push(
            <CompanyGroupUserRoleList
              compUserList={companyUserData}
              compUser={compUser}
              roleData={roleValues}
              setState={setCompanyUserdata}
            />
          );
          roleValues.map((x) => (x.checked = false));
        }
      });
      return checkboxJSX;
    }
  };

  const onSubmitHandler = async (companyUser: any) => {
    //check if role is selected for each company user
    const checkSelectedRoles = () => {
      let canProceed = false;
      let filteredItems = companyUser.companyGroupUsers.filter(
        (x: any) => x.roleUser.length === 0
      );
      if (filteredItems.length === 0) canProceed = true;
      return canProceed;
    };

    let canProceedUpdate: boolean = false;
    canProceedUpdate = checkSelectedRoles();

    if (canProceedUpdate) {
      try {
        await commonApi
          .putApiCall(
            "/companygroup/user/" + companyUser.user.userID,
            companyUser
          )
          .then((resp) => {
            if (resp === "") {
              if (isPopup) {
                if (setState) {
                  setState(true);
                  closeAllModals();
                }
              } else {
                navigate("/CompanyGroup/User/List");
              }
            } else {
              if (resp.response.status !== 200) {
                alerts.showError({
                  message: resp.response.data.message,
                });
              }
            }
            handlers.setState([]);
          });
      } catch (error) {
        alerts.showError(error);
      }
    } else {
      alerts.showClientError({
        message: "Please select atleast 1 role for each company user",
      });
    }
  };

  useEffect(() => {
    if (userID) {
      userId = parseInt(userID);
      setIsPopup(false);
    } else {
      setIsPopup(true);
    }
    getUserById();
    getRoleList();
  }, []);

  return (
    <Box className={!isPopup ? "fade-in-block width33" : ""}>
      <form
        onSubmit={form.onSubmit((values) => {
          let userData: User = {
            userID: values.userId,
            email: values.email,
            phone: values.phone,
            name: values.username,
            disabled: disabled,
            companyGroup: user ? user.companyGroup : "",
            companyGroupID: user ? user.companyGroupID : 0,
            createdBy: user ? user.createdBy : 0,
            createdByUserName: user ? user.createdByUserName : "",
            createdDate: user ? user.createdDate : "",
          };

          let companyUsers: CompanyUser[] = [];
          companyUserData.forEach((values) => {
            let companyUser: CompanyUser = {
              companyUserID: values.companyUserID,
              companyID: values.companyID,
              userID: values.userID,
              default: true,
              email: values.email,
              userName: values.userName,
              phone: values.phone,
              disabled: disabled,
              roleUser: values.roleUser,
            };
            companyUsers.push(companyUser);
          });

          let companyGroupUser: CompanyGroupUser = {
            user: userData,
            companyGroupUsers: companyUsers,
          };

          onSubmitHandler(companyGroupUser);
        })}
      >
        <TextInput
          withAsterisk
          label="Name"
          placeholder="name of user "
          {...form.getInputProps("username")}
        />
        <TextInput
          withAsterisk
          label="Email"
          placeholder="email address"
          {...form.getInputProps("email")}
        />
        <TextInput
          withAsterisk
          label="Phone"
          placeholder="phone number"
          {...form.getInputProps("phone")}
        />
        <Switch
          checked={disabled}
          disabled={!canBeDisabled}
          label="Disabled"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
          onChange={(event) => setDisabled(event.currentTarget.checked)}
        />

        {generateCompanyUserRoleList(companyUserData)}

        <Group justify="flex-end" mt="md">
          <Button
            onClick={(event) => {
              if (isPopup) {
                closeAllModals();
              } else {
                event.preventDefault();
                navigate("/CompanyGroup/User/List");
              }
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Update</Button>
        </Group>
      </form>
    </Box>
  );
}
