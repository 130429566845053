import { Modal, Alert, Stack } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';

function ConnectionError() {
    const [opened, { open, close }] = useDisclosure(true);
    const icon = <IconInfoCircle />;

    return (
    <Modal opened={opened} onClose={close} withCloseButton={false} centered size="md">
        <Stack>
            <Alert variant="light" color="blue" title="Connection Error" icon={icon}>
                There was a problem connecting to the server. Please wait while connection is re-established.
            </Alert>
        </Stack>
    </Modal>
  );
}

export default ConnectionError;