import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BrowserRouter, useNavigate, useParams } from "react-router-dom";
import alerts from "../common/alerts";
import commonApi from "../../api/commonApi";
import { Box, Button, Group, NativeSelect, NumberInput, TextInput } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import { useForm } from "@mantine/form";
import OptionItem from "../../types/common/optionItem";
import JournalFormEdit from "./journalFormEdit";
import extensionMethods from "../../common/extensionMethods";

export default function JournalDetailFormAdd({
    id,
    setState
  }: {
    id?: string;
    setState?: Dispatch<SetStateAction<boolean>>;
  }){
    const navigate = useNavigate();
    const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);  
    const {journalID} = useParams();
    let isPopup: boolean = true;
    if (id == null){
      isPopup = false;
      id = journalID;
    } 
    const form = useForm({
        initialValues: {
            journalID: 0,
            name: "",
            creditSourceValue: 0,
            debitSourceValue: 0,
            accountID: 0
        }        
      });  
    const getJournalById = async () => {
        try {
          let data = await commonApi.getApiCall("/journals/" + id);
          form.setValues({
            journalID: data.journalID,
            name: "New line for " + data.detail});
        } catch (error) {}
      }; 
      const [accounts, setAccounts] = useState<OptionItem[]>([]);
      const getAccountList = async () => {
        let data = null;
        try {
          data = await commonApi.getApiCall("/accounts");
        } catch (error) {}
        if (data) {
          let data2: OptionItem[] = data.map((s: { name: string; accountNumber: string, accountID: string; }) => ({label: s.accountNumber + " " + s.name, value: s.accountID}));
          data2.splice(0, 0, {label: "Select..", value: "0"});
          setAccounts(data2);               
        }
      };
      useEffect(() => {      
        getJournalById();
        getAccountList();
      }, 
      []); 
      const onSubmitHandler = async (values: any) => {
        try {
          await commonApi.postApiCall("/journal/details", values).then(() => {
            alerts.showMessage("Journal detail added");
            if (isPopup) {
              if (setState){
                setState(true);
              }
              openModal({
                  modalId: "edit",
                  title:"Showing journal information",
                  size:"100vw",
                  children: (
                    <BrowserRouter>
                      <JournalFormEdit id={values.journalID.toString()} setState={setState} />
                    </BrowserRouter>
                  ),
                });
            } 
            else {
              navigate("/Journal/Edit/" + values.journalID);
            }
          });
        } 
        catch (error) {
          alerts.showError(error);
        }
      };   
      return (
        <>
            <form
                onSubmit={form.onSubmit((values) => {
                    onSubmitHandler(values);
                })}>
                <Box>
                    <TextInput
                      withAsterisk
                      label="Name"
                      placeholder="Description of journal line"
                      {...form.getInputProps("name")}/>
                    <NativeSelect 
                      withAsterisk
                      label="Account" 
                      data={accounts}
                    {...form.getInputProps("accountID")}/>
                    <NumberInput
                      withAsterisk
                      label="Credit Value"
                      placeholder="Credit value of journal line"
                      allowDecimal={true}
                      decimalScale={2}
                      fixedDecimalScale
                      {...form.getInputProps("creditSourceValue")}/>
                    <NumberInput
                      withAsterisk
                      label="Debit Value"
                      placeholder="Debit value of journal line"
                      allowDecimal={true}
                      decimalScale={2}
                      fixedDecimalScale
                      {...form.getInputProps("debitSourceValue")}/>
                    <Group justify="flex-end" mt="md">
                        <Button
                            onClick={(event) => {
                                if (isPopup) {
                                    closeAllModals();
                                } else {
                                    event.preventDefault();
                                    navigate("/Journal/Edit/" + form.values.journalID);
                                }
                        }}>
                        Back
                        </Button>
                        <Button type="submit">Add</Button>
                    </Group>
                </Box>
            </form>
        </>
      );
}

function openModal(arg0: { modalId: string; title: string; size: string; children: import("react/jsx-runtime").JSX.Element; }) {
    throw new Error("Function not implemented.");
}
