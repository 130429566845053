import {
  Box,
  SimpleGrid,
  Stepper,
  TextInput,
  Group,
  Button,
} from "@mantine/core";
import { IconMoodConfuzed } from "@tabler/icons-react";
import { DataTable } from "mantine-datatable";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const BasicInformationForm = () => {
  return (
    <>
      <Box className="fade-in-block width33">
        <TextInput
          withAsterisk
          label="Document Number"
          placeholder="Enter document number"
        />
        <TextInput
          withAsterisk
          label="Sub Total"
          placeholder="Enter Sub Total"
        />
        <TextInput
          withAsterisk
          label="Tax Amount"
          placeholder="Enter tax amount"
        />
        <TextInput withAsterisk label="Total" placeholder="Enter Total" />
      </Box>
    </>
  );
};

const PurchaseLineOrderTable = () => {
  return (
    <Box>
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        records={[]}
        minHeight={50}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        columns={[
          {
            accessor: "lineNumber",
            title: "Line Number",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "quantity",
            title: "Quantity",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "unitPrice",
            title: "Unit Price",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "subTotal",
            title: "SubTotal",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "taxTotal",
            title: "Tax Total",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "total",
            title: "Total",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
        ]}
      />
    </Box>
  );
};

const PurchaseOrderLineForm = () => {
  return (
    <Box className="fade-in-block width33">
      <TextInput
        label="Line Number"
        placeholder="Enter line number"
        withAsterisk
      />
      <TextInput label="Quantity" placeholder="Enter quantity" withAsterisk />
      <TextInput
        label="Unit Price"
        placeholder="Enter unit price"
        withAsterisk
      />
      <TextInput
        label="Line Subtotal"
        placeholder="Enter line subtotal"
        withAsterisk
      />
      <TextInput
        label="Line Tax Total"
        placeholder="Enter tax total"
        withAsterisk
      />
      <TextInput label="Total" placeholder="Enter total" withAsterisk />
    </Box>
  );
};

export default function PurchaseOrderAddForm() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNextStep = () => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
  };
  return (
    <>
      <Box className="fade-in-block width100">
        <Stepper
          active={currentStep}
          onStepClick={setCurrentStep}
          orientation="horizontal"
        >
          <Stepper.Step label="Basic Information">
            <BasicInformationForm />
          </Stepper.Step>
          <Stepper.Step label="Purchase Order lines">
            <Box>
              <SimpleGrid cols={2}>
                <PurchaseOrderLineForm />
                <PurchaseLineOrderTable />
              </SimpleGrid>
            </Box>
          </Stepper.Step>
        </Stepper>
        <Group justify="flex-end" mt="md">
          <Button onClick={handlePrevStep} disabled={currentStep === 0}>
            Back
          </Button>
          {currentStep < 1 ? (
            <Button onClick={handleNextStep} disabled={currentStep === 1}>
              Next
            </Button>
          ) : (
            <form
              onSubmit={() => {
                console.log("hit");
              }}
            >
              <Button type="submit">Add Purchase Order</Button>
            </form>
          )}

          <Button onClick={() => navigate("/Finance/Purchasing")}>
            Cancel
          </Button>
        </Group>
      </Box>
    </>
  );
}
