import { useCallback, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { menu } from "../types/navigation/menu";
import {
  Flex,
  Breadcrumbs,
  Button,
  useMantineTheme,
  Menu,
  Divider,
  Text,
} from "@mantine/core";
import commonApi from "../api/commonApi";
import NavLinkIcon from "./navLinkIcon";
import styles from "./Navigation.module.css";
import { IconChevronRight } from "@tabler/icons-react";

export default function PageHeader() {
  const theme = useMantineTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [pageUrl, setPageUrl] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState([] as menu[]);

  const breadCrumbListToDisplay = (items: menu[]) => {
    return items.map((item: menu, index: number) => {
      return item.selectable === true ? (
        <NavLink className="breadCrumb" to={item.url}>
          {item.menuTitle}
        </NavLink>
      ) : (
        <span className="breadCrumb" key={index}>
          {item.menuTitle}
        </span>
      );
    });
  };

  const setToContextAndOpen = (href: string) => {
    navigate(href);
  };

  const childMenuDisplay = useCallback((currentBreadcrumb: menu) => {
    let elements = currentBreadcrumb.childMenus.map(
      (item: menu, index: number) => {
        return (
          <Menu.Item
            leftSection={NavLinkIcon(item.icon, false)}
            component="a"
            key={index}
            onClick={() => setToContextAndOpen(item.url)}
          >
            {item.pageTitle}
          </Menu.Item>
        );
      }
    );
    //TODO: item.pageDetail was removed but if we need it, we should think how to add to the Menu

    return elements;
  }, []);

  const getBreadcrumbs = async () => {
    commonApi
      .getApiCall("/navigation/breadcrumb?url=" + location.pathname)
      .then((data) => {
        data[0].menuTitle !== "Dashboard"
          ? setBreadcrumbs([
              { menuTitle: "Home", url: "/", selectable: true },
              ...data,
            ])
          : setBreadcrumbs(data);
      });
  };

  if (pageUrl !== location.pathname) {
    setPageUrl(location.pathname);
    getBreadcrumbs();
  }

  if (breadcrumbs.length !== 0) {
    let currentBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
    return (
      <>
        <Flex direction="row" justify="space-between" align="center">
          <div>
            <h1>
              {NavLinkIcon(currentBreadcrumb.icon, false)}{" "}
              {currentBreadcrumb.pageTitle}
            </h1>
            <Breadcrumbs
              separator="→"
              mt="xs"
              classNames={{
                breadcrumb: styles.breadCrumb,
              }}
            >
              {breadCrumbListToDisplay(breadcrumbs)}
            </Breadcrumbs>
          </div>
          {currentBreadcrumb.childMenus.length > 0 && (
            <Flex justify="flex-end">
              <Menu
                shadow="md"
                width={200}
                offset={5}
                position="bottom-end"
                withArrow
                arrowPosition="center"
                classNames={{ dropdown: styles.dropdown }}
              >
                <Menu.Target>
                  <Button
                    color={theme.colors.tertiaryColor[0]}
                    rightSection={<IconChevronRight size={14} />}
                  >
                    Actions
                  </Button>
                </Menu.Target>
                <Menu.Dropdown>
                  {childMenuDisplay(currentBreadcrumb)}
                </Menu.Dropdown>
              </Menu>
            </Flex>
          )}
        </Flex>

        <Text mt="xl">{currentBreadcrumb.pageDetail}</Text>

        <Divider my="sm" />
      </>
    );
  } else {
    return <></>;
  }
}
