import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import { useForm } from "@mantine/form";
import { Box, TextInput, NativeSelect, Button, Group, NumberInput, Checkbox, Fieldset, SimpleGrid, Switch } from "@mantine/core";
import OptionItem from "../../../types/common/optionItem";
import alerts from "../../common/alerts";

export default function ItemFormAdd()
  {
    const navigate = useNavigate();   
    const form = useForm();  
    
    const [valuationMethods, setValuationMethods] = useState<OptionItem[]>([]);
    const getValuationMethodList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/inventory/valuationmethods");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
        data2.splice(0, 0, {label: "-- Select --", value: "0"});
        setValuationMethods(data2);               
      }
    };  
    const [itemTypes, setItemTypes] = useState<OptionItem[]>([]);
    const getItemTypeList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/inventory/itemtypes");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
        setItemTypes(data2);
        //set default value for itemTypeID field on form
        form.setFieldValue("itemTypeID", Number(data2[0].value)); 
      }
    }; 
    const [categories, setCategories] = useState<OptionItem[]>([]);
    const getCategoryList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/inventory/categories");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
        data2.splice(0, 0, {label: "-- Select --", value: "0"});
        setCategories(data2);               
      }
    };
    const [taxOptions, setTaxOptions] = useState<OptionItem[]>([]);
    const getTaxOptionList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/inventory/taxoptions");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
        data2.splice(0, 0, {label: "-- Select --", value: "0"});
        setTaxOptions(data2);               
      }
    };
    const [unitOfMeasures, setUnitofMeasures] = useState<OptionItem[]>([]);
    const getUnitOfMeasureList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/inventory/unitofmeasures");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
        data2.splice(0, 0, {label: "-- Select --", value: "0"});
        setUnitofMeasures(data2);
      }
    };
    const [trackingOptions, setTrackingOptions] = useState<OptionItem[]>([]);
    const getTrackingOptionList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/inventory/trackingoptions");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
        data2.splice(0, 0, {label: "-- Select --", value: "0"});
        setTrackingOptions(data2);               
      }
    };
      
    useEffect(() => {      
      getValuationMethodList();
      getItemTypeList();
      getCategoryList();
      getTaxOptionList();
      getUnitOfMeasureList();
      getTrackingOptionList();
    },[]);

    const onSubmitHandler = async (values: any) => {
      try {
        var data = await commonApi.postApiCall("/inventory/items", values);
        alerts.showMessage("Item added");
        navigate("/Inventory/Item/Edit/" + data.itemID);
      } 
      catch (error) {
        alerts.showError(error);
      }
    };    
    return (
      <>
        <Box>
          <form
            onSubmit={form.onSubmit((values) => {
              onSubmitHandler(values);
            })}>
            <SimpleGrid cols={2}>
              <Fieldset legend="Core Details">
                <TextInput 
                  name="name" 
                  label="Name"
                  required
                  {...form.getInputProps("name")}/>

                <TextInput 
                  name="description" 
                  label="Description"
                  {...form.getInputProps("description")}/>

                <NumberInput
                  name="listCost"
                  label="List Cost"
                  description="The RRP cost of the item"
                  prefix="$"
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  {...form.getInputProps("listCost")}/>
              
                <NumberInput
                  label="Current Cost"
                  description="The weighted average cost of the item"
                  disabled
                  prefix="$"
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  {...form.getInputProps("currentCost")}/>        

                <Switch
                  name="disabled" 
                  label="Disabled"
                  {...form.getInputProps("disabled")}/>      

                <Switch
                  name="allowBackorder" 
                  label="Allow Backorder"
                  {...form.getInputProps("allowBackorder")}/>
              </Fieldset>

              <Fieldset legend="Extended Properties">
                <NativeSelect 
                  name="itemTypeID" 
                  label="Item Type" 
                  required
                  data={itemTypes}
                  {...form.getInputProps("itemTypeID")}/>
                
                <NativeSelect 
                  name="categoryID" 
                  label="Item Category" 
                  data={categories}
                  {...form.getInputProps("categoryID")}/>
                
                <NativeSelect 
                  name="valuationMethodID" 
                  label="Valuation Method" 
                  data={valuationMethods}
                  {...form.getInputProps("valuationMethodID")}/>

                <NativeSelect 
                  name="unitOfMeasureID" 
                  label="Unit of Measure" 
                  data={unitOfMeasures}
                  {...form.getInputProps("unitOfMeasureID")}/>

                <NativeSelect 
                  name="trackingOptionID" 
                  label="Tracking Option" 
                  data={trackingOptions}
                  {...form.getInputProps("trackingOptionID")}/>
                
                <NativeSelect 
                  name="salesTaxOptionID" 
                  label="Sales Tax Option" 
                  description="The tax option to use for sales transactions"
                  data={taxOptions}
                  {...form.getInputProps("salesTaxOptionID")}/>
                
                <NativeSelect 
                  name="purchaseTaxOptionID"
                  label="Purchase Tax Option" 
                  description="The tax option to use for purchase transactions"
                  data={taxOptions}
                  {...form.getInputProps("purchaseTaxOptionID")}/>               
              </Fieldset>              
          </SimpleGrid>

          <Group justify="flex-end" mt="md">
            <Button
              onClick={(event) => {
                navigate("/Inventory/Item/List");
              }}>
              Back
            </Button>
            <Button type="submit">Add</Button>
          </Group>
        </form>
      </Box>
      </>
    );
  }