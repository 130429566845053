import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import { useForm } from "@mantine/form";
import { Box, TextInput, NativeSelect, Button, Group } from "@mantine/core";
import OptionItem from "../../../types/common/optionItem";
import alerts from "../../common/alerts";

export default function AccountCategoryFormAdd()
  {
    const navigate = useNavigate();   
    const [accountCategoryTypes, setAccountCategoryTypes] = useState<OptionItem[]>([]);
    const form = useForm({
        initialValues: {
            accountCategoryID: 0,
            name: "",
            accountCategoryTypeID: 0
        }        
      }); 
    const getAccountCategoryTypeList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/account/category/types");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { name: string; accountCategoryTypeID: string; }) => ({label: s.name, value: s.accountCategoryTypeID}));
        data2.splice(0, 0, {label: "Select..", value: "0"});
        setAccountCategoryTypes(data2);               
      }
    };  
    useEffect(() => {      
        getAccountCategoryTypeList();
    }, 
    []);    
    const onSubmitHandler = async (values: any) => {
      try {
        await commonApi.postApiCall("/account/categories", values).then(() => {
            alerts.showMessage("Account Category added");
            navigate("/Account/Category/List");
        });
      } 
      catch (error) {
        alerts.showError(error);
      }
    };    
    return (
      <>
        <Box mx="auto">
          <form
            onSubmit={form.onSubmit((values) => {
                onSubmitHandler(values);
            })}>   
            <NativeSelect 
                withAsterisk
                label="Account Category Type" 
                className="searchFormElement"
                data={accountCategoryTypes}
                {...form.getInputProps("accountCategoryTypeID")}/>
            <TextInput
                withAsterisk
                label="Name"
                placeholder="Name of Account Category"
                {...form.getInputProps("name")}/>  
            <Group justify="flex-end" mt="md">
                <Button
                    onClick={(event) => {
                        navigate("/Account/Category/List")
                    }}>
                    Back
                </Button>
                <Button type="submit">Add</Button>
            </Group> 
          </form>
        </Box>
      </>
    );
  }