import { BrowserRouter, useNavigate } from "react-router-dom";
import PaginationSection from "../../common/Sections/Pagination/PaginationSection";
import SiteSearch from "./siteSearch";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import commonApi from "../../../api/commonApi";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import Site from "../../../types/inventory/site";
import { ActionIcon, Box, Group, Text } from "@mantine/core";
import { IconEdit, IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import PaginationSummary from "../../common/Sections/Pagination/PaginationSummary";
import alerts from "../../common/alerts";
import { modals, openModal } from "@mantine/modals";
import SiteFormEdit from "./siteFormEdit";

export default function SiteList() {
  const navigate = useNavigate();
  const [sites, setSites] = useState<Site[]>([]);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [params, setParams] = useState({});
  const [totalRecords, setTotalRecords] = useState(1);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Site>>({
    columnAccessor: "",
    direction: "asc",
  });
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);

  const getSiteList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/inventory/sites?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      setTotalRecords(data.length);
      setSites(data);
    }
  };

  const showEditModal = ({
    site,
    setState,
  }: {
    site: Site;
    setState?: Dispatch<SetStateAction<boolean>>;
  }) => {
    openModal({
      modalId: "edit",
      title: "Site Edit",
      size: "lg",
      children: (
        <BrowserRouter>
          <SiteFormEdit id={site.siteID.toString()} setState={setState} />
        </BrowserRouter>
      ),
    });
  };

  const showDeleteModal = (siteID: number) => {
    modals.openConfirmModal({
      title: "Delete item",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this site/s.</Text>
      ),
      labels: { confirm: "Delete site", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        try {
          await commonApi
            .deleteApiCall("/inventory/sites/" + siteID)
            .then(() => {
              alerts.showMessage("Item deleted");
              getSiteList();
            });
        } catch (error) {
          alerts.showError(error);
        }
      },
    });
  };

  useEffect(() => {
    getSiteList();
  }, [sortStatus, page, pageSize, params, isSuccessUpdate]);

  return (
    <div className="fade-in-block width100">
      <SiteSearch setParamsFunction={setParams} />
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        records={sites}
        idAccessor="itemID"
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        columns={[
          {
            accessor: "code",
            title: "Code",
            textAlign: "left",
            sortable: true,
            render: (site) => site.code,
            width: "10%",
          },
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
            render: (site) => site.name,
            width: "10%",
          },
          {
            accessor: "address",
            title: "Address",
            textAlign: "left",
            sortable: true,
            render: (site) => site.address.address1,
            width: "10%",
          },
          {
            accessor: "city",
            title: "City",
            textAlign: "left",
            sortable: true,
            render: (site) => site.address.city,
            width: "10%",
          },
          {
            accessor: "zip",
            title: "Zip",
            textAlign: "left",
            sortable: true,
            render: (site) => site.address.zipcode,
            width: "10%",
          },
          {
            accessor: "phone",
            title: "Phone",
            textAlign: "left",
            sortable: true,
            render: (site) => site.phone,
            width: "10%",
          },
          {
            accessor: "fax",
            title: "Fax",
            textAlign: "left",
            sortable: true,
            render: (site) => site.fax,
            width: "10%",
          },
          {
            accessor: "email",
            title: "Email",
            textAlign: "left",
            sortable: true,
            render: (site) => site.email,
            width: "10%",
          },
          {
            accessor: "disabled",
            title: "Status",
            textAlign: "left",
            sortable: true,
            render: (site) => (site.disabled ? "Disabled" : "Enabled"),
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            width: "5%",
          },
          {
            accessor: "actions2",
            title: "",
            textAlign: "right",
            width: "1%",
            render: (site) => (
              <Group gap={4} justify="right" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showEditModal({ site, setState: setIsSuccessUpdate });
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showDeleteModal(site.siteID);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/Inventory/Site/Edit/" + record.siteID);
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
