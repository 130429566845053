import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import RateDetail from "../../types/tax/rateDetail";
import commonApi from "../../api/commonApi";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import alerts from "../common/alerts";
import { ActionIcon, Box, Button, Group, Text } from "@mantine/core";
import PaginationSection from "../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../common/Sections/Pagination/PaginationSummary";
import { IconEdit, IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import { modals, openModal } from "@mantine/modals";
import RateDetailEdit from "./rateDetailEdit";
import RateDetailSearch from "./rateDetailSearch";

export default function RateDetailList() {
  const navigate = useNavigate();
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [totalRecords, setTotalRecords] = useState(1);
  const [params, setParams] = useState({});
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<RateDetail>>(
    {
      columnAccessor: "",
      direction: "asc",
    }
  );
  const [selectedRecords, setSelectedRecords] = useState<RateDetail[]>([]);
  const [rateDetails, setRateDetails] = useState<RateDetail[]>([]);

  const openDeleteModal = (
    rateDetail?: RateDetail,
    selectedRateDetails?: RateDetail[]
  ) => {
    modals.openConfirmModal({
      title: "Delete rate detail",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this rate detail/s.
        </Text>
      ),
      labels: { confirm: "Delete rate detail", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        if (rateDetail) {
          if (rateDetail.rateDetailID) {
            try {
              await commonApi
                .deleteApiCall("/ratedetails/" + rateDetail.rateDetailID)
                .then((response) => {
                  alerts.showMessage("Rate detail delete success");
                  getRatedetailList();
                });
            } catch (error) {
              alerts.showError(error);
            }
          }
        }

        if (selectedRateDetails) {
          let selectedCount = selectedRateDetails.length;
          let counter = 0;
          selectedRateDetails.forEach(async (rateDetail) => {
            if (rateDetail.rateDetailID) {
              try {
                await commonApi
                  .deleteApiCall("/ratedetails/" + rateDetail.rateDetailID)
                  .then((response) => {
                    counter++;
                  });
              } catch (error) {
                alerts.showError(error);
              }

              if (counter === selectedCount) {
                alerts.showMessage("Company user delete success");
                setSelectedRecords([]);
                getRatedetailList();
              }
            }
          });
        }
      },
    });
  };

  const showModal = ({
    rateDetail,
    setState,
  }: {
    rateDetail: RateDetail;
    setState?: Dispatch<SetStateAction<boolean>>;
  }) => {
    openModal({
      modalId: "update",
      title: "Edit rate detail information",
      children: (
        <BrowserRouter>
          <RateDetailEdit
            rateDetailID={rateDetail.rateDetailID}
            setState={setState}
          />
        </BrowserRouter>
      ),
    });
  };

  const getRatedetailList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      await commonApi
        .getApiCall("/ratedetails?" + new URLSearchParams(params).toString())
        .then((response: any) => {
          data = response;
          if (data) {
            setTotalRecords(data.length);
            setRateDetails(data);
          }
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  useEffect(() => {
    getRatedetailList();
  }, [isSuccessUpdate, sortStatus, page, pageSize, params]);

  return (
    <div>
      <RateDetailSearch setParamsFunction={setParams} />
      <Group mt="md" justify="flex-end">
        <Button
          onClick={() => {
            navigate("/Tax/RateDetail/Add");
          }}
        >
          Add
        </Button>
        <Button
          onClick={() => {
            if (selectedRecords.length > 0) {
              openDeleteModal(undefined, selectedRecords);
            } else {
              alerts.showClientError({
                message: "No selected record to delete",
              });
            }
          }}
        >
          Delete
        </Button>
      </Group>
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        pinLastColumn
        idAccessor="rateDetailID"
        records={rateDetails}
        isRecordSelectable={(record) => record.canBeDeleted === true}
        columns={[
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "account.name",
            title: "Account",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "ratePercentage",
            title: "Rate Percentage",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "createdByUser.name",
            title: "Created By",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (rateDetail) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showModal({
                      rateDetail,
                      setState: setIsSuccessUpdate,
                    });
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  disabled={!rateDetail.canBeDeleted}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    openDeleteModal(rateDetail);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/Tax/RateDetail/Edit/" + record.rateDetailID);
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        selectedRecords={selectedRecords}
        onSelectedRecordsChange={setSelectedRecords}
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
