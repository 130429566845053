import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import commonApi from "../../api/commonApi";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import Schedule from "../../types/tax/schedule";
import alerts from "../common/alerts";
import { Group, Button, ActionIcon, Box, Text, Modal } from "@mantine/core";
import { IconTrash, IconMoodConfuzed, IconEdit } from "@tabler/icons-react";
import PaginationSection from "../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../common/Sections/Pagination/PaginationSummary";
import { modals, openModal } from "@mantine/modals";
import ScheduleEdit from "./scheduleEdit";
import ScheduleSearch from "./scheduleSearch";
import ScheduleEditModal from "./scheduleEditModal";

export default function ScheduleList() {
  const navigate = useNavigate();
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [totalRecords, setTotalRecords] = useState(1);
  const [params, setParams] = useState({});
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Schedule>>({
    columnAccessor: "",
    direction: "asc",
  });
  const [selectedRecords, setSelectedRecords] = useState<Schedule[]>([]);
  const [scheduleList, setScheduleList] = useState<Schedule[]>([]);

  const showModal = ({
    schedule,
    setState,
  }: {
    schedule: Schedule;
    setState?: Dispatch<SetStateAction<boolean>>;
  }) => {
    openModal({
      modalId: "updateSchedule",
      title: "Edit schedule information",
      closeOnClickOutside: false,
      keepMounted: true,
      children: (
        <BrowserRouter>
          <ScheduleEdit scheduleID={schedule.scheduleID} setState={setState} />
        </BrowserRouter>
      ),
    });
  };

  const openDeleteModal = (
    schedule?: Schedule,
    selectedSchedule?: Schedule[]
  ) => {
    modals.openConfirmModal({
      title: "Delete schedule",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this schedule/s.</Text>
      ),
      labels: { confirm: "Delete schedule", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        if (schedule) {
          if (schedule.scheduleID) {
            try {
              await commonApi
                .deleteApiCall("/schedules/" + schedule.scheduleID)
                .then((response) => {
                  alerts.showMessage("Schedule delete success");
                  getScheduleList();
                });
            } catch (error) {
              alerts.showError(error);
            }
          }
        }

        if (selectedSchedule) {
          let selectedCount = selectedSchedule.length;
          let counter = 0;
          selectedSchedule.forEach(async (schedule) => {
            if (schedule.scheduleID) {
              try {
                await commonApi
                  .deleteApiCall("/schedules/" + schedule.scheduleID)
                  .then((response) => {
                    counter++;
                  });
              } catch (error) {
                alerts.showError(error);
              }

              if (counter === selectedCount) {
                alerts.showMessage("Company user delete success");
                setSelectedRecords([]);
                getScheduleList();
              }
            }
          });
        }
      },
    });
  };

  const getScheduleList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      await commonApi
        .getApiCall("/schedules?" + new URLSearchParams(params).toString())
        .then((response) => {
          data = response;
          if (data) {
            setTotalRecords(data.length);
            setScheduleList(data);
          }
        });
    } catch (error) {
      alerts.showError(error);
    }
  };
  useEffect(() => {
    setIsSuccessUpdate(false);
    getScheduleList();
  }, [isSuccessUpdate, sortStatus, page, pageSize, params]);

  return (
    <div>
      <ScheduleSearch setParamsFunction={setParams} />
      <Group mt="md" justify="flex-end">
        <Button
          onClick={() => {
            navigate("/Tax/Schedule/Add");
          }}
        >
          Add
        </Button>
        <Button
          onClick={() => {
            if (selectedRecords.length > 0) {
              openDeleteModal(undefined, selectedRecords);
            } else {
              alerts.showClientError({
                message: "No selected record to delete",
              });
            }
          }}
        >
          Delete
        </Button>
      </Group>
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        pinLastColumn
        idAccessor="scheduleID"
        records={scheduleList}
        isRecordSelectable={(record) => record.canBeDeleted === true}
        columns={[
          {
            accessor: "company.name",
            title: "Company",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "code",
            title: "Code",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "rateDetailCount",
            title: "Rate Count",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "createdByUser.name",
            title: "Created By",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (schedule) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                {/* <ScheduleEditModal
                  schedule={schedule}
                  setState={setIsSuccessUpdate}
                /> */}
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showModal({
                      schedule,
                      setState: setIsSuccessUpdate,
                    });
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  disabled={!schedule.canBeDeleted}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    openDeleteModal(schedule);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/Tax/Schedule/Edit/" + record.scheduleID);
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        selectedRecords={selectedRecords}
        onSelectedRecordsChange={setSelectedRecords}
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
