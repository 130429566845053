import { Box, Button, Group, Select, Switch, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserView from "../../types/company/UserView";
import companyApi from "../../api/company/companyApi";
import alerts from "../common/alerts";
import commonApi from "../../api/commonApi";
import CompanyGroup from "../../types/company/companyGroup";

type SelectType = {
  value: string;
  label: string;
  disabled: boolean;
};

export default function AdminUserFormAdd() {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [userId, setUserId] = useState(0);
  const [groupData, setGroupData] = useState<SelectType[]>([]);

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      disabled: false,
      companyGroupID: 0,
    },
    validate: {
      name: (value) =>
        value.length < 2 ? "Name must have at least 2 characters" : null,
      email: (value) =>
        value.length < 2
          ? "Email address must have at least 2 characters"
          : null,
      phone: (value) =>
        value.length < 2
          ? "Phone number must have at least 2 characters"
          : null,
      companyGroupID: (value) =>
        value === 0 ? "Please select company group" : null,
    },
  });

  //get company group
  let groupList: SelectType[] = [];
  const getCompanyGrpList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/groups");
    } catch (error) {}

    if (data) {
      data.map((group: CompanyGroup) => {
        let d: SelectType = {
          label: group.name,
          value: group.companyGroupID.toString(),
          disabled: false,
        };
        groupList.push(d);
      });
      setGroupData(groupList);
    }
  };

  const onSubmitHandler = async (values: any) => {
    try {
      let user: UserView = {
        email: values.email,
        name: values.name,
        phone: values.phone,
        disabled: disabled,
        companyGroupID: values.companyGroupID,
      };

      await commonApi.postApiCall("/users", user).then((resp) => {
        navigate("/Admin/User/List");
      });
    } catch (error) {
      alerts.showError(error);
    }
  };

  useEffect(() => {
    getCompanyGrpList();
  }, []);

  return (
    <Box className="fade-in-block width33">
      <form
        onSubmit={form.onSubmit((values) => {
          values.disabled = disabled;
          onSubmitHandler(values);
        })}
      >
        <Select
          label="Company Group"
          withAsterisk
          placeholder="Company Group"
          data={groupData}
          allowDeselect={true}
          clearable
          searchable
          nothingFoundMessage="Nothing found..."
          {...form.getInputProps("companyGroupID")}
        />
        <TextInput
          withAsterisk
          label="Name"
          disabled={userId > 0 ? true : false}
          placeholder="name of user "
          {...form.getInputProps("name")}
        />
        <TextInput
          withAsterisk
          label="Email"
          disabled={userId > 0 ? true : false}
          placeholder="email address"
          {...form.getInputProps("email")}
        />
        <TextInput
          withAsterisk
          label="Phone"
          disabled={userId > 0 ? true : false}
          placeholder="phone number"
          {...form.getInputProps("phone")}
        />
        <Switch
          checked={disabled}
          label="Disabled"
          disabled={userId > 0 ? true : false}
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
          onChange={(event) => setDisabled(event.currentTarget.checked)}
        />

        <Group justify="flex-end" mt="md">
          <Button
            onClick={(event) => {
              event.preventDefault();
              navigate("/Admin/User/List");
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </Box>
  );
}
