import { Avatar, Group, Text } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import styles from "./User.module.css";
import { useMediaQuery } from "@mantine/hooks";

type UserBannerProps = {
  image: string;
  name: string;
  email: string;
  icon?: React.ReactNode;
};

const UserBanner = ({ image, name, email, icon }: UserBannerProps) => {
  const smallViewport = useMediaQuery("(max-width: 48em)");
  return (
    <Group classNames={{ root: styles.user }}>
      <Avatar
        src={image}
        alt={name}
        radius="xl"
        variant="outline"
        color="primaryColor"
      />

      {!smallViewport && (
        <div style={{ flex: 1 }}>
          <Text size="sm" fw={500}>
            {name}
          </Text>

          <Text c="dimmed" size="xs">
            {email}
          </Text>
        </div>
      )}

      {icon || <IconChevronDown size="1rem" />}
    </Group>
  );
};
export default UserBanner;
