import {
  Box,
  Button,
  Checkbox,
  Fieldset,
  Group,
  Select,
  Switch,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Role from "../../types/user/role";
import { useListState } from "@mantine/hooks";
import CompanyRoleUser from "../../types/company/companyRoleUser";
import CompanyUser from "../../types/company/companyUser";
import commonApi from "../../api/commonApi";
import User from "../../types/user/user";
import alerts from "../common/alerts";

type SelectType = {
  value: string;
  label: string;
};

export default function CompanyUserForm() {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [roleData, setRoleData] = useState<Role[]>([]);
  const [userlist, setUserlist] = useState<User[]>([]);
  const [userSelectData, setUserSelectData] = useState<SelectType[]>([]);
  const [roleValues, handlers] = useListState<Role>([]);
  const [userId, setUserId] = useState(0);

  const form = useForm({
    initialValues: {
      username: "",
      email: "",
      phone: "",
      disabled: false,
      userId: 0,
    },
    validate: {
      username: (value) =>
        value.length < 2 ? "Name must have at least 2 characters" : null,
      email: (value) =>
        value.length < 2
          ? "Email address must have at least 2 characters"
          : null,
      phone: (value) =>
        value.length < 2
          ? "Phone number must have at least 2 characters"
          : null,
    },
  });

  const onSubmitHandler = async (values: any) => {
    try {
      let assignedRoles: CompanyRoleUser[] = [];
      roleValues.map((item) => {
        if (item.checked) {
          let role: CompanyRoleUser = {
            roleID: item.roleID ? item.roleID : 0,
          };
          assignedRoles.push(role);
        }
      });

      let companyUser: CompanyUser = {
        companyID: 0,
        default: true,
        email: values.email,
        userName: values.username,
        phone: values.phone,
        disabled: disabled,
        roleUser: assignedRoles,
        userID: userId,
      };

      if (assignedRoles.length > 0) {
        await commonApi
          .postApiCall("/companies/users", companyUser)
          .then((resp) => {
            navigate("/user/list");
          });
      } else {
        alerts.showClientError({
          message: "Please select atleast 1 role for this user",
        });
      }
    } catch (error) {
      alerts.showError(error);
    }
  };

  const getRoleList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/users/roles");
    } catch (error) {}

    if (data) {
      setRoleData(data);
      handlers.setState(data);
    }
  };

  const getUserList = async () => {
    let data = null;
    let companyId = 0;
    let userList: SelectType[] = [];
    try {
      data = await commonApi.getApiCall("/users/existingusers/" + companyId);
      if (data) {
        data.map((user: User) => {
          let d: SelectType = {
            label: user.name,
            value: user.userID.toString(),
          };
          userList.push(d);
        });
        setUserSelectData(userList);
        setUserlist(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getUserList();
    getRoleList();
  }, []);

  const roleItems = roleValues.map((value, index) => (
    <Checkbox
      mt="xs"
      ml={33}
      label={value.name}
      key={value.roleID}
      checked={value.checked}
      onChange={(event) => {
        handlers.setItemProp(index, "checked", event.currentTarget.checked);
      }}
    />
  ));

  return (
    <Box className="fade-in-block width33">
      <form
        onSubmit={form.onSubmit((values) => {
          values.disabled = disabled;
          onSubmitHandler(values);
        })}
      >
        <Select
          label="Existing User"
          placeholder="User"
          data={userSelectData}
          allowDeselect={true}
          clearable
          searchable
          nothingFoundMessage="Nothing found..."
          onChange={(value) => {
            const selectedUser = userlist.find(
              (user) => user.userID === parseInt(value as string)
            );

            if (selectedUser) {
              setUserId(parseInt(value as string));
              form.setValues({
                userId: userId,
                username: selectedUser.name,
                disabled: selectedUser.disabled,
                phone: selectedUser.phone,
                email: selectedUser.email,
              });
            } else {
              setUserId(0);
              form.reset();
            }
          }}
        />
        <TextInput
          withAsterisk
          label="Name"
          disabled={userId > 0 ? true : false}
          placeholder="name of user "
          {...form.getInputProps("username")}
        />
        <TextInput
          withAsterisk
          label="Email"
          disabled={userId > 0 ? true : false}
          placeholder="email address"
          {...form.getInputProps("email")}
        />
        <TextInput
          withAsterisk
          label="Phone"
          disabled={userId > 0 ? true : false}
          placeholder="phone number"
          {...form.getInputProps("phone")}
        />
        <Switch
          checked={disabled}
          label="Disabled"
          disabled={userId > 0 ? true : false}
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
          onChange={(event) => setDisabled(event.currentTarget.checked)}
        />

        <Fieldset legend="User Roles"> {roleItems}</Fieldset>

        <Group justify="flex-end" mt="md">
          <Button
            onClick={(event) => {
              event.preventDefault();
              navigate("/User/List");
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </Box>
  );
}
