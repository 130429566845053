import { IconFilePlus, IconEdit, IconFolder, IconHome, IconListCheck, IconQuestionMark, IconSearch, IconExclamationCircle, IconTrashFilled } from '@tabler/icons-react';
//https://tabler.io/icons
export default function NavLinkIcon(
    name: string, 
    isFolder: boolean){
    var size: string = "1em";
    if (isFolder){
        name = "IconFolder";
    }
    switch(name) { 
        case "IconFolder": { 
            return (<IconFolder size={size} />);
        }   
        case "IconAdd": { 
            return (<IconFilePlus size={size} />);
        }                 
        case "IconQuestionMark": { 
            return (<IconQuestionMark size={size} />);
        }           
        case "IconHome": { 
            return (<IconHome size={size} />);
        }                 
        case "IconEdit": { 
            return (<IconEdit size={size} />);
        }         
        case "IconList": { 
            return (<IconListCheck size={size} />);
        } 
        case "IconFind": {
            return (<IconSearch size={size} />);
        }
        case "IconView": {
            return (<IconSearch size={size} />);
        }
        case "IconAction": {
            return (<IconExclamationCircle size={size} />);
        }
        case "IconDelete": {
            return (<IconTrashFilled size={size} />);
        }
        default: { 
            return (<span className="icon-spacing">&nbsp;</span>);
        } 
     }     
}