import { Modal, Center } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { GoogleLogin } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Utility from "../common/utils";
import { useAuth } from "./user/useAuth";

type LoginProps = {
  needLogin?: boolean;
  loginReady?: () => void;
};
const Login = ({ needLogin = false, loginReady = () => {} }: LoginProps) => {
  const [credentials, setCredentials] = useState("");
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(true);
  const { user, login } = useAuth();
  const [isValidUserData, setIsValidUserData] = useState(false);

  useEffect(() => {
    if (credentials && !isValidUserData) {
      var base64Url = credentials.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var decodedUser = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      const decondedUserJSON = JSON.parse(decodedUser);

      const {
        aud,
        azp,
        email_verified,
        exp,
        iat,
        iss,
        jti,
        nbf,
        sub,
        ...userTrimmed
      } = decondedUserJSON;

      login(userTrimmed);
      setIsValidUserData(true);
      close();
      // navigate("/");
      loginReady();
    }
  }, [credentials, isValidUserData, login]);

  return (
    <Modal
      opened={needLogin}
      onClose={close}
      withCloseButton={false}
      centered
      size="sm"
    >
      <Center>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            const { credential } = credentialResponse;
            setIsValid(true);
            setCredentials(credential as string);
            Utility.addToStorage(
              "userCredential",
              credential ? credential : ""
            );
          }}
          type="standard"
        />
      </Center>
    </Modal>
  );
};

export default Login;
