import axios from "axios";
import Utility from "./utils";

const apiUrl = process.env.REACT_APP_API_URL,
  token = Utility.getValueFromStorage("userCredential")
    ? Utility.getValueFromStorage("userCredential")
    : "";

const apiInitializer = axios.create({    
  baseURL: apiUrl
});

apiInitializer.interceptors.response.use(
  response => response,
  error => {
    if (!error.response) {
      // network error
      console.log('Error: Network Error - please check your internet connection');
      //return window.location.href = '/connectionError'
  } else {

    const status = error.response ? error.response.status : null;
    
    if (status === 401) {
      // Handle unauthorized access
      console.log("Unauthorized access");
      
      //source.cancel("Operation canceled due to unauthorized access");
      return new Promise(() => {});

    } else if (status === 404) {
      // Handle not found errors
      console.log("Not found");
    } else {
      // Handle other errors
      //console.error("An error occurred:", error);
    }
    
  }
    
    return Promise.reject(error);
  }
);


apiInitializer.interceptors.request.use(config => {
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  }
  else{
    const controller = new AbortController();
    const cfg = {
      ...config,
      signal: controller.signal
    };
    controller.abort("controller axios abort");
    return cfg;
  }
});    

export default apiInitializer;
// export default axios.create({
//   baseURL: apiUrl,
  // headers: {
  //   Authorization: `Bearer ${token}`,
  // },
//});
