import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import commonApi from "../../../api/commonApi";
import PostingYear from "../../../types/generalLedger/postingYear";
import PaginationSection from "../../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../../common/Sections/Pagination/PaginationSummary";
import PostingYearSearch from "./postingYearSearch";
import { ActionIcon, Box, Group, Text } from "@mantine/core";
import { IconEdit, IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { modals, openModal } from "@mantine/modals";
import PostingYearFormEdit from "./postingYearFormEdit";
import extensionMethods from "../../../common/extensionMethods";
import alerts from "../../common/alerts";

export default function PostingYearList() {
  const navigate = useNavigate();
  const [postingYears, setPostingYears] = useState<PostingYear[]>([]);
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [params, setParams] = useState({});
  const [totalRecords, setTotalRecords] = useState(1);
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<PostingYear>
  >({
    columnAccessor: "",
    direction: "asc",
  });
  const getPostingYearList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/posting/years?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      setTotalRecords(data.length);
      setPostingYears(data);
    }
  };

  useEffect(() => {
    getPostingYearList();
  }, [sortStatus, page, pageSize, params]);

  function showEditModal(
    postingYearID: number,
    setState?: Dispatch<SetStateAction<boolean>>
  ) {
    openModal({
      modalId: "edit",
      title: "Showing posting year information",
      children: (
        <BrowserRouter>
          <PostingYearFormEdit
            id={postingYearID.toString()}
            setState={setState}
          />
        </BrowserRouter>
      ),
    });
  }

  function showDeleteModal(postingYearID: number) {
    modals.openConfirmModal({
      title: "Delete posting year",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this posting year/s.
        </Text>
      ),
      labels: { confirm: "Delete posting year", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        try {
          await commonApi
            .deleteApiCall("/posting/years/" + postingYearID)
            .then(() => {
              alerts.showMessage("Posting Year deleted");
              getPostingYearList();
            });
        } catch (error) {
          alerts.showError(error);
        }
      },
    });
  }

  return (
    <div className="fade-in-block width100">
      <PostingYearSearch setParamsFunction={setParams} />
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        records={postingYears}
        idAccessor="PostingYearID"
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        columns={[
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "startDate",
            title: "Year Start",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: ({ startDate }) => extensionMethods.dateDisplay(startDate),
          },
          {
            accessor: "endDate",
            title: "Year End",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: ({ endDate }) => extensionMethods.dateDisplay(endDate),
          },
          {
            accessor: "postingPeriod",
            title: "Open Posting Period",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "periodOpenCount",
            title: "Open Periods",
            textAlign: "center",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "periodCloseCount",
            title: "Closed Periods",
            textAlign: "center",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "status",
            title: "Status",
            textAlign: "center",
            sortable: true,
            width: "5%",
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (postingYear) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showEditModal(
                      postingYear.postingYearID,
                      setIsSuccessUpdate
                    );
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showDeleteModal(postingYear.postingYearID);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/PostingYear/Edit/" + record.postingYearID);
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
