import {
  Button,
  Collapse,
  Flex,
  useMantineTheme,
  Container,
  Divider,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconSearch, IconChevronUp } from "@tabler/icons-react";

type CollapsibleWithActionButtonProps = {
  closeCollapsibleLabel?: string;
  openCollapsibleLabel?: string;
  children: React.ReactNode;
};

export default function CollapsibleWithActionButton({
  closeCollapsibleLabel = "Close Search",
  openCollapsibleLabel = "Search Item",
  children,
}: CollapsibleWithActionButtonProps) {
  const theme = useMantineTheme();
  const [opened, { toggle }] = useDisclosure(false);

  return (
    <Flex direction="column" align="flex-end" justify="flex-start">
      <Button
        mb="md"
        color={opened ? theme.colors.secondaryColor[1] : ""}
        onClick={toggle}
        rightSection={
          opened ? <IconChevronUp size={14} /> : <IconSearch size={14} />
        }
        size={"sm"}
      >
        {opened ? closeCollapsibleLabel : openCollapsibleLabel}
      </Button>
      <Container fluid style={{ width: "100%" }}>
        <Collapse
          in={opened}
          transitionDuration={500}
          transitionTimingFunction="linear"
        >
          {children}
          <Divider my="sm" />
        </Collapse>
      </Container>
    </Flex>
  );
}
