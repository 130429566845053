import { useNavigate, useParams } from "react-router-dom";
import Currency from "../../../types/company/currenty";
import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import commonApi from "../../../api/commonApi";
import alerts from "../../common/alerts";
import { Box, Button, Group, TextInput } from "@mantine/core";

export default function CurrencyFormEdit() {
  const navigate = useNavigate();
  const [currency, setCurrency] = useState<Currency>({} as Currency);
  const { systemCurrencyID } = useParams();

  const form = useForm({
    initialValues: {
      systemCurrencyID: 0,
      name: "",
      symbol: "",
      isoCode: "",
      fractionalUnit: "",
    },
    validate: {
      name: (value) =>
        value.length < 1 ? "Name must have at least 1 characters" : null,
      symbol: (value) =>
        value.length < 1 ? "Symbol must have at least 1 characters" : null,
      isoCode: (value) =>
        value.length < 1 ? "ISO code must have at least 1 characters" : null,
      fractionalUnit: (value) =>
        value.length < 1
          ? "Fractional Unit must have at least 1 characters"
          : null,
    },
  });

  const getCurrencyById = async () => {
    let data = null;
    try {
      await commonApi
        .getApiCall("/currencies/" + systemCurrencyID)
        .then((response: any) => {
          if (response) {
            data = response;
            setCurrency(response);
            if (data) {
              form.setValues({
                systemCurrencyID: data.systemCurrencyID,
                name: data.name,
                symbol: data.symbol,
                isoCode: data.isoCode,
                fractionalUnit: data.fractionalUnit,
              });
            }
          }
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  const onSubmitHandler = async (values: any) => {
    try {
      await commonApi
        .putApiCall("/currencies/" + values.systemCurrencyID, values)
        .then((response) => {
          if (response === "") {
            alerts.showMessage("Currency details updated");
          }
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  useEffect(() => {
    getCurrencyById();
  }, []);
  return (
    <div>
      <Box className="fade-in-block width33">
        <form
          onSubmit={form.onSubmit((values) => {
            onSubmitHandler(values);
          })}
        >
          <TextInput
            withAsterisk
            label="Name"
            placeholder="name"
            {...form.getInputProps("name")}
          />
          <TextInput
            withAsterisk
            label="Symbol"
            placeholder="symbol"
            {...form.getInputProps("symbol")}
          />
          <TextInput
            withAsterisk
            label="ISO Code"
            placeholder="iso code"
            {...form.getInputProps("isoCode")}
          />
          <TextInput
            withAsterisk
            label="Fractional Unit"
            placeholder="exchange rate detail"
            {...form.getInputProps("fractionalUnit")}
          />
          <Group justify="flex-end" mt="md">
            <Button
              onClick={(event) => {
                event.preventDefault();
                navigate("/Admin/Currency/List");
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Update</Button>
          </Group>
        </form>
      </Box>
    </div>
  );
}
