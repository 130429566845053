import { DateInput } from "@mantine/dates";
export default function JournalReversingDate(props: any){
    var transactionTypeID = parseInt(props.transactionTypeID);
    if (transactionTypeID === 1){
        return <><div className="information">A transaction type of Standard means that the journal will write to the general ledger as a single transaction that will not required any further consideration or action.</div></>
    }
    else{
        return <>
            <DateInput
                withAsterisk
                label="Reversing Date"
                placeholder="reversing date"
                {...props.form.getInputProps("reversingDate")} />
            <div className="information">A transaction type of Reversing means that the journal will write to the general ledger (probably to an accrual account) that will automatically reverse itself on the selected reversing date.</div>
        </>
    }
};