import {
  Box,
  Select,
  TextInput,
  Group,
  Button,
  ActionIcon,
  Fieldset,
} from "@mantine/core";
import { useEffect, useState } from "react";
import commonApi from "../../api/commonApi";
import Company from "../../types/company/company";
import { useLocation, useNavigate, BrowserRouter } from "react-router-dom";
import Schedule from "../../types/tax/schedule";
import { useForm } from "@mantine/form";
import alerts from "../common/alerts";
import { DataTable } from "mantine-datatable";
import { IconMoodConfuzed, IconPlus, IconTrash } from "@tabler/icons-react";
import { openModal } from "@mantine/modals";
import RateDetailListPopup from "./rateDetailListPopup";
import ScheduleRateDetail from "../../types/tax/scheduleRateDetail";

type SelectType = {
  value: string;
  label: string;
};

export default function ScheduleAdd() {
  const navigate = useNavigate();
  const location = useLocation();
  const [schedule, setSchedule] = useState<Schedule>({} as Schedule);
  const [companySelectData, setCompanySelectData] = useState<SelectType[]>([]);
  const [companyId, setCompanyId] = useState("");
  const [assignedRates, setAssignedRates] = useState<ScheduleRateDetail[]>([]);
  const [selectedRecords, setSelectedRecords] = useState<ScheduleRateDetail[]>(
    []
  );
  const [totalAggregateTax, setTotalAggregateTax] = useState(0);

  const form = useForm({
    initialValues: {
      scheduleID: 0,
      companyID: 0,
      name: "",
      code: "",
    },
    validate: {
      name: (value) =>
        value.length < 1 ? "Name must have at least 1 characters" : null,
      code: (value) =>
        value.length < 1 ? "Code must have at least 1 characters" : null,
      companyID: (value) =>
        value === 0 || value === null ? "Please select a company" : null,
    },
  });

  let compList: SelectType[] = [];
  const getCompanyList = async () => {
    let data = null;
    const companyGroupId = 0;
    try {
      data = await commonApi.getApiCall("/companiesbygroup/" + companyGroupId);
    } catch (error) {}

    if (data) {
      data.map((company: Company) => {
        let d: SelectType = {
          label: company.name,
          value: company.companyID.toString(),
        };
        compList.push(d);
      });
      setCompanySelectData(compList);
    }
  };

  const onSubmitHandler = async (values: any) => {
    let schedule: Schedule = {
      name: values.name,
      code: values.code,
      companyID: values.companyID,
      rateDetails: assignedRates,
    };

    try {
      await commonApi.postApiCall("/schedules", schedule).then((resp) => {
        navigate("/Tax/Schedule/List");
      });
    } catch (error) {
      alerts.showError(error);
    }
  };

  const showRateDetailsModal = () => {
    openModal({
      modalId: "list",
      title: "Rate Detail List",
      size: "50vw",
      children: (
        <BrowserRouter>
          <RateDetailListPopup
            assignedRates={assignedRates}
            setState={setAssignedRates}
          />
        </BrowserRouter>
      ),
    });
  };

  const handleRemoveItem = (rateDetail: any) => {
    var ratesArray = assignedRates;
    let item = ratesArray.filter(
      (x) => x.rateDetailID == rateDetail.rateDetailID
    )[0];
    let index = ratesArray.indexOf(item);
    ratesArray.splice(index, 1);

    let array: ScheduleRateDetail[] = [];
    ratesArray.forEach((x) => {
      let rate: ScheduleRateDetail = {
        rateDetailID: x.rateDetailID,
        ratePercentage: x.ratePercentage,
        scheduleID: x.scheduleID,
        name: x.name,
        canBeDeleted: x.canBeDeleted,
      };
      array.push(rate);
    });
    setTotalAggregateTax(0);
    setAssignedRates(array);
  };

  useEffect(() => {
    let totalTax = 0;
    assignedRates.forEach((x) => {
      totalTax = totalTax + x.ratePercentage;
    });
    setTotalAggregateTax(totalTax);
    getCompanyList();
  }, [assignedRates]);

  return (
    <Box className="fade-in-block width33">
      <form
        style={{ paddingBottom: "25px" }}
        onSubmit={form.onSubmit((values) => {
          onSubmitHandler(values);
        })}
      >
        <Select
          label="Company"
          withAsterisk
          placeholder="Company"
          data={companySelectData}
          allowDeselect={true}
          clearable
          searchable
          nothingFoundMessage="Nothing found..."
          {...form.getInputProps("companyID")}
        />
        <TextInput
          withAsterisk
          label="Name"
          placeholder="name of schedule"
          {...form.getInputProps("name")}
        />
        <TextInput
          withAsterisk
          label="Code"
          placeholder="code"
          {...form.getInputProps("code")}
        />
        <TextInput
          label="Total Tax"
          disabled={true}
          style={{ paddingBottom: "25px" }}
          value={totalAggregateTax}
        />
        <Fieldset legend="Rate Details" style={{ paddingTop: "0px" }}>
          <Group justify="flex-end" mt="md">
            <ActionIcon
              size="sm"
              variant="subtle"
              color="blue"
              onClick={(e: React.MouseEvent) => {
                showRateDetailsModal();
              }}
            >
              <IconPlus size={16} />
            </ActionIcon>
            <ActionIcon
              size="sm"
              variant="subtle"
              color="blue"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                e.stopPropagation();
                if (selectedRecords.length > 0)
                  selectedRecords.forEach((item) => {
                    handleRemoveItem(item);
                  });
                else {
                  alerts.showClientError({
                    message: "No selected record to delete",
                  });
                }
              }}
            >
              <IconTrash size={16} />
            </ActionIcon>
          </Group>
          <DataTable
            withTableBorder
            borderRadius="sm"
            withColumnBorders
            striped
            highlightOnHover
            textSelectionDisabled
            pinLastColumn
            idAccessor="rateDetailID"
            records={assignedRates}
            columns={[
              {
                accessor: "name",
                title: "Name",
                textAlign: "left",
                sortable: true,
              },
              {
                accessor: "ratePercentage",
                title: "Rate Percentage",
                textAlign: "left",
                sortable: true,
              },
              {
                accessor: "actions",
                title: "Actions",
                textAlign: "right",
                width: "1%",
                render: (rateDetail) => (
                  <Group gap={10} justify="space-around" wrap="nowrap">
                    <ActionIcon
                      size="sm"
                      variant="subtle"
                      color="red"
                      disabled={!rateDetail.canBeDeleted}
                      onClick={(e: React.MouseEvent) => {
                        handleRemoveItem(rateDetail);
                      }}
                    >
                      <IconTrash size={16} />
                    </ActionIcon>
                  </Group>
                ),
              },
            ]}
            selectedRecords={selectedRecords}
            onSelectedRecordsChange={setSelectedRecords}
            minHeight={150}
            noRecordsIcon={
              <Box p={4} mb={4}>
                <IconMoodConfuzed size={36} strokeWidth={1.5} />
              </Box>
            }
            noRecordsText="No records found"
          />
        </Fieldset>
        <Group justify="flex-end" mt="md">
          <Button
            onClick={(event) => {
              event.preventDefault();
              navigate("/Tax/Schedule/List");
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </Box>
  );
}
