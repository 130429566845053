import { Box, ActionIcon, Group, Button, Text } from "@mantine/core";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import User from "../../types/user/user";
import { IconEdit, IconTrash, IconMoodConfuzed } from "@tabler/icons-react";
import { modals, openModal } from "@mantine/modals";
import { BrowserRouter, useNavigate } from "react-router-dom";
import PaginationSection from "../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../common/Sections/Pagination/PaginationSummary";
import commonApi from "../../api/commonApi";
import AdminUserSearch from "./adminUserSearch";
import AdminUserFormEdit from "./adminUserFormEdit";
import companyApi from "../../api/company/companyApi";
import alerts from "../common/alerts";

function showModal(
  userID: number,
  setState?: Dispatch<SetStateAction<boolean>>
) {
  openModal({
    modalId: "edit",
    title: "Showing user information",
    children: (
      <BrowserRouter>
        <AdminUserFormEdit id={userID} setState={setState} />
      </BrowserRouter>
    ),
  });
}

export default function AdminCUserList() {
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<User>>({
    columnAccessor: "name",
    direction: "asc",
  });
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [totalRecords, setTotalRecords] = useState(1);
  const [params, setParams] = useState({});
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<User[]>([]);

  const getUserList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/admin/userViews?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      setTotalRecords(data.length);
      setUsers(data);
    }
  };

  const openDeleteModal = (user?: User, selectedUsers?: User[]) => {
    modals.openConfirmModal({
      title: "Delete user",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this user/s.</Text>
      ),
      labels: { confirm: "Delete user", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        if (user) {
          if (user.userID) {
            try {
              await commonApi
                .deleteApiCall("/users/" + user.userID)
                .then((response) => {
                  alerts.showMessage("Company user delete success");
                  getUserList();
                });
            } catch (error) {
              alerts.showError(error);
            }
          }
        }

        if (selectedUsers) {
          let selectedCount = selectedUsers.length;
          let counter = 0;
          selectedUsers.forEach(async (user) => {
            if (user.userID) {
              await commonApi
                .deleteApiCall("/users/" + user.userID)
                .then((response) => {
                  counter++;
                });

              if (counter === selectedCount) {
                alerts.showMessage("Company user delete success");
                setSelectedRecords([]);
                getUserList();
              }
            }
          });
        }
      },
    });
  };

  useEffect(() => {
    getUserList();
  }, [isSuccessUpdate, sortStatus, page, pageSize, params]);

  return (
    <div>
      <AdminUserSearch setParamsFunction={setParams} />
      <Group mt="md" justify="flex-end">
        <Button
          onClick={() => {
            navigate("/Admin/User/Add");
          }}
        >
          Add
        </Button>
        <Button
          onClick={() => {
            if (selectedRecords.length > 0) {
              openDeleteModal(undefined, selectedRecords);
            } else {
              alerts.showClientError({
                message: "No selected record to delete",
              });
            }
          }}
        >
          Delete
        </Button>
      </Group>
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        pinLastColumn
        records={users}
        idAccessor="userID"
        isRecordSelectable={(record) => record.canBeDeleted === true}
        columns={[
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "companyGroupName",
            title: "Company Group",
            textAlign: "left",
            sortable: true,
            width: "15%",
          },
          {
            accessor: "email",
            title: "Email",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "phone",
            title: "Phone",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "defaultCompanyName",
            title: "Default Company",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.lg})`,
          },
          {
            accessor: "companyCount",
            title: "Companies",
            textAlign: "center",
            sortable: true,
            width: "5%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.lg})`,
          },
          {
            accessor: "status",
            title: "Status",
            textAlign: "center",
            sortable: true,
            width: "5%",
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (user) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showModal(user.userID, setIsSuccessUpdate);
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  disabled={!user.canBeDeleted}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    openDeleteModal(user);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/Admin/User/Edit/" + record.userID);
        }}
        selectedRecords={selectedRecords}
        onSelectedRecordsChange={setSelectedRecords}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
