import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { Box, Button, Group, Select, Switch, TextInput } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import commonApi from "../../api/commonApi";
import alerts from "../common/alerts";
import Currency from "../../types/company/currenty";
import CompanyGroup from "../../types/company/companyGroup";

type SelectType = {
  value: string;
  label: string;
  disabled: boolean;
};

export default function AdminCompanyFormAdd() {
  const navigate = useNavigate();
  const [currencyList, setCurrencyList] = useState<Currency[]>([]);
  const [currencyData, setCurrencyData] = useState<SelectType[]>([]);
  const [groupData, setGroupData] = useState<SelectType[]>([]);
  const [disabled, setDisabled] = useState(false);

  const form = useForm({
    initialValues: {
      name: "",
      companyGroupID: 0,
      createdDate: new Date(),
      currencyId: 0,
      disabled: false,
    },
    validate: {
      name: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      companyGroupID: (value) =>
        value === 0 ? "Please select company group" : null,
      currencyId: (value) =>
        value === 0 ? "Please select currency value" : null,
    },
  });

  const onSubmitHandler = async (values: any) => {
    try {
      await commonApi.postApiCall("/admin/companies", values).then((resp) => {
        navigate("/Admin/Company/List");
      });
    } catch (error) {
      alerts.showError(error);
    }
  };

  let groupList: SelectType[] = [];
  const getCompanyGrpList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/groups");
    } catch (error) {}

    if (data) {
      data.map((group: CompanyGroup) => {
        let d: SelectType = {
          label: group.name,
          value: group.companyGroupID.toString(),
          disabled: false,
        };
        groupList.push(d);
      });
      setGroupData(groupList);
    }
  };

  //get currency
  let list: SelectType[] = [];
  const getCurrencies = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/currencies");
    } catch (error) {}

    if (data) {
      setCurrencyList(data);
      data.map((currency: Currency) => {
        let d: SelectType = {
          label: currency.name,
          value: currency.systemCurrencyID.toString(),
          disabled: false,
        };

        list.push(d);
      });
      setCurrencyData(list);
    }
  };

  useEffect(() => {
    getCompanyGrpList();
    getCurrencies();
  }, []);

  return (
    <Box className="fade-in-block width33">
      <form
        onSubmit={form.onSubmit((values) => {
          values.disabled = disabled;
          onSubmitHandler(values);
        })}
      >
        <TextInput
          withAsterisk
          label="Name"
          placeholder="Name of company"
          {...form.getInputProps("name")}
        />
        <Select
          label="Company Group"
          withAsterisk
          placeholder="Company Group"
          data={groupData}
          allowDeselect={true}
          clearable
          searchable
          nothingFoundMessage="Nothing found..."
          {...form.getInputProps("companyGroupID")}
        />
        <Select
          label="Currency"
          withAsterisk
          placeholder="Currency"
          data={currencyData}
          allowDeselect={true}
          clearable
          searchable
          nothingFoundMessage="Nothing found..."
          {...form.getInputProps("currencyId")}
        />
        <Switch
          checked={disabled}
          label="Disabled"
          style={{ paddingTop: "10px" }}
          onChange={(event) => setDisabled(event.currentTarget.checked)}
        />
        <Group justify="flex-end" mt="md">
          <Button
            onClick={(event) => {
              event.preventDefault();
              navigate("/Admin/Company/List");
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </Box>
  );
}
