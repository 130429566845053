import { Box, ActionIcon, Group, Button, Text } from "@mantine/core";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import Company from "../../types/company/company";
import dayjs from "dayjs";
import sortBy from "lodash/sortBy";
import { IconEdit, IconTrash, IconMoodConfuzed } from "@tabler/icons-react";
import { modals, openConfirmModal, openModal } from "@mantine/modals";
import { BrowserRouter, useNavigate } from "react-router-dom";
import PaginationSection from "../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../common/Sections/Pagination/PaginationSummary";
import commonApi from "../../api/commonApi";
import CompanyGroupCompanySearch from "./companyGroupCompanySearch";
import CompanyGroupCompanyFormEdit from "./companyGroupCompanyFormEdit";
import companyApi from "../../api/company/companyApi";
import alerts from "../common/alerts";

export default function CompanyGroupCompanyList() {
  const navigate = useNavigate();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Company>>({
    columnAccessor: "name",
    direction: "asc",
  });
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [totalRecords, setTotalRecords] = useState(1);
  const [params, setParams] = useState({});
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<Company[]>([]);

  const showModal = ({
    company,
    action,
    setState,
  }: {
    company: Company;
    action: "view" | "edit" | "delete";
    setState?: Dispatch<SetStateAction<boolean>>;
  }) => {
    let buttonText = "Close";
    switch (action) {
      case "view":
        buttonText = "Close";
        break;
      case "edit":
        buttonText = "Update";
        break;
    }
    openModal({
      modalId: action,
      title:
        action === "view"
          ? "Showing company information"
          : action === "edit"
          ? "Editing company information"
          : "Deleting company",
      children: (
        <BrowserRouter>
          {action === "edit" ? (
            <CompanyGroupCompanyFormEdit
              id={company.companyID}
              setState={setState}
            />
          ) : (
            "Additional company information"
          )}
        </BrowserRouter>
      ),
    });
  };

  const openDeleteModal = (company?: Company, selectedRecords?: Company[]) => {
    modals.openConfirmModal({
      title: "Delete company",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this company record/s.
        </Text>
      ),
      labels: { confirm: "Delete company", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        if (company) {
          try {
            await commonApi
              .deleteApiCall("/companies/" + company.companyID)
              .then((response) => {
                alerts.showMessage("Company delete success");
                getCompanyList();
              });
          } catch (error) {
            alerts.showError(error);
          }
        }

        if (selectedRecords) {
          let selectedCount = selectedRecords.length;
          let counter = 0;
          selectedRecords.forEach(async (company) => {
            await commonApi
              .deleteApiCall("/companies/" + company.companyID)
              .then((response) => {
                counter++;
              });

            if (counter === selectedCount) {
              alerts.showMessage("Company record/s delete success");
              setSelectedRecords([]);
              getCompanyList();
            }
          });
        }
      },
    });
  };

  const getCompanyList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/companyView?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      setTotalRecords(data.length);
      setCompanies(data);
    }
  };

  useEffect(() => {
    getCompanyList();
  }, [isSuccessUpdate, sortStatus, page, pageSize, params]);

  return (
    <div>
      <CompanyGroupCompanySearch setParamsFunction={setParams} />
      <Group mt="md" justify="flex-end">
        <Button
          onClick={() => {
            navigate("/CompanyGroup/Company/Add");
          }}
        >
          Add
        </Button>
        <Button
          onClick={() => {
            if (selectedRecords.length > 0) {
              openDeleteModal(undefined, selectedRecords);
            } else {
              alerts.showClientError({
                message: "No selected record to delete",
              });
            }
          }}
        >
          Delete
        </Button>
      </Group>
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        pinLastColumn
        records={companies}
        idAccessor="companyID"
        isRecordSelectable={(record) => record.canBeDeleted === true}
        columns={[
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "currencyName",
            title: "Currency",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "userCount",
            title: "Users",
            textAlign: "center",
            sortable: true,
            width: "5%",
          },
          {
            accessor: "accountCount",
            title: "Accounts",
            textAlign: "center",
            sortable: true,
            width: "5%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "status",
            title: "Status",
            textAlign: "center",
            sortable: true,
            width: "5%",
          },
          {
            accessor: "createdDate",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: ({ createdDate }) =>
              dayjs(createdDate).format("MMM DD YYYY"),
          },
          {
            accessor: "updatedDate",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: ({ updatedDate }) =>
              dayjs(updatedDate).format("MMM DD YYYY"),
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (company) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showModal({
                      company,
                      action: "edit",
                      setState: setIsSuccessUpdate,
                    });
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  disabled={!company.canBeDeleted}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    openDeleteModal(company);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/CompanyGroup/Company/Edit/" + record.companyID);
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        selectedRecords={selectedRecords}
        onSelectedRecordsChange={setSelectedRecords}
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
