import {
  TextInput,
  Checkbox,
  Button,
  Group,
  Box,
  NativeSelect,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import ButtonGroup from "../../common/ButtonGroup";
import SearchSection from "../../common/Sections/Search/SearchSection";

export default function AccountCategoryTypeSearch(props: any) {
  const form = useForm({
    initialValues: {
      name: "",
    },
  });
  return (
    <form onSubmit={form.onSubmit((values) => props.setParamsFunction(values))}>
      <SearchSection
        buttonSection={
          <ButtonGroup
            cancelLabel="Reset"
            cancelClick={form.reset}
            okLabel="Search"
          />
        }
      >
        <TextInput
          label="Name"
          placeholder="search by name"
          {...form.getInputProps("name")}
        />
      </SearchSection>
    </form>
  );
}
