import { useForm } from "@mantine/form";
import AddressFormAdd from "../address/addressFormAdd";
import { useNavigate, useParams } from "react-router-dom";
import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Fieldset,
  Group,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import commonApi from "../../api/commonApi";
import { IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import CreditorAddress from "../../types/creditor/creditorAddress";
import Address from "../../types/address/address";
import { modals } from "@mantine/modals";
import Creditor from "../../types/creditor/creditor";
import alerts from "../common/alerts";

export default function CreditorAddressManage({
  creditor,
  setCreditor,
}: {
  creditor?: Creditor;
  setCreditor?: Dispatch<SetStateAction<Creditor>>;
}) {
  const navigate = useNavigate();
  const { creditorId } = useParams();
  const form = useForm();
  const addressForm = useForm();
  const [isPrimaryBillingAddress, setIsPrimaryBillingAddress] =
    useState<boolean>(false);
  const [isPrimaryShippingAddress, setIsPrimaryShippingAddress] =
    useState<boolean>(false);
  const [creditorAddressList, setCreditorAddressList] = useState<
    CreditorAddress[]
  >([]);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [params, setParams] = useState({});
  const [totalRecords, setTotalRecords] = useState(1);
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<CreditorAddress>
  >({
    columnAccessor: "",
    direction: "asc",
  });

  const [btnText, setBtnText] = useState("Add");
  const [isAddressUpdate, setIsAddressUpdate] = useState(false);
  const [primaryShippingID, setPrimaryShippingID] = useState(0);
  const [primaryBilllingID, setPrimaryBilllingID] = useState(0);
  const [addressIdToUpdate, setAddressIdToUpdate] = useState(0);
  const [creditorAddressIdToUpdate, setCreditorAddressIdToUpdate] = useState(0);

  const loadAdrressDefaultData = () => {
    let data: Address = {
      addressID: 0,
      address1: "",
      address2: "",
      city: "",
      cityZipID: 0,
      zipcode: "",
      stateID: 0,
      stateAbbreviation: "",
      stateName: "",
      countryID: 0,
      countryName: "",
      addressName: "",
    };
    addressForm.setValues({ address: data });
  };

  const getCreditorAddressFromState = () => {
    if (creditor) {
      if (creditor.creditorAddress)
        setCreditorAddressList(creditor.creditorAddress);
    }
  };

  const clearAddressForm = () => {
    addressForm.reset();
    setAddressIdToUpdate(0);
    setBtnText("Add");
    setIsAddressUpdate(false);
    setIsPrimaryBillingAddress(false);
    setIsPrimaryShippingAddress(false);
    loadAdrressDefaultData();
  };

  const onSubmitHandler = async (values: CreditorAddress) => {
    if (creditor) {
      if (creditor.creditorAddress) {
        //check if item is in list meaning it is an update
        var continueAdd = true;
        var newItems = creditor.creditorAddress.filter(
          (item) => item.creditorAddressID != values.creditorAddressID
        );
        debugger;
        if (values.isPrimaryBilling) {
          var primaryBilling = creditor.creditorAddress.filter(
            (item) => item.isPrimaryBilling == true
          );

          if (primaryBilling.length > 0) {
            alerts.showClientError({
              message: "Cannot have multiple primary billing address",
            });
            continueAdd = false;
          }
        } else if (values.isPrimaryShipping) {
          var primaryShipping = creditor.creditorAddress.filter(
            (item) => item.isPrimaryShipping == true
          );

          if (primaryShipping.length > 0) {
            alerts.showClientError({
              message: "Cannot have multiple primary shipping address",
            });
            continueAdd = false;
          }
        }

        if (continueAdd) {
          creditor.creditorAddress = newItems;
          creditor.creditorAddress.push(values);

          setCreditorAddressList(creditor.creditorAddress);
          if (setCreditor) setCreditor(creditor);
          clearAddressForm();
        }
      }
    }
  };

  const openDeleteModal = (creditorAddress?: CreditorAddress) => {
    modals.openConfirmModal({
      title: "Delete creditor address",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this creditor address.
        </Text>
      ),
      labels: { confirm: "Delete address", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        if (creditorAddress) {
          if (creditorAddress.creditorAddressID) {
            if (creditor?.creditorAddress) {
              var newItems = creditor?.creditorAddress.filter(
                (item) =>
                  item.creditorAddressID != creditorAddress.creditorAddressID
              );
              creditor.creditorAddress = newItems;
              setCreditorAddressList(newItems);
              clearAddressForm();
            }
          }
        }
      },
    });
  };

  useEffect(() => {
    getCreditorAddressFromState();
  }, []);

  useEffect(() => {
    loadAdrressDefaultData();
  }, [creditorAddressList]);

  return (
    <div>
      <div>
        <Box>
          <SimpleGrid cols={2}>
            <form
              onSubmit={addressForm.onSubmit((values) => {
                if (!isAddressUpdate) {
                  let creditorAddress: CreditorAddress = {
                    creditorAddressID: creditor?.creditorAddress
                      ? creditor?.creditorAddress.length + 1
                      : 0,
                    creditorID: 0,
                    address: values.address as Address,
                    isPrimaryBilling: isPrimaryBillingAddress,
                    isPrimaryShipping: isPrimaryShippingAddress,
                  };
                  onSubmitHandler(creditorAddress);
                } else {
                  values.addressID = addressIdToUpdate;
                  let creditorAddress: CreditorAddress = {
                    creditorAddressID: creditorAddressIdToUpdate,
                    creditorID: 0,
                    address: values.address as Address,
                    addressID: addressIdToUpdate,
                    isPrimaryBilling: isPrimaryBillingAddress,
                    isPrimaryShipping: isPrimaryShippingAddress,
                  };
                  onSubmitHandler(creditorAddress);
                }
              })}
            >
              <Fieldset legend="Details" className="fade-in-block width100">
                <AddressFormAdd form={addressForm} />
                <Checkbox
                  onChange={(event) =>
                    setIsPrimaryShippingAddress(event.currentTarget.checked)
                  }
                  checked={isPrimaryShippingAddress}
                  label="Primary shipping address"
                  style={{ paddingTop: "5px" }}
                />
                <Checkbox
                  onChange={(event) =>
                    setIsPrimaryBillingAddress(event.currentTarget.checked)
                  }
                  checked={isPrimaryBillingAddress}
                  label="Primary billing address"
                  style={{ paddingTop: "5px" }}
                />
                <Group justify="flex-end" mt="md">
                  <Button
                    onClick={() => {
                      clearAddressForm();
                    }}
                  >
                    Clear
                  </Button>
                  <Button type="submit">{btnText}</Button>
                </Group>
              </Fieldset>
            </form>
            <Box>
              <DataTable
                withTableBorder
                borderRadius="sm"
                withColumnBorders
                striped
                highlightOnHover
                textSelectionDisabled
                records={creditorAddressList}
                idAccessor="creditorAddressID"
                minHeight={50}
                height="80%"
                noRecordsIcon={
                  <Box p={4} mb={4}>
                    <IconMoodConfuzed size={36} strokeWidth={1.5} />
                  </Box>
                }
                noRecordsText="No records found"
                columns={[
                  {
                    accessor: "address.address1",
                    title: "Address 1",
                    textAlign: "left",
                    sortable: true,
                  },
                  {
                    accessor: "address.address2",
                    title: "Address 2",
                    textAlign: "left",
                    sortable: true,
                    visibleMediaQuery: (theme) =>
                      `(min-width: ${theme.breakpoints.md})`,
                  },
                  {
                    accessor: "address.city",
                    title: "City",
                    textAlign: "left",
                    sortable: true,
                    visibleMediaQuery: (theme) =>
                      `(min-width: ${theme.breakpoints.md})`,
                  },
                  {
                    accessor: "address.zipcode",
                    title: "Zip Code",
                    textAlign: "left",
                    sortable: true,
                    visibleMediaQuery: (theme) =>
                      `(min-width: ${theme.breakpoints.md})`,
                  },
                  {
                    accessor: "address.stateName",
                    title: "State",
                    textAlign: "left",
                    sortable: true,
                    visibleMediaQuery: (theme) =>
                      `(min-width: ${theme.breakpoints.md})`,
                  },
                  {
                    accessor: "address.countryName",
                    title: "Country",
                    textAlign: "left",
                    sortable: true,
                    visibleMediaQuery: (theme) =>
                      `(min-width: ${theme.breakpoints.md})`,
                  },
                  {
                    accessor: "actions",
                    title: "",
                    textAlign: "right",
                    width: "1%",
                    render: (creditorAddress) => (
                      <Group gap={4} justify="right" wrap="nowrap">
                        <ActionIcon
                          size="sm"
                          variant="subtle"
                          color="red"
                          onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            openDeleteModal(creditorAddress);
                          }}
                        >
                          <IconTrash size={16} />
                        </ActionIcon>
                      </Group>
                    ),
                  },
                ]}
                onRowClick={({ record, index, event }) => {
                  clearAddressForm();
                  setIsAddressUpdate(true);
                  setBtnText("Update");
                  let addressId = record.addressID ? record.addressID : 0;
                  let creditorAddressId = record.creditorAddressID
                    ? record.creditorAddressID
                    : 0;
                  setAddressIdToUpdate(addressId);
                  setCreditorAddressIdToUpdate(creditorAddressId);

                  if (record.isPrimaryBilling)
                    setIsPrimaryBillingAddress(record.isPrimaryBilling);
                  if (record.isPrimaryShipping)
                    setIsPrimaryShippingAddress(record.isPrimaryShipping);

                  addressForm.setValues({ address: record.address });
                }}
                sortStatus={sortStatus}
                onSortStatusChange={setSortStatus}
              />
            </Box>
          </SimpleGrid>
        </Box>
      </div>
    </div>
  );
}
