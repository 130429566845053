import { TextInput, Button, Group, NativeSelect } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import OptionItem from "../../types/common/optionItem";
import SearchSection from "../common/Sections/Search/SearchSection";
import ButtonGroup from "../common/ButtonGroup";

export default function ScheduleSearch(props: any) {
  const form = useForm({
    initialValues: {
      name: "",
      code: "",
    },
  });
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        props.setParamsFunction(values);
      })}
    >
      <SearchSection
        buttonSection={
          <ButtonGroup
            cancelLabel="Reset"
            cancelClick={form.reset}
            okLabel="Search"
          />
        }
      >
        <TextInput
          label="Name"
          placeholder="search by schedule name"
          {...form.getInputProps("name")}
        />
        <TextInput
          label="Code"
          placeholder="search by schedule code"
          {...form.getInputProps("code")}
        />
      </SearchSection>
    </form>
  );
}
