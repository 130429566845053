import { useEffect } from "react";
import { useUser } from "./useUser";
import { useLocalStorage } from "../common/useLocalStorage";
import LoggedUser from "../../types/login/loggedUser";

export const useAuth = () => {
  const { user, addUser, removeUser, updateUser } = useUser();
  const { setItem, getItem } = useLocalStorage();

  useEffect(() => {
    const user = getItem("user");
    if (user) {
      addUser(JSON.parse(user) as LoggedUser);
    }
  }, [addUser, getItem]);

  const login = (user: LoggedUser) => {
    addUser(user);
  };

  const logout = () => {
    removeUser();
    setItem("token", "");
    setItem("userCredential", "");
  };

  return { user, login, logout, updateUser };
};
