import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Role from "../../types/user/role";
import { useForm } from "@mantine/form";
import commonApi from "../../api/commonApi";
import alerts from "../common/alerts";
import AppRoute from "../../types/appRoute/appRoute";
import AppRouteCheckbox from "./appRouteCheckbox";
import { DataNode } from "antd/es/tree";
import { Box, Button, Fieldset, Group, Switch, TextInput } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";

export default function RoleEdit({
  roleID,
  setState,
}: {
  roleID?: number;
  setState?: Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const [role, setRole] = useState<Role>({} as Role);
  const { roleId } = useParams();
  const [isPopup, setIsPopup] = useState(false);
  const [selectedAppRouteIDs, setSelectedAppRouteIDs] = useState<number[]>([]);
  const [appRoutes, setAppRoutes] = useState<AppRoute[]>([]);
  const [isDefaultForAppAdmin, setIsDefaultForAppAdmin] = useState(false);
  const [isDefaultForCompanyAdmin, setIsDefaultForCompanyAdmin] =
    useState(false);
  const [isDefaultForAll, setIsDefaultForAll] = useState(false);
  const [isNameDisabled, setIsNameDisabled] = useState(false);

  const form = useForm({
    initialValues: {
      roleID: 0,
      name: "",
      defaultForAppAdmin: false,
      defaultForCompanyAdmin: false,
      defaultForForAll: false,
    },
    validate: {
      name: (value) =>
        value.length < 1 ? "Role name must have at least 1 characters" : null,
    },
  });

  const getRoleById = async () => {
    let data = null;
    if (!roleID) roleID = role.roleID;

    try {
      await commonApi
        .getApiCall("/security/roles/" + roleID)
        .then((response: any) => {
          if (response) {
            data = response;
            setRole(response);
            if (data) {
              form.setValues({
                name: data.name,
                defaultForAppAdmin: data.defaultForAppAdmin,
                defaultForCompanyAdmin: data.defaultForCompanyAdmin,
                defaultForForAll: data.defaultForAll,
              });
              if (data.name.toLowerCase() === "super admin") {
                setIsNameDisabled(true);
              }

              setIsDefaultForAll(data.defaultForAll);
              setIsDefaultForAppAdmin(data.defaultForAppAdmin);
              setIsDefaultForCompanyAdmin(data.defaultForCompanyAdmin);
            }
          }
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  const getRoleAppRouteById = async () => {
    let data = null;
    if (!roleID) roleID = role.roleID;
    try {
      await commonApi
        .getApiCall("/security/roleapproute/" + roleID)
        .then((response) => {
          if (response) {
            data = response;
            data.map((item: any) => {
              selectedAppRouteIDs.push(item.appRouteID);
            });
          }
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  const getAppRouteList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/approutes?");
    } catch (error) {}
    if (data) {
      setAppRoutes(data);
    }
  };

  const appendAppRouteChild = (
    parentAppRoute: DataNode,
    childAppRoutes: AppRoute[]
  ) => {
    childAppRoutes.map((appRoute: AppRoute) => {
      var child: DataNode = {
        title: appRoute.menuTitle,
        key: appRoute.appRouteID ? appRoute.appRouteID : 0,
        children: [],
      };
      parentAppRoute.children?.push(child);
      if (appRoute.childAppRoutes) {
        if (appRoute.childAppRoutes.length > 0)
          appendAppRouteChild(child, appRoute.childAppRoutes);
      }
    });
    return parentAppRoute;
  };

  const generateTreeViewUsingCheckbox = (appRouteList: AppRoute[]) => {
    var parentNodes: DataNode[] = [];
    appRouteList.map((approute: AppRoute) => {
      if (approute.childAppRoutes) {
        if (approute.childAppRoutes.length > 0) {
          var parentItem: DataNode = {
            title: approute.menuTitle,
            key: approute.appRouteID ? approute.appRouteID : 0,
            children: [],
          };
          parentNodes.push(parentItem);
          appendAppRouteChild(parentItem, approute.childAppRoutes);
        } else {
          var parentItem: DataNode = {
            title: approute.menuTitle,
            key: approute.appRouteID ? approute.appRouteID : 0,
            children: [],
          };

          parentNodes.push(parentItem);
        }
      }
    });
    return (
      <AppRouteCheckbox
        dataNodes={parentNodes}
        selectedIds={selectedAppRouteIDs}
        setState={setSelectedAppRouteIDs}
      />
    );
  };

  const onSubmitHandler = async (values: any) => {
    let role: Role = {
      roleID: roleId ? parseInt(roleId) : roleID,
      name: values.name,
      defaultForAll: isDefaultForAll,
      defaultForAppAdmin: isDefaultForAppAdmin,
      defaultForCompanyAdmin: isDefaultForCompanyAdmin,
      assignedAppRouteIds: selectedAppRouteIDs,
    };

    try {
      await commonApi
        .putApiCall("/security/roles/" + role.roleID, role)
        .then((resp) => {
          if (isPopup) {
            if (setState) {
              setState(true);
            }
            setSelectedAppRouteIDs([]);
            closeAllModals();
          } else {
            navigate("/Role/List");
            setSelectedAppRouteIDs([]);
          }
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  useEffect(() => {
    if (roleId) {
      roleID = parseInt(roleId);
      setIsPopup(false);
    } else {
      setIsPopup(true);
    }
    getRoleById();
    getRoleAppRouteById();
    getAppRouteList();
  }, []);

  return (
    <div>
      <Box className={!isPopup ? "fade-in-block width33" : ""}>
        <form
          onSubmit={form.onSubmit((values) => {
            onSubmitHandler(values);
          })}
        >
          <TextInput
            withAsterisk
            label="Name"
            placeholder="role name"
            disabled={isNameDisabled}
            {...form.getInputProps("name")}
          />
          <Switch
            checked={isDefaultForAppAdmin}
            label="Default for App Admin"
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
            onChange={(event) =>
              setIsDefaultForAppAdmin(event.currentTarget.checked)
            }
          />
          <Switch
            checked={isDefaultForCompanyAdmin}
            label="Default for Company Admin"
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
            onChange={(event) =>
              setIsDefaultForCompanyAdmin(event.currentTarget.checked)
            }
          />
          <Switch
            checked={isDefaultForAll}
            label="Default for All"
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
            onChange={(event) =>
              setIsDefaultForAll(event.currentTarget.checked)
            }
          />
          <Fieldset legend="Application Routes">
            {generateTreeViewUsingCheckbox(appRoutes)}
          </Fieldset>
          <Group justify="flex-end" mt="md">
            <Button
              onClick={(event) => {
                if (isPopup) {
                  closeAllModals();
                } else {
                  event.preventDefault();
                  navigate("/Role/List");
                }
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Update</Button>
          </Group>
        </form>
      </Box>
    </div>
  );
}
