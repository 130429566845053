import { useEffect, useState } from "react";
import commonApi from "../../api/commonApi";
import { Autocomplete } from "@mantine/core";
import autoCompleteOptionsFilter from "../common/autoCompleteOptionsFilter";

export default function AddressFormAdd(props: any) {
    const [addressCity, setAddressCity] = useState("");
    const [addressZipcode, setAddressZipcode] = useState("");
    const [addressStateName, setAddressStateName] = useState("");
    const [addressCountryName, setAddressCountryName] = useState("");
    const [cities, setCities] = useState<string[]>([]);
    const [zipcodes, setZipcodes] = useState<string[]>([]);
    const [states, setStates] = useState<string[]>([]);
    const [countries, setCountries] = useState<string[]>([]);
    const onAddressCityChange = (e: any) => {
        setAddressCity(e.currentTarget.value);
    }
    const onAddressZipcodeChange = (e: any) => {
        setAddressZipcode(e.currentTarget.value);
    }
    const onAddressStateNameChange = (e: any) => {
        setAddressStateName(e.currentTarget.value);
    }
    const onAddressCountryNameChange = (e: any) => {
        setAddressCountryName(e.currentTarget.value);
    }
    const onAddressCitySelect = (e: any) => {
        let splitted: string[] = e.currentTarget.value.split('::');
        if (splitted.length === 4) {
            props.form.setValues({
                address: {
                    address1: props.form.values.address.address1,
                    address2: props.form.values.address.address2,
                    city: splitted[0],
                    zipcode: splitted[1],
                    stateName: splitted[2],
                    countryName: splitted[3]
                }
            });
        }
    }
    const onAddressZipcodeSelect = (e: any) => {
        let splitted: string[] = e.currentTarget.value.split('::');
        if (splitted.length === 4) {
            props.form.setValues({
                address: {
                    address1: props.form.values.address.address1,
                    address2: props.form.values.address.address2,
                    city: splitted[0],
                    zipcode: splitted[1],
                    stateName: splitted[2],
                    countryName: splitted[3]
                }
            });
        }
    }
    const onAddressStateNameSelect = (e: any) => {
        let splitted: string[] = e.currentTarget.value.split('::');
        if (splitted.length === 2) {
            props.form.setValues({
                address: {
                    address1: props.form.values.address.address1,
                    address2: props.form.values.address.address2,
                    city: props.form.values.address.city,
                    zipcode: props.form.values.address.zipcode,                    
                    stateName: splitted[0],
                    countryName: splitted[1]
                }
            });
        }
    }
    const onAddressCountryNameSelect = (e: any) => {
        let splitted: string[] = e.currentTarget.value.split('::');
        if (splitted.length === 1) {
            props.form.setValues({
                address: {
                    address1: props.form.values.address.address1,
                    address2: props.form.values.address.address2,
                    city: props.form.values.address.city,
                    zipcode: props.form.values.address.zipcode,                    
                    stateName: props.form.values.address.stateName,                    
                    countryName: splitted[0]
                }
            });
        }
    }
    const getAddressCityList = async () => {
        let data = null;
        try {
            data = await commonApi.getApiCall("/address/city/search?search=" + addressCity);
        } catch (error) { }
        if (data) {
            let tempCities: string[] = [];
            for (var i = 0; i < data.length; i++) {
                tempCities.push(data[i].dropDownName);
            }
            setCities(tempCities);
        }
    };
    const getAddressZipcodeList = async () => {
        let data = null;
        try {
            data = await commonApi.getApiCall("/address/zipcode/search?search=" + addressZipcode);
        } catch (error) { }
        if (data) {
            let tempZipcodes: string[] = [];
            for (var i = 0; i < data.length; i++) {
                tempZipcodes.push(data[i].dropDownName);
            }
            setZipcodes(tempZipcodes);
        }
    };
    const getAddressStateNameList = async () => {
        let data = null;
        try {
            data = await commonApi.getApiCall("/address/state/search?search=" + addressStateName);
        } catch (error) { }
        if (data) {
            let tempStates: string[] = [];
            for (var i = 0; i < data.length; i++) {
                tempStates.push(data[i].dropDownName);
            }
            setStates(tempStates);
        }
    };
    const getAddressCountryNameList = async () => {
        let data = null;
        try {
            data = await commonApi.getApiCall("/address/country/search?search=" + addressCountryName);
        } catch (error) { }
        if (data) {
            let tempCountries: string[] = [];
            for (var i = 0; i < data.length; i++) {
                tempCountries.push(data[i].dropDownName);
            }
            setCountries(tempCountries);
        }
    };
    useEffect(() => {
        getAddressCityList();
    }, [addressCity]);
    useEffect(() => {
        getAddressZipcodeList();
    }, [addressZipcode]);
    useEffect(() => {
        getAddressStateNameList();
    }, [addressStateName]);
    useEffect(() => {
        getAddressCountryNameList();
    }, [addressCountryName]);
    return <>
        <Autocomplete
            withAsterisk
            label="Address Line 1"
            placeholder="Start typing the address"
            autoComplete="off"
            {...props.form.getInputProps("address.address1")} />
        <Autocomplete
            label="Address Line 2"
            placeholder="Optional second line of the address"
            autoComplete="off"
            {...props.form.getInputProps("address.address2")} />
        <Autocomplete
            withAsterisk
            label="City"
            placeholder="Start typing the city"
            autoComplete="off"
            data={cities}
            comboboxProps={{ shadow: 'md' }}
            filter={autoCompleteOptionsFilter.optionsFilter}
            {...props.form.getInputProps("address.city")}
            onKeyUp={(e) => {
                onAddressCityChange(e);
            }}
            onSelect={(e) => {
                onAddressCitySelect(e);
            }} />
        <Autocomplete
            withAsterisk
            label="Zip Code"
            placeholder="Start typing the zip code"
            autoComplete="off"
            data={zipcodes}
            comboboxProps={{ shadow: 'md' }}
            filter={autoCompleteOptionsFilter.optionsFilter}
            {...props.form.getInputProps("address.zipcode")}
            onKeyUp={(e) => {
                onAddressZipcodeChange(e);
            }}
            onSelect={(e) => {
                onAddressZipcodeSelect(e);
            }} />
        <Autocomplete
            withAsterisk
            label="State"
            placeholder="Start typing the state name"
            autoComplete="off"
            data={states}
            comboboxProps={{ shadow: 'md' }}
            filter={autoCompleteOptionsFilter.optionsFilter}
            {...props.form.getInputProps("address.stateName")}
            onKeyUp={(e) => {
                onAddressStateNameChange(e);
            }}
            onSelect={(e) => {
                onAddressStateNameSelect(e);
            }} />
        <Autocomplete
            withAsterisk
            label="Country"
            placeholder="Start typing the country name"
            autoComplete="off"
            data={countries}
            comboboxProps={{ shadow: 'md' }}
            filter={autoCompleteOptionsFilter.optionsFilter}
            {...props.form.getInputProps("address.countryName")}
            onKeyUp={(e) => {
                onAddressCountryNameChange(e);
            }}
            onSelect={(e) => {
                onAddressCountryNameSelect(e);
            }} />
    </>
}