import {
  TextInput,
  Checkbox,
  Button,
  Group,
  Box,
  NativeSelect,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState, useEffect } from "react";
import OptionItem from "../../../types/common/optionItem";
import commonApi from "../../../api/commonApi";
import SearchSection from "../../common/Sections/Search/SearchSection";
import ButtonGroup from "../../common/ButtonGroup";

export default function ItemSearch(props: any) {
  const form = useForm({
    initialValues: {
      search: "",
      itemTypeID: 0,
      categoryID: 0,
      disabled: false,
      valuationMethodID: 0,
      unitOfMeasureID: 0,
      trackingOptionId: 0,
      salesTaxOptionID: 0,
      purchaseTaxOptionID: 0
    },
  });
  const [valuationMethods, setValuationMethods] = useState<OptionItem[]>([]);
  const getValuationMethodList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/inventory/valuationmethods");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
      data2.splice(0, 0, {label: "-- Select --", value: "0"});
      setValuationMethods(data2);               
    }
  };  
  const [itemTypes, setItemTypes] = useState<OptionItem[]>([]);
  const getItemTypeList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/inventory/itemtypes");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
      data2.splice(0, 0, {label: "-- Select --", value: "0"});
      setItemTypes(data2);
      //set default value for itemTypeID field on form
      form.setFieldValue("itemTypeID", Number(data2[0].value)); 
    }
  }; 
  const [categories, setCategories] = useState<OptionItem[]>([]);
  const getCategoryList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/inventory/categories");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
      data2.splice(0, 0, {label: "-- Select --", value: "0"});
      setCategories(data2);               
    }
  };
  const [taxOptions, setTaxOptions] = useState<OptionItem[]>([]);
  const getTaxOptionList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/inventory/taxoptions");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
      data2.splice(0, 0, {label: "-- Select --", value: "0"});
      setTaxOptions(data2);               
    }
  };
  const [unitOfMeasures, setUnitofMeasures] = useState<OptionItem[]>([]);
  const getUnitOfMeasureList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/inventory/unitofmeasures");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
      data2.splice(0, 0, {label: "-- Select --", value: "0"});
      setUnitofMeasures(data2);
    }
  };
  const [trackingOptions, setTrackingOptions] = useState<OptionItem[]>([]);
  const getTrackingOptionList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/inventory/trackingoptions");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map((s: { dropDownName: string; dropDownValue: string; }) => ({label: s.dropDownName, value: s.dropDownValue}));
      data2.splice(0, 0, {label: "-- Select --", value: "0"});
      setTrackingOptions(data2);               
    }
  };

  useEffect(() => {
    getValuationMethodList();
    getItemTypeList();
    getCategoryList();
    getTaxOptionList();
    getUnitOfMeasureList();
    getTrackingOptionList();
  }, []);
  return (
    <form onSubmit={form.onSubmit((values) => props.setParamsFunction(values))}>
      <SearchSection
        buttonSection={
          <ButtonGroup
            cancelLabel="Reset"
            cancelClick={form.reset}
            okLabel="Search"
          />
        }
      >
        <TextInput
          label="Search"
          placeholder="general text search"
          {...form.getInputProps("search")}
        />
        <NativeSelect
          name="itemTypeID"
          label="Item Type"
          data={itemTypes}
          {...form.getInputProps("itemTypeID")}
        />
        <NativeSelect
          name="categoryID"
          label="Category"
          data={categories}
          {...form.getInputProps("categoryID")}
        />
        <NativeSelect
          name="valuationMethodID"
          label="Valuation Method"
          data={valuationMethods}
          {...form.getInputProps("valuationMethodID")}
        />
        <NativeSelect
          name="unitOfMeasureID"
          label="Unit of Measure"
          data={unitOfMeasures}
          {...form.getInputProps("unitOfMeasureID")}
        />
        <NativeSelect
          name="trackingOptionID"
          label="Tracking Option"
          data={trackingOptions}
          {...form.getInputProps("trackingOptionID")}
        />
        <NativeSelect
          name="salesTaxOptionID"
          label="Sales Tax Option"
          description="The tax option to use for sales transactions"
          data={taxOptions}
          {...form.getInputProps("salesTaxOptionID")}
        />
        <NativeSelect
          name="purchaseTaxOptionID"
          label="Purchase Tax Option"
          description="The tax option to use for purchase transactions"
          data={taxOptions}
          {...form.getInputProps("purchaseTaxOptionID")}
        />
        <Checkbox label="Disabled" {...form.getInputProps("disabled")} />
      </SearchSection>
    </form>
  );
}
