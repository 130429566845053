import { useForm } from "@mantine/form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import alerts from "../../common/alerts";
import OptionItem from "../../../types/common/optionItem";
import { Box, Button, Group, NativeSelect, TextInput } from "@mantine/core";

export default function BankAccountFormEdit({
  id,
  setState,
}: {
  id?: string;
  setState?: Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const form = useForm();
  const { bankAccountId } = useParams();
  let isPopup: boolean = true;
  if (id == null) {
    isPopup = false;
    id = bankAccountId;
  }
  const getBankAccountById = async () => {
    try {
      let data = await commonApi.getApiCall("/bank/accounts/" + id);
      form.setValues(data);
    } catch (error) {}
  };

  const [currencyOptions, setCurrencyOptions] = useState<OptionItem[]>([]);
  const getCurrencyOption = async () => {
    let data = null;
    try {
      commonApi.setPageNumber(1);
      commonApi.setPageSize(100);
      data = await commonApi.getApiCall("/currencies");
    } catch (error) {}

    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setCurrencyOptions(data2);
    }
  };

  const [bankOptions, setBankOptions] = useState<OptionItem[]>([]);
  const getBankOptions = async () => {
    let data = null;
    try {
      commonApi.setPageNumber(1);
      commonApi.setPageSize(100);
      data = await commonApi.getApiCall("/banks");
    } catch (error) {}

    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setBankOptions(data2);
    }
  };

  const [accountOptions, setAccountOptions] = useState<OptionItem[]>([]);
  const getAccountOptions = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/accounts");
    } catch (error) {}

    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setAccountOptions(data2);
    }
  };

  useEffect(() => {
    getBankAccountById();
    getCurrencyOption();
    getBankOptions();
    getAccountOptions();
  }, [id]);

  const onSubmitHandler = async (values: any) => {
    try {
      await commonApi.putApiCall("/banks/accounts/" + id, values).then(() => {
        alerts.showMessage("Bank account updated");
        if (isPopup) {
          if (setState) {
            setState(true);
          }
        }
        getBankAccountById();
      });
    } catch (error) {
      alerts.showError(error);
    }
  };
  return (
    <>
      <form
        onSubmit={form.onSubmit((values) => {
          onSubmitHandler(values);
        })}
      >
        <Box className="fade-in-block width">
          <NativeSelect
            label="Bank"
            withAsterisk
            data={bankOptions}
            {...form.getInputProps("bankID")}
          />
          <NativeSelect
            label="Account"
            withAsterisk
            data={accountOptions}
            {...form.getInputProps("accountID")}
          />
          <NativeSelect
            label="Currency"
            withAsterisk
            data={currencyOptions}
            {...form.getInputProps("currencyID")}
          />
          <TextInput
            withAsterisk
            label="Bank Account Name"
            placeholder="Enter Bank Account Name"
            {...form.getInputProps("bankAccountName")}
          />
          <TextInput
            withAsterisk
            label="Bank Account Number"
            placeholder="Enter Bank Account Number"
            {...form.getInputProps("bankAccountNumber")}
          />
          <TextInput
            withAsterisk
            label="Bank Account BSB"
            placeholder="Enter Bank Account BSB"
            {...form.getInputProps("bankAccountBsb")}
          />
          <Group justify="flex-end" mt="md">
            <Button
              onClick={(event) => {
                navigate("/BankAccount/List");
              }}
            >
              Back
            </Button>
            <Button type="submit">Update</Button>
          </Group>
        </Box>
      </form>
    </>
  );
}
