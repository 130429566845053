import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import commonApi from "../../api/commonApi";
import { useForm } from "@mantine/form";
import {
  Box,
  TextInput,
  NativeSelect,
  Button,
  Group,
  Textarea,
} from "@mantine/core";
import OptionItem from "../../types/common/optionItem";
import alerts from "../common/alerts";
import { DateInput } from "@mantine/dates";
import extensionMethods from "../../common/extensionMethods";
import JournalReversingDate from "./journalReversingDate";

export default function JournalFormAdd() {
  const navigate = useNavigate();
  const form = useForm({
    initialValues: {
      detail: "",
      exchangeRateID: 0,
      journalDate: new Date(),
      journalTypeID: 0,
      transactionTypeID: 1,
      reversingDate: new Date(),
    },
  });
  const [exchangeRates, setExchangeRates] = useState<OptionItem[]>([]);
  const getExchangeRateList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/exchangerates");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { detail: string; exchangeRateID: string; rateValue: number }) => ({
          label:
            extensionMethods.decimalDisplay(s.rateValue, false) +
            " " +
            s.detail,
          value: s.exchangeRateID,
        })
      );
      setExchangeRates(data2);
    }
  };
  const [journalTypes, setJournalTypes] = useState<OptionItem[]>([]);
  const getJournalTypeList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/journal/types");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { name: string; journalTypeID: string }) => ({
          label: s.name,
          value: s.journalTypeID,
        })
      );
      setJournalTypes(data2);
    }
  };
  const [transactionTypeID, setTransactionTypeID] = useState(0);
  const [transactionTypes, setTransactionTypes] = useState<OptionItem[]>([]);
  const getTranactionTypeList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/journal/system/transactiontypes");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { name: string; systemTransactionTypeID: string }) => ({
          label: s.name,
          value: s.systemTransactionTypeID,
        })
      );
      setTransactionTypes(data2);
    }
  };
  const getNextPostingYear = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/journal/next");
    } catch (error) {}
    if (data) {
      setTransactionTypeID(data.transactionTypeID);
      form.setValues({
        detail: data.detail,
        journalDate: new Date(data.journalDate),
        exchangeRateID: data.exchangeRateID,
        journalTypeID: data.journalTypeID,
        transactionTypeID: data.transactionTypeID,
        reversingDate: new Date(data.reversingDate),
      });
    }
  };
  const onTransactionTypeChange = (e: any) => {
    setTransactionTypeID(e.currentTarget.value);
  };
  useEffect(() => {
    getNextPostingYear();
    getExchangeRateList();
    getJournalTypeList();
    getTranactionTypeList();
  }, []);
  const onSubmitHandler = async (values: any) => {
    try {
      values.journalDate = extensionMethods.dateMinusTimezoneOffset(
        values.journalDate
      );
      values.reversingDate = extensionMethods.dateMinusTimezoneOffset(
        values.reversingDate
      );
      var data = await commonApi.postApiCall("/journals", values);
      alerts.showMessage("Journal added");
      navigate("/Journal/Edit/" + data.journalID);
    } catch (error) {
      alerts.showError(error);
    }
  };
  return (
    <>
      <Box className="fade-in-block width33">
        <form
          onSubmit={form.onSubmit((values) => {
            onSubmitHandler(values);
          })}
        >
          <TextInput
            withAsterisk
            label="Description"
            placeholder="Detail of new journal"
            {...form.getInputProps("detail")}
          />
          <DateInput
            withAsterisk
            label="Date"
            placeholder="Date of the journal"
            {...form.getInputProps("journalDate")}
          />
          <NativeSelect
            withAsterisk
            label="Journal Type"
            data={journalTypes}
            {...form.getInputProps("journalTypeID")}
          />
          <NativeSelect
            withAsterisk
            label="Transaction Type"
            data={transactionTypes}
            {...form.getInputProps("transactionTypeID")}
            onChange={(e) => {
              onTransactionTypeChange(e);
              form.getInputProps("transactionTypeID").onChange(e);
            }}
          />
          <JournalReversingDate
            transactionTypeID={transactionTypeID}
            form={form}
          />
          <NativeSelect
            withAsterisk
            label="Exchange Rate"
            data={exchangeRates}
            {...form.getInputProps("exchangeRateID")}
          />
          <Group justify="flex-end" mt="md">
            <Button
              onClick={(event) => {
                navigate("/Journal/List");
              }}
            >
              Back
            </Button>
            <Button type="submit">Add</Button>
          </Group>
        </form>
      </Box>
    </>
  );
}
