import { useForm } from "@mantine/form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import commonApi from "../../api/commonApi";
import {
  Box,
  Button,
  Checkbox,
  Group,
  NativeSelect,
  Stepper,
  TextInput,
} from "@mantine/core";
import OptionItem from "../../types/common/optionItem";
import alerts from "../common/alerts";
import CreditorAddressManageUpdate from "./creditorAddressManageUpdate";
import Creditor from "../../types/creditor/creditor";

function CreditorEditForm({
  id,
  setState,
}: {
  id?: string;
  setState?: Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const form = useForm();
  const { creditorId } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [currencyOptions, setCurrencyOptions] = useState<OptionItem[]>([]);
  const [creditorIdToUpdate, setCreditorIdToUpdate] = useState<string>("0");

  let isPopup: boolean = true;

  if (id == null) {
    isPopup = false;
    id = creditorId;
  }

  const getCreditorById = async () => {
    try {
      let data = await commonApi.getApiCall("/creditors/" + id);
      form.setValues(data);
    } catch (error) {}
  };

  const getCurrencyOptions = async () => {
    let data = null;
    try {
      commonApi.setPageNumber(1);
      commonApi.setPageSize(100);
      data = await commonApi.getApiCall("/currencies");
    } catch (error) {}

    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setCurrencyOptions(data2);
    }
  };

  useEffect(() => {
    if (id) setCreditorIdToUpdate(id);
    getCreditorById();
    getCurrencyOptions();
  }, [id]);

  const onSubmitHandler = async (values: any) => {
    try {
      //var data = await commonApi.putApiCall("/creditors/" + id, values);
      alerts.showMessage("Creditor updated");
    } catch (error) {
      alerts.showError(error);
    }
  };

  const updateCreditor = async (values: any) => {
    try {
      await commonApi
        .putApiCall("/creditors/" + creditorIdToUpdate, values)
        .then((response) => {
          setCurrentStep(currentStep + 1);
          alerts.showMessage("Creditor updated");
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  const handleNextStep = () => {
    if (currentStep < 2) {
      if (currentStep === 0) {
        let creditorToUpdate: Creditor = {
          creditorID: creditorIdToUpdate,
          name: form.values.name ? form.values.name.toString() : "",
          phone: form.values.phone ? form.values.phone.toString() : "",
          email: form.values.email ? form.values.email.toString() : "",
          website: form.values.website ? form.values.website.toString() : "",
          currencyID: form.values.currencyID
            ? parseInt(form.values.currencyID.toString())
            : 0,
        };
        updateCreditor(creditorToUpdate);
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <>
      <Box className="fade-in-block width100">
        <Stepper
          active={currentStep}
          onStepClick={setCurrentStep}
          orientation="horizontal"
        >
          <Stepper.Step label="Basic Information">
            <Box>
              <form
                onSubmit={form.onSubmit((values) => {
                  onSubmitHandler(values);
                })}
              >
                <Box className="fade-in-block width33">
                  <TextInput
                    withAsterisk
                    label="Name"
                    placeholder="Enter a creditor name"
                    {...form.getInputProps("name")}
                  />
                  <TextInput
                    withAsterisk
                    label="Phone"
                    placeholder="Enter a phone"
                    {...form.getInputProps("phone")}
                  />
                  <TextInput
                    withAsterisk
                    label="Email"
                    placeholder="Enter a email"
                    {...form.getInputProps("email")}
                  />
                  <TextInput
                    withAsterisk
                    label="Website"
                    placeholder="Enter a email"
                    {...form.getInputProps("website")}
                  />
                  <NativeSelect
                    label="Currency"
                    withAsterisk
                    data={currencyOptions}
                    {...form.getInputProps("currencyID")}
                  />
                </Box>
              </form>
            </Box>
          </Stepper.Step>
          <Stepper.Step label="Address Data">
            <CreditorAddressManageUpdate creditorId={creditorIdToUpdate} />
          </Stepper.Step>
        </Stepper>
        <Group justify="flex-end" mt="md">
          <Button onClick={handlePrevStep} disabled={currentStep === 0}>
            Back
          </Button>
          {currentStep < 1 ? (
            <Button onClick={handleNextStep} disabled={currentStep === 1}>
              Next
            </Button>
          ) : (
            <form onSubmit={form.onSubmit(onSubmitHandler)}>
              <Button type="submit">Update Creditor</Button>
            </form>
          )}

          <Button onClick={() => navigate("/Finance/Creditor/List")}>
            Cancel
          </Button>
        </Group>
      </Box>
    </>
  );
}

export default CreditorEditForm;
