import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import { useForm } from "@mantine/form";
import { Box, TextInput, Button, Group } from "@mantine/core";
import alerts from "../../common/alerts";
import AddressFormAdd from "../../address/addressFormAdd";

export default function BankFormAdd() {
  const navigate = useNavigate();
  const form = useForm();
  const getBankForCreation = async () => {
    try {
      let data = await commonApi.getApiCall("/banks/new");
      form.setValues(data);
    } catch (error) {}
  };
  useEffect(() => {
    getBankForCreation();
  }, []);
  const onSubmitHandler = async (values: any) => {
    try {
      var data = await commonApi.postApiCall("/banks", values);
      alerts.showMessage("Bank added");
      navigate("/Bank/Edit/" + data.bankID);
    } catch (error) {
      alerts.showError(error);
    }
  };
  return (
    <>
      <form
        onSubmit={form.onSubmit((values) => {
          onSubmitHandler(values);
        })}
      >
        <Box className="fade-in-block width50">
          <TextInput
            withAsterisk
            label="Bank Name"
            placeholder="Enter the bank name"
            {...form.getInputProps("name")}
          />
          <TextInput
            withAsterisk
            label="Contact Name"
            placeholder="Enter the name of the bank contact"
            {...form.getInputProps("contactName")}
          />
          <TextInput
            withAsterisk
            label="Email Address"
            placeholder="Enter the email address to contact this bank"
            {...form.getInputProps("emailAddress")}
          />
          <TextInput
            withAsterisk
            label="Phone Number"
            placeholder="Enter the phone number to contact this bank"
            {...form.getInputProps("phoneNumber")}
          />
        </Box>
        <Box className="fade-in-block width50">
          <AddressFormAdd form={form} />
          <Group justify="flex-end" mt="md">
            <Button
              onClick={(event) => {
                navigate("/Bank/List");
              }}
            >
              Back
            </Button>
            <Button type="submit">Add</Button>
          </Group>
        </Box>
      </form>
    </>
  );
}
