import Role from "../../types/user/role";
import { useListState } from "@mantine/hooks";
import { Checkbox, Fieldset } from "@mantine/core";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import CompanyUser from "../../types/company/companyUser";
import CompanyRoleUser from "../../types/company/companyRoleUser";

export default function CompanyGroupUserRoleList({
  compUserList,
  compUser,
  roleData,
  setState,
}: {
  compUserList: CompanyUser[];
  compUser: CompanyUser;
  roleData: Role[];
  setState?: Dispatch<SetStateAction<CompanyUser[]>>;
}) {
  const [roleValues, handlers] = useListState<Role>([]);
  const [checkboxItems, setCheckboxItems] = useState<JSX.Element[]>([]);
  const [assignedRoles, setAssignedRoles] = useState<CompanyRoleUser[]>([]);
  const [removedRoleIds, setRemovedRoleIds] = useState<number[]>([]);

  const setRoleData = () => {
    //initial assignment of assignedRoles values
    handlers.setState(roleData);
    compUser.roleUser?.map((item: any) => {
      const roleItem: CompanyRoleUser = {
        roleID: item.roleID,
      };

      let roleIndex = assignedRoles.findIndex((i) => i.roleID === item.roleID);
      if (roleIndex < 0) assignedRoles.push(roleItem);
      setAssignedRoles(assignedRoles);
    });
  };

  const generateCheckboxes = () => {
    let checkboxes = roleValues.map((value, index) => {
      value.checked = false;
      const currentUserRole = assignedRoles.find(
        (item) => item.roleID === value.roleID
      );
      const isRemoved = removedRoleIds.find((x) => x === value.roleID);
      if (currentUserRole && !isRemoved) value.checked = true;
      return (
        <Checkbox
          mt="xs"
          ml={33}
          label={value.name}
          key={value.roleID}
          checked={value.checked}
          onChange={(event) => {
            handlers.setItemProp(index, "checked", event.currentTarget.checked);
            //get index if it exists on array
            let roleIndex = assignedRoles.findIndex(
              (i) => i.roleID === value.roleID
            );

            if (event.currentTarget.checked) {
              const role: CompanyRoleUser = {
                roleID: value.roleID ? value.roleID : 0,
              };

              if (roleIndex < 0) {
                assignedRoles.push(role);
                setAssignedRoles(assignedRoles);
              }
            } else {
              //remove from array
              const newSelectedRole = assignedRoles.filter(
                (x) => x.roleID != value.roleID
              );

              //assign to the state
              setAssignedRoles(newSelectedRole);
            }
          }}
        />
      );
    });

    //updating companyuser roles
    let roles: CompanyRoleUser[] = [];
    assignedRoles.map((item) => {
      let role: CompanyRoleUser = {
        roleID: item.roleID,
      };
      roles.push(role);
    });

    compUser.roleUser = roles;

    let toEditCompUser = compUserList.find(
      (item) => item.companyUserID === compUser.companyUserID
    );

    if (toEditCompUser) {
      if (roles.length > 0) {
        toEditCompUser.roleUser = roles;
        if (setState) setState(compUserList);
      }
    }
    setCheckboxItems(checkboxes);
  };

  useEffect(() => {
    setRoleData();
  }, []);

  useEffect(() => {
    generateCheckboxes();
  }, [roleValues]);

  return <Fieldset legend={compUser.companyName}>{checkboxItems}</Fieldset>;
}
