import { Button, Group, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import ButtonGroup from "../../common/ButtonGroup";
import SearchSection from "../../common/Sections/Search/SearchSection";

export default function CurrencySearch(props: any) {
  const form = useForm({
    initialValues: {
      name: "",
      symbol: "",
      isoCode: "",
      fractionalUnit: "",
    },
  });
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        props.setParamsFunction(values);
      })}
    >
      <SearchSection
        buttonSection={
          <ButtonGroup
            cancelLabel="Reset"
            cancelClick={form.reset}
            okLabel="Search"
          />
        }
      >
        <TextInput
          label="Name"
          placeholder="name"
          {...form.getInputProps("name")}
        />
        <TextInput
          label="Symbol"
          placeholder="symbol"
          {...form.getInputProps("symbol")}
        />
        <TextInput
          label="ISO Code"
          placeholder="ISO code"
          {...form.getInputProps("isoCode")}
        />
        <TextInput
          label="Fractional Unit"
          placeholder="fractional unit"
          {...form.getInputProps("fractionalUnit")}
        />
      </SearchSection>
    </form>
  );
}
