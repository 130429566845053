import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import commonApi from "../../api/commonApi";
import PaginationSection from "../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../common/Sections/Pagination/PaginationSummary";
import CompanyGroupUserSearch from "./companyGroupUserSearch";
import { ActionIcon, Box, Button, Group, Text } from "@mantine/core";
import { IconEdit, IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import UserView from "../../types/company/UserView";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { modals, openModal } from "@mantine/modals";
import CompanyGroupUserFormEdit from "./companyGroupUserFormEdit";
import companyApi from "../../api/company/companyApi";
import companyUser from "../../types/company/companyUser";
import alerts from "../common/alerts";

export default function CompanyGroupUserList() {
  const navigate = useNavigate();
  const [userViews, setUserViews] = useState<UserView[]>([]);
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [params, setParams] = useState({});
  const [totalRecords, setTotalRecords] = useState(1);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<UserView>>({
    columnAccessor: "",
    direction: "asc",
  });
  const [selectedRecords, setSelectedRecords] = useState<UserView[]>([]);
  const getCompanyGroupUserList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/companygroups/userviews?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      setTotalRecords(data.length);
      setUserViews(data);
    }
  };

  const openDeleteModal = (user?: UserView, selectedUsers?: UserView[]) => {
    modals.openConfirmModal({
      title: "Delete user",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this user/s.</Text>
      ),
      labels: { confirm: "Delete user", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: async () => {
        if (user) {
          if (user.userID) {
            try {
              await commonApi
                .deleteApiCall("/companygroup/user/" + user.userID)
                .then((response) => {
                  alerts.showMessage("Company user delete success");
                  getCompanyGroupUserList();
                });
            } catch (error) {
              alerts.showError(error);
            }
          }
        }

        if (selectedUsers) {
          let selectedCount = selectedUsers.length;
          let counter = 0;
          selectedUsers.forEach(async (user) => {
            if (user.userID) {
              try {
                await commonApi
                  .deleteApiCall("/companygroup/user/" + user.userID)
                  .then((response) => {
                    counter++;
                  });
              } catch (error) {
                alerts.showError(error);
              }

              if (counter === selectedCount) {
                alerts.showMessage("Company user delete success");
                setSelectedRecords([]);
                getCompanyGroupUserList();
              }
            }
          });
        }
      },
    });
  };

  const showModal = ({
    user,
    action,
    setState,
  }: {
    user: UserView;
    action: "edit" | "delete";
    setState?: Dispatch<SetStateAction<boolean>>;
  }) => {
    let buttonText = "Close";
    switch (action) {
      case "edit":
        buttonText = "Update";
        break;
      case "delete":
        buttonText = "Delete";
        break;
    }
    openModal({
      modalId: action,
      title: action === "edit" ? "Edit user information" : "Deleting company",
      children: (
        <BrowserRouter>
          {action === "edit" ? (
            <CompanyGroupUserFormEdit
              userId={user.userID}
              setState={setState}
            />
          ) : (
            "Additional company information"
          )}
        </BrowserRouter>
      ),
    });
  };

  useEffect(() => {
    getCompanyGroupUserList();
  }, [sortStatus, page, pageSize, params, isSuccessUpdate]);

  return (
    <div>
      <CompanyGroupUserSearch setParamsFunction={setParams} />
      <Group mt="md" justify="flex-end">
        <Button
          onClick={() => {
            navigate("/CompanyGroup/User/Add");
          }}
        >
          Add
        </Button>
        <Button
          onClick={() => {
            if (selectedRecords.length > 0) {
              openDeleteModal(undefined, selectedRecords);
            } else {
              alerts.showClientError({
                message: "No selected record to delete",
              });
            }
          }}
        >
          Delete
        </Button>
      </Group>
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        pinLastColumn
        records={userViews}
        idAccessor="userID"
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        isRecordSelectable={(record) => record.canBeDeleted === true}
        columns={[
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "email",
            title: "Email",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "phone",
            title: "Phone",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "defaultCompanyName",
            title: "Default Company",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "companyCount",
            title: "Companies",
            textAlign: "center",
            sortable: true,
            width: "5%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "status",
            title: "Status",
            textAlign: "center",
            sortable: true,
            width: "5%",
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (user) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showModal({
                      user,
                      action: "edit",
                      setState: setIsSuccessUpdate,
                    });
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  disabled={!user.canBeDeleted}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    openDeleteModal(user);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        selectedRecords={selectedRecords}
        onSelectedRecordsChange={setSelectedRecords}
        onRowClick={({ record, index, event }) => {
          navigate("/CompanyGroup/User/Edit/" + record.userID);
        }}
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
