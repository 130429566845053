import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  BrowserRouter,
} from "react-router-dom";
import Schedule from "../../types/tax/schedule";
import { useForm } from "@mantine/form";
import {
  ActionIcon,
  Box,
  Button,
  Fieldset,
  Group,
  Select,
  TextInput,
  Text,
  NumberInput,
} from "@mantine/core";
import commonApi from "../../api/commonApi";
import Company from "../../types/company/company";
import alerts from "../common/alerts";
import { closeAllModals, openModal, modals, closeModal } from "@mantine/modals";
import { IconMoodConfuzed, IconPlus, IconTrash } from "@tabler/icons-react";
import { DataTable } from "mantine-datatable";
import RateDetailListPopup from "./rateDetailListPopup";
import ScheduleRateDetail from "../../types/tax/scheduleRateDetail";

type SelectType = {
  value: string;
  label: string;
};

export default function ScheduleEdit({
  scheduleID,
  setState,
  close,
}: {
  scheduleID?: number;
  setState?: Dispatch<SetStateAction<boolean>>;
  close?: any;
}) {
  const navigate = useNavigate();
  const [schedule, setSchedule] = useState<Schedule>({} as Schedule);
  const { scheduleId } = useParams();
  const [isPopup, setIsPopup] = useState(false);
  const [companySelectData, setCompanySelectData] = useState<SelectType[]>([]);
  const [companyId, setCompanyId] = useState("");
  const [assignedRates, setAssignedRates] = useState<ScheduleRateDetail[]>([]);
  const [selectedRecords, setSelectedRecords] = useState<ScheduleRateDetail[]>(
    []
  );
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);

  const form = useForm({
    initialValues: {
      scheduleID: 0,
      companyID: 0,
      name: "",
      code: "",
      totalAggregateTax: 0,
    },
    validate: {
      name: (value) =>
        value.length < 1 ? "Name must have at least 1 characters" : null,
      code: (value) =>
        value.length < 1 ? "Code must have at least 1 characters" : null,
      companyID: (value) =>
        value === 0 || value === null ? "Please select a company" : null,
    },
  });

  const showRateDetailsModal = () => {
    openModal({
      modalId: "editratedetailpopup",
      title: "Rate Detail List",
      closeOnClickOutside: false,
      keepMounted: true,
      size: "50vw",
      children: (
        <BrowserRouter>
          <RateDetailListPopup
            scheduleID={schedule.scheduleID}
            assignedRates={assignedRates}
            setState={setAssignedRates}
            isSuccessUpdate={setIsSuccessUpdate}
            isPopup={isPopup}
          />
        </BrowserRouter>
      ),
    });
  };

  const onSubmitHandler = async (values: any) => {
    let sched: Schedule = {
      name: values.name,
      code: values.code,
      companyID: parseInt(companyId),
      rateDetails: assignedRates,
      scheduleID: schedule.scheduleID,
    };

    try {
      await commonApi
        .putApiCall("/schedules/" + sched.scheduleID, sched)
        .then((resp) => {
          if (isPopup) {
            if (setState) {
              setState(true);
            }
            closeAllModals();
          } else {
            navigate("/Tax/Schedule/List");
          }
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  let compList: SelectType[] = [];
  const getCompanyList = async () => {
    let data = null;
    const companyGroupId = 0;
    try {
      data = await commonApi.getApiCall("/companiesbygroup/" + companyGroupId);
    } catch (error) {}

    if (data) {
      data.map((company: Company) => {
        let d: SelectType = {
          label: company.name,
          value: company.companyID.toString(),
        };
        compList.push(d);
      });
      setCompanySelectData(compList);
    }
  };

  const getScheduleById = async () => {
    let data = null;
    if (!scheduleID) scheduleID = schedule.scheduleID;
    try {
      await commonApi
        .getApiCall("/schedules/" + scheduleID)
        .then((response) => {
          if (response) {
            data = response;
            setSchedule(response);
            if (data) {
              form.setValues({
                scheduleID: data.scheduleID,
                code: data.code,
                name: data.name,
                companyID: data.companyID,
                totalAggregateTax: data.totalAggregateTax,
              });
              setCompanyId(data.companyID.toString());
              setAssignedRates(data.rateDetails);
            }
          }
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  const handleRemoveItem = async (rateDetail: any) => {
    var ratesArray = assignedRates;
    let item = ratesArray.filter(
      (x) => x.rateDetailID == rateDetail.rateDetailID
    )[0];
    let index = ratesArray.indexOf(item);
    ratesArray.splice(index, 1);

    let array: ScheduleRateDetail[] = [];
    ratesArray.forEach((x) => {
      let rate: ScheduleRateDetail = {
        rateDetailID: x.rateDetailID,
        ratePercentage: x.ratePercentage,
        scheduleID: x.scheduleID,
        name: x.name,
        canBeDeleted: x.canBeDeleted,
      };
      array.push(rate);
    });
    setAssignedRates(array);
  };

  const openDeleteModal = (
    schedRateDetail?: ScheduleRateDetail,
    schedRateDetailList?: ScheduleRateDetail[]
  ) => {
    modals.openConfirmModal({
      title: "Delete rate detail",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this rate detail/s.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        if (schedRateDetail) {
          if (schedRateDetail.scheduleRateDetailID) {
            try {
              await commonApi
                .deleteApiCall(
                  "/scheduleratedetails/" + schedRateDetail.scheduleRateDetailID
                )
                .then((response) => {
                  alerts.showMessage("Delete success");
                  getScheduleById();
                });
            } catch (error) {
              alerts.showError(error);
            }
          } else {
            handleRemoveItem(schedRateDetail);
          }
        }

        if (schedRateDetailList) {
          let selectedCount = schedRateDetailList.length;
          let counter = 0;
          schedRateDetailList.forEach(async (schedRateDetail) => {
            if (schedRateDetail.scheduleRateDetailID) {
              try {
                await commonApi
                  .deleteApiCall(
                    "/scheduleratedetails/" +
                      schedRateDetail.scheduleRateDetailID
                  )
                  .then((response) => {
                    counter++;
                  });
              } catch (error) {
                alerts.showError(error);
              }

              if (counter === selectedCount) {
                alerts.showMessage("Delete success");
                setSelectedRecords([]);
                getScheduleById();
              }
            } else {
              handleRemoveItem(schedRateDetail);
            }
          });
        }
      },
    });
  };

  useEffect(() => {
    setIsSuccessUpdate(false);
    if (scheduleId) {
      scheduleID = parseInt(scheduleId);
      setIsPopup(false);
    } else {
      setIsPopup(true);
    }
    getCompanyList();
    getScheduleById();
  }, [isSuccessUpdate]);

  return (
    <Box className={!isPopup ? "fade-in-block width33" : ""}>
      <form
        onSubmit={form.onSubmit((values) => {
          onSubmitHandler(values);
        })}
      >
        <Select
          label="Company"
          withAsterisk
          placeholder="Company"
          data={companySelectData}
          allowDeselect={true}
          value={companyId}
          clearable
          searchable
          nothingFoundMessage="Nothing found..."
          onChange={(value) => {
            value !== null ? setCompanyId(value) : setCompanyId("");
          }}
        />
        <TextInput
          withAsterisk
          label="Name"
          placeholder="name of schedule"
          {...form.getInputProps("name")}
        />
        <TextInput
          withAsterisk
          label="Code"
          placeholder="code"
          {...form.getInputProps("code")}
        />
        <TextInput
          label="Total Tax"
          disabled={true}
          style={{ paddingBottom: "25px" }}
          {...form.getInputProps("totalAggregateTax")}
        />

        <Fieldset legend="Rate Details" style={{ paddingTop: "0px" }}>
          <Group justify="flex-end" mt="md">
            <ActionIcon
              size="sm"
              variant="subtle"
              color="blue"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                showRateDetailsModal();
              }}
            >
              <IconPlus size={16} />
            </ActionIcon>
            <ActionIcon
              size="sm"
              variant="subtle"
              color="blue"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                if (selectedRecords.length > 0)
                  openDeleteModal(undefined, selectedRecords);
                else {
                  alerts.showClientError({
                    message: "No selected record to delete",
                  });
                }
              }}
            >
              <IconTrash size={16} />
            </ActionIcon>
          </Group>
          <DataTable
            withTableBorder
            borderRadius="sm"
            withColumnBorders
            striped
            highlightOnHover
            textSelectionDisabled
            pinLastColumn
            idAccessor="rateDetailID"
            records={assignedRates}
            columns={[
              {
                accessor: "name",
                title: "Name",
                textAlign: "left",
                sortable: true,
              },
              {
                accessor: "ratePercentage",
                title: "Rate Percentage",
                textAlign: "left",
                sortable: true,
              },
              {
                accessor: "actions",
                title: "Actions",
                textAlign: "right",
                width: "1%",
                render: (rateDetail) => (
                  <Group gap={10} justify="space-around" wrap="nowrap">
                    <ActionIcon
                      size="sm"
                      variant="subtle"
                      color="red"
                      disabled={!rateDetail.canBeDeleted}
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        openDeleteModal(rateDetail);
                      }}
                    >
                      <IconTrash size={16} />
                    </ActionIcon>
                  </Group>
                ),
              },
            ]}
            selectedRecords={selectedRecords}
            onSelectedRecordsChange={setSelectedRecords}
            minHeight={150}
            noRecordsIcon={
              <Box p={4} mb={4}>
                <IconMoodConfuzed size={36} strokeWidth={1.5} />
              </Box>
            }
            noRecordsText="No records found"
          />
        </Fieldset>
        <Group justify="flex-end" mt="md">
          <Button
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              if (isPopup) {
                //if (close) close();
                if (setState) setState(true);
                closeAllModals();
              } else {
                navigate("/Tax/Schedule/List");
              }
              //setAssignedRates([]);
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            Update
          </Button>
        </Group>
      </form>
    </Box>
  );
}
