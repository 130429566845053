import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import commonApi from "../../../api/commonApi";
import Item from "../../../types/inventory/item";
import PaginationSection from "../../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../../common/Sections/Pagination/PaginationSummary";
import ItemSearch from "./itemSearch";
import { ActionIcon, Box, Group, Text } from "@mantine/core";
import { IconEdit, IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { modals, openModal } from "@mantine/modals";
import ItemFormEdit from "./itemFormEdit";
import extensionMethods from "../../../common/extensionMethods";
import alerts from "../../common/alerts";

export default function ItemList() {
  const navigate = useNavigate();
  const [items, setItems] = useState<Item[]>([]);
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [params, setParams] = useState({});
  const [totalRecords, setTotalRecords] = useState(1);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Item>>({
    columnAccessor: "",
    direction: "asc",
  });
  const getItemList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/inventory/items?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      setTotalRecords(data.length);
      setItems(data);
    }
  };

  useEffect(() => {
    getItemList();
  }, [sortStatus, page, pageSize, params]);

  function showEditModal(
    itemID: number,
    setState?: Dispatch<SetStateAction<boolean>>
  ) {
    openModal({
      modalId: "edit",
      title: "Showing item information",
      size: "100vw",
      children: (
        <BrowserRouter>
          <ItemFormEdit id={itemID.toString()} setState={setState} />
        </BrowserRouter>
      ),
    });
  }

  function showDeleteModal(ItemID: number) {
    modals.openConfirmModal({
      title: "Delete item",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this item/s.</Text>
      ),
      labels: { confirm: "Delete item", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        try {
          await commonApi
            .deleteApiCall("/inventory/items/" + ItemID)
            .then(() => {
              alerts.showMessage("Item deleted");
              getItemList();
            });
        } catch (error) {
          alerts.showError(error);
        }
      },
    });
  }

  return (
    <div className="fade-in-block width100">
      <ItemSearch setParamsFunction={setParams} />
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        records={items}
        idAccessor="itemID"
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        columns={[
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
            render: (item) => item.name,
            width: "10%",
          },
          {
            accessor: "description",
            title: "Description",
            textAlign: "left",
            sortable: true,
            render: (item) => item.description,
            width: "20%",
          },
          {
            accessor: "itemTypeName",
            title: "Item Type",
            textAlign: "left",
            sortable: true,
            render: (item) => item.itemTypeName,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            width: "5%",
          },
          {
            accessor: "categoryName",
            title: "Category",
            textAlign: "left",
            sortable: true,
            render: (item) => item.categoryName,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            width: "5%",
          },
          {
            accessor: "listCost",
            title: "List Cost",
            textAlign: "right",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: (item) =>
              extensionMethods.decimalDisplay(item.listCost, true),
            width: "7.5%",
          },
          {
            accessor: "currentCost",
            title: "Current Cost",
            textAlign: "right",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: (item) =>
              extensionMethods.decimalDisplay(item.currentCost, true),
            width: "7.5%",
          },
          {
            accessor: "disabled",
            title: "Status",
            textAlign: "left",
            sortable: true,
            render: (item) => (item.disabled ? "Disabled" : "Enabled"),
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            width: "5%",
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (item) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showEditModal(item.itemID, setIsSuccessUpdate);
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showDeleteModal(item.itemID);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/Inventory/Item/Edit/" + record.itemID);
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
