import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import commonApi from "../../../api/commonApi";
import AccountCategoryTypeView from "../../../types/generalLedger/accountCategoryTypeView";
import PaginationSection from "../../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../../common/Sections/Pagination/PaginationSummary";
import AccountCategoryTypeSearch from "./accountCategoryTypeSearch";
import { ActionIcon, Box, Group, Text } from "@mantine/core";
import { IconEdit, IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { modals, openModal } from "@mantine/modals";
import AccountCategoryTypeFormEdit from "./accountCategoryTypeFormEdit";
import alerts from "../../common/alerts";

export default function AccountCategoryTypeList() {
  const navigate = useNavigate();
  const [accountCategoryTypes, setAccountCategoryTypes] = useState<
    AccountCategoryTypeView[]
  >([]);
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [params, setParams] = useState({});
  const [totalRecords, setTotalRecords] = useState(1);
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<AccountCategoryTypeView>
  >({
    columnAccessor: "",
    direction: "asc",
  });
  const getAccountCategoryTypeViewList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/accountCategoryTypeViews?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      setTotalRecords(data.length);
      setAccountCategoryTypes(data);
    }
  };

  useEffect(() => {
    getAccountCategoryTypeViewList();
  }, [sortStatus, page, pageSize, params]);

  function showEditModal(
    accountCategoryTypeID: number,
    setState?: Dispatch<SetStateAction<boolean>>
  ) {
    openModal({
      modalId: "edit",
      title: "Account Category Type Popup",
      children: (
        <BrowserRouter>
          <AccountCategoryTypeFormEdit
            id={accountCategoryTypeID.toString()}
            setState={setState}
          />
        </BrowserRouter>
      ),
    });
  }

  function showDeleteModal(accountCategoryTypeID: number) {
    modals.openConfirmModal({
      title: "Delete account category type",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this account category type/s.
        </Text>
      ),
      labels: { confirm: "Delete account category type", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        try {
          await commonApi
            .deleteApiCall("/Account/Category/Types/" + accountCategoryTypeID)
            .then(() => {
              alerts.showMessage("Account Category Type deleted");
              getAccountCategoryTypeViewList();
            });
        } catch (error) {
          alerts.showError(error);
        }
      },
    });
  }

  return (
    <div className="fade-in-block width100">
      <AccountCategoryTypeSearch setParamsFunction={setParams} />
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        records={accountCategoryTypes}
        idAccessor="AccountCategoryTypeID"
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        columns={[
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "typicalBalanceName",
            title: "Typical Balance",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "postingTypeName",
            title: "Posting Type",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (accountCategoryTypeView) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showEditModal(
                      accountCategoryTypeView.accountCategoryTypeID,
                      setIsSuccessUpdate
                    );
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showDeleteModal(
                      accountCategoryTypeView.accountCategoryTypeID
                    );
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate(
            "/Account/Category/Type/Edit/" + record.accountCategoryTypeID
          );
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
