import { FC, ReactNode, createContext, useState } from "react";
import { useLocalStorage } from "../components/common/useLocalStorage";

type LastUrlValid = string;

export type NavContextProps = {
  navBarLastSelectable: LastUrlValid | null;
  updateNavBarLastSelectable: (url: LastUrlValid) => void;
};

export const NavContext = createContext<NavContextProps | null>(null);

const NavProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [navBarLastSelectable, setNavBarLastSelectable] =
    useState<LastUrlValid | null>(null);
  const { setItem } = useLocalStorage();

  const updateNavBarLastSelectable = (url: LastUrlValid) => {
    setNavBarLastSelectable(url);
    setItem("lastValidURL", url);
  };

  return (
    <NavContext.Provider
      value={{ navBarLastSelectable, updateNavBarLastSelectable }}
    >
      {children}
    </NavContext.Provider>
  );
};

export default NavProvider;
