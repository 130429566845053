import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Account from "../../types/generalLedger/account";
import commonApi from "../../api/commonApi";
import alerts from "../common/alerts";
import { useForm } from "@mantine/form";
import RateDetail from "../../types/tax/rateDetail";
import {
  Box,
  Select,
  TextInput,
  Group,
  Button,
  NumberInput,
} from "@mantine/core";
import { closeAllModals } from "@mantine/modals";

type SelectType = {
  value: string;
  label: string;
};

export default function RateDetailEdit({
  rateDetailID,
  setState,
}: {
  rateDetailID?: number;
  setState?: Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [rateDetail, setRateDetail] = useState<RateDetail>({} as RateDetail);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [accountSelectData, setAccountSelectData] = useState<SelectType[]>([]);
  const [accountId, setAccountId] = useState("");
  const { rateDetailId } = useParams();
  const [isPopup, setIsPopup] = useState(false);

  const form = useForm({
    initialValues: {
      accountID: 0,
      name: "",
      ratePercentage: 0.0,
      rateDetailID: 0,
    },
    validate: {
      name: (value) =>
        value.length < 2 ? "Name must have at least 2 characters" : null,
      accountID: (value) => (value === 0 ? "Please select account" : null),
      ratePercentage: (value) =>
        value === 0 || value === null
          ? "Please type amount greater than 0"
          : null,
    },
  });

  const onSubmitHandler = async (values: any) => {
    let rateDetail: RateDetail = {
      name: values.name,
      ratePercentage: values.ratePercentage,
      accountID: values.accountID,
      rateDetailID: values.rateDetailID,
    };
    try {
      await commonApi
        .putApiCall("/ratedetails/" + rateDetail.rateDetailID, rateDetail)
        .then((resp) => {
          if (isPopup) {
            if (setState) {
              setState(true);
              closeAllModals();
            }
          } else {
            navigate("/Tax/RateDetail/List");
          }
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  const getAccountsList = async () => {
    let data = null;
    let accountList: SelectType[] = [];
    try {
      await commonApi.getApiCall("/accounts").then((response) => {
        data = response;
        if (data) {
          data.map((account: Account) => {
            let a: SelectType = {
              label: account.name,
              value: account.accountID.toString(),
            };
            accountList.push(a);
          });
          setAccountSelectData(accountList);
          setAccounts(data);
        }
      });
    } catch (error) {
      alerts.showError(error);
    }
  };

  const getRateDetailById = async () => {
    let data = null;
    try {
      await commonApi
        .getApiCall("/ratedetails/" + rateDetailID)
        .then((response) => {
          if (response) {
            data = response;
            setRateDetail(response);
            if (data) {
              form.setValues({
                accountID: data.accountID,
                ratePercentage: data.ratePercentage,
                name: data.name,
                rateDetailID: data.rateDetailID,
              });
              setAccountId(data.accountID.toString());
            }
          }
        });
    } catch (error) {}
  };

  useEffect(() => {
    if (rateDetailId) {
      rateDetailID = parseInt(rateDetailId);
      setIsPopup(false);
    } else {
      setIsPopup(true);
    }
    getRateDetailById();
    getAccountsList();
  }, []);

  return (
    <Box className={!isPopup ? "fade-in-block width33" : ""}>
      <form
        onSubmit={form.onSubmit((values) => {
          onSubmitHandler(values);
        })}
      >
        <Select
          label="Account"
          placeholder="account"
          withAsterisk
          data={accountSelectData}
          allowDeselect={true}
          value={accountId}
          clearable
          searchable
          nothingFoundMessage="Nothing found..."
          onChange={(value) => {
            if (value === null) {
              form.setFieldValue("accountID", parseInt("0"));
            } else {
              form.setFieldValue("accountID", parseInt(value));
            }
          }}
        />
        <TextInput
          withAsterisk
          label="Name"
          placeholder="name of rate detail"
          {...form.getInputProps("name")}
        />
        <NumberInput
          label="Rate Percentage"
          placeholder="rate percentage"
          allowNegative={false}
          decimalScale={2}
          {...form.getInputProps("ratePercentage")}
        />
        <Group justify="flex-end" mt="md">
          <Button
            onClick={(event) => {
              if (isPopup) {
                closeAllModals();
              } else {
                event.preventDefault();
                navigate("/Tax/RateDetail/List");
              }
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Update</Button>
        </Group>
      </form>
    </Box>
  );
}
