import { TextInput, Button, Group } from "@mantine/core";
import { useForm } from "@mantine/form";
import ButtonGroup from "../../common/ButtonGroup";
import SearchSection from "../../common/Sections/Search/SearchSection";

function BankAccountSearch(props: any) {
  const form = useForm({
    initialValues: {
      search: "",
    },
  });
  return (
    <form onSubmit={form.onSubmit((values) => props.setParamsFunction(values))}>
      <SearchSection
        buttonSection={
          <ButtonGroup
            cancelLabel="Reset"
            cancelClick={form.reset}
            okLabel="Search"
          />
        }
      >
        <TextInput
          label="Search"
          placeholder="search by name, account number, bsb etc.."
          {...form.getInputProps("search")}
        />
      </SearchSection>
    </form>
  );
}
export default BankAccountSearch;
