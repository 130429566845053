import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppRoute from "../../types/appRoute/appRoute";
import { useForm } from "@mantine/form";
import AppRouteCheckbox from "./appRouteCheckbox";
import { DataNode } from "antd/es/tree";
import Role from "../../types/user/role";
import commonApi from "../../api/commonApi";
import { Box, TextInput, Fieldset, Group, Switch, Button } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import alerts from "../common/alerts";

export default function RoleAdd() {
  const navigate = useNavigate();
  const [selectedAppRouteIDs, setSelectedAppRouteIDs] = useState<number[]>([]);
  const [appRoutes, setAppRoutes] = useState<AppRoute[]>([]);
  const [isDefaultForAppAdmin, setIsDefaultForAppAdmin] = useState(false);
  const [isDefaultForCompanyAdmin, setIsDefaultForCompanyAdmin] =
    useState(false);
  const [isDefaultForAll, setIsDefaultForAll] = useState(false);

  const form = useForm({
    initialValues: {
      roleID: 0,
      name: "",
      defaultForAppAdmin: false,
      defaultForCompanyAdmin: false,
      defaultForForAll: false,
    },
    validate: {
      name: (value) =>
        value.length < 1 ? "Role name must have at least 1 characters" : null,
    },
  });

  const getAppRouteList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/approutes?");
    } catch (error) {}
    if (data) {
      setAppRoutes(data);
    }
  };

  const appendAppRouteChild = (
    parentAppRoute: DataNode,
    childAppRoutes: AppRoute[]
  ) => {
    childAppRoutes.map((appRoute: AppRoute) => {
      var child: DataNode = {
        title: appRoute.menuTitle,
        key: appRoute.appRouteID ? appRoute.appRouteID : 0,
        children: [],
      };
      parentAppRoute.children?.push(child);
      if (appRoute.childAppRoutes) {
        if (appRoute.childAppRoutes.length > 0)
          appendAppRouteChild(child, appRoute.childAppRoutes);
      }
    });
    return parentAppRoute;
  };

  const generateTreeViewUsingCheckbox = (appRouteList: AppRoute[]) => {
    var parentNodes: DataNode[] = [];
    appRouteList.map((approute: AppRoute) => {
      if (approute.childAppRoutes) {
        if (approute.childAppRoutes.length > 0) {
          var parentItem: DataNode = {
            title: approute.menuTitle,
            key: approute.appRouteID ? approute.appRouteID : 0,
            children: [],
          };
          parentNodes.push(parentItem);
          appendAppRouteChild(parentItem, approute.childAppRoutes);
        } else {
          var parentItem: DataNode = {
            title: approute.menuTitle,
            key: approute.appRouteID ? approute.appRouteID : 0,
            children: [],
          };

          parentNodes.push(parentItem);
        }
      }
    });
    return (
      <AppRouteCheckbox
        dataNodes={parentNodes}
        selectedIds={selectedAppRouteIDs}
        setState={setSelectedAppRouteIDs}
      />
    );
  };

  const onSubmitHandler = async (values: any) => {
    let role: Role = {
      name: values.name,
      defaultForAll: isDefaultForAll,
      defaultForAppAdmin: isDefaultForAppAdmin,
      defaultForCompanyAdmin: isDefaultForCompanyAdmin,
      assignedAppRouteIds: selectedAppRouteIDs,
    };

    try {
      await commonApi.postApiCall("/security/roles", role).then((resp) => {
        navigate("/Role/List");
      });
    } catch (error) {
      alerts.showError(error);
    }
  };

  useEffect(() => {
    getAppRouteList();
  }, []);

  return (
    <div>
      <Box className="fade-in-block width33">
        <form
          onSubmit={form.onSubmit((values) => {
            onSubmitHandler(values);
          })}
        >
          <TextInput
            withAsterisk
            label="Name"
            placeholder="role name"
            {...form.getInputProps("name")}
          />
          <Switch
            checked={isDefaultForAppAdmin}
            label="Default for App Admin"
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
            onChange={(event) =>
              setIsDefaultForAppAdmin(event.currentTarget.checked)
            }
          />
          <Switch
            checked={isDefaultForCompanyAdmin}
            label="Default for Company Admin"
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
            onChange={(event) =>
              setIsDefaultForCompanyAdmin(event.currentTarget.checked)
            }
          />
          <Switch
            checked={isDefaultForAll}
            label="Default for All"
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
            onChange={(event) =>
              setIsDefaultForAll(event.currentTarget.checked)
            }
          />
          <Fieldset legend="Application Routes">
            {generateTreeViewUsingCheckbox(appRoutes)}
          </Fieldset>
          <Group justify="flex-end" mt="md">
            <Button
              onClick={(event) => {
                event.preventDefault();
                navigate("/Role/List");
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Submit</Button>
          </Group>
        </form>
      </Box>
    </div>
  );
}
