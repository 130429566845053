import {
  TextInput,
  Checkbox,
  Button,
  Group,
  Box,
  NativeSelect,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState, useEffect } from "react";
import commonApi from "../../../api/commonApi";
import OptionItem from "../../../types/common/optionItem";
import ButtonGroup from "../../common/ButtonGroup";
import SearchSection from "../../common/Sections/Search/SearchSection";

export default function LedgerDetailSearch(props: any) {
  const form = useForm({
    initialValues: {
      search: "",
      accountID: 0,
      postingYearID: 0,
      postingPeriodID: 0,
      subLedgerTypeID: 0,
    },
  });
  const [accounts, setAccounts] = useState<OptionItem[]>([]);
  const [postingYears, setPostingYears] = useState<OptionItem[]>([]);
  const [postingPeriods, setPostingPeriods] = useState<OptionItem[]>([]);
  const [subLedgerTypes, setSubLedgerTypes] = useState<OptionItem[]>([]);
  const getAccountList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/accounts");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { name: string; accountNumber: string; accountID: string }) => ({
          label: s.accountNumber + " " + s.name,
          value: s.accountID,
        })
      );
      data2.splice(0, 0, { label: "All", value: "0" });
      setAccounts(data2);
    }
  };
  const getPostingYearList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/posting/years");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { name: string; postingYearID: string }) => ({
          label: s.name,
          value: s.postingYearID,
        })
      );
      data2.splice(0, 0, { label: "All", value: "0" });
      setPostingYears(data2);
    }
  };
  const getPostingPeriodList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/posting/periods");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { name: string; postingPeriodID: string }) => ({
          label: s.name,
          value: s.postingPeriodID,
        })
      );
      data2.splice(0, 0, { label: "All", value: "0" });
      setPostingPeriods(data2);
    }
  };
  const getSubLedgerTypeList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/system/subledgertypes");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { name: string; systemSubLedgerTypeID: string }) => ({
          label: s.name,
          value: s.systemSubLedgerTypeID,
        })
      );
      data2.splice(0, 0, { label: "All", value: "0" });
      setSubLedgerTypes(data2);
    }
  };
  useEffect(() => {
    getAccountList();
    getPostingYearList();
    getPostingPeriodList();
    getSubLedgerTypeList();
  }, []);
  return (
    <form onSubmit={form.onSubmit((values) => props.setParamsFunction(values))}>
      <SearchSection
        buttonSection={
          <ButtonGroup
            cancelLabel="Reset"
            cancelClick={form.reset}
            okLabel="Search"
          />
        }
      >
        <TextInput
          label="Search"
          placeholder="search by name"
          {...form.getInputProps("search")}
        />
        <NativeSelect
          label="Account"
          data={accounts}
          {...form.getInputProps("accountID")}
        />
        <NativeSelect
          label="Posting Year"
          data={postingYears}
          {...form.getInputProps("postingYearID")}
        />
        <NativeSelect
          label="Posting Period"
          data={postingPeriods}
          {...form.getInputProps("postingPeriodID")}
        />
        <NativeSelect
          label="Sub-Ledger Type"
          data={subLedgerTypes}
          {...form.getInputProps("subLedgerTypeID")}
        />
      </SearchSection>
    </form>
  );
}
