import { Menu, UnstyledButton, useMantineColorScheme } from "@mantine/core";
import UserBanner from "./UserBanner";
import {
  IconLogout,
  IconMoon,
  IconSearch,
  IconSunFilled,
  IconUser,
} from "@tabler/icons-react";
import { googleLogout } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import styles from "./User.module.css";
import { useAuth } from "./useAuth";

const UserMenu = () => {
  const navigate = useNavigate();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const { user, logout } = useAuth();

  const logOut = () => {
    console.log("Logging out");
    googleLogout();
    logout();
    navigate("/login");
  };

  return (
    <Menu
      shadow="md"
      position="bottom-end"
      arrowPosition="center"
      offset={10}
      classNames={{ dropdown: styles.dropdown }}
      transitionProps={{ duration: 500, transition: "pop-top-right" }}
      // closeOnItemClick={false}
    >
      <Menu.Target>
        <UnstyledButton>
          <UserBanner
            image={user?.picture || ""}
            name={user?.name || ""}
            email={user?.email || ""}
          />
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>User</Menu.Label>
        <Menu.Item leftSection={<IconUser size={14} />}>My account</Menu.Item>
        <Menu.Item leftSection={<IconSearch size={14} />} disabled>
          Search
        </Menu.Item>

        <Menu.Divider />
        <Menu.Label>System</Menu.Label>
        <Menu.Item
          leftSection={
            colorScheme === "light" ? (
              <IconMoon size={14} />
            ) : (
              <IconSunFilled size={14} />
            )
          }
          onClick={() => toggleColorScheme()}
        >
          Turn to {colorScheme === "light" ? " Dark " : " Light "} Mode
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item
          leftSection={<IconLogout size={14} />}
          onClick={() => logOut()}
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default UserMenu;
