import { useEffect, useState } from "react";
import commonApi from "../../api/commonApi";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import ExchangeRate from "../../types/company/exchangeRate";
import { ActionIcon, Box, Button, Group, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import alerts from "../common/alerts";
import PaginationSection from "../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../common/Sections/Pagination/PaginationSummary";
import { IconEdit, IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import ExchangeRateSearch from "./exhangeRateSearch";
import { modals } from "@mantine/modals";

export default function ExchangeRateList() {
  const navigate = useNavigate();
  const [exchangeRates, setExchangeRates] = useState<ExchangeRate[]>([]);
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<ExchangeRate>
  >({
    columnAccessor: "name",
    direction: "asc",
  });
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [totalRecords, setTotalRecords] = useState(1);
  const [params, setParams] = useState({});
  const [selectedRecords, setSelectedRecords] = useState<ExchangeRate[]>([]);

  const getExchangeRateList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/exchangeratespaging?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      setTotalRecords(data.length);
      setExchangeRates(data);
    }
  };

  const openDeleteModal = (
    exchangeRate?: ExchangeRate,
    selectedRates?: ExchangeRate[]
  ) => {
    modals.openConfirmModal({
      title: "Delete exchange rate",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this currency.</Text>
      ),
      labels: { confirm: "Delete exchange rate", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        if (exchangeRate) {
          if (exchangeRate.exchangeRateID) {
            try {
              await commonApi
                .deleteApiCall("/exchangerates/" + exchangeRate.exchangeRateID)
                .then((response) => {
                  alerts.showMessage("Exchange rate delete success");
                  getExchangeRateList();
                });
            } catch (error) {
              alerts.showError(error);
            }
          }
        }

        if (selectedRates) {
          let selectedCount = selectedRates.length;
          let counter = 0;
          selectedRates.forEach(async (exchangeRate) => {
            if (exchangeRate.exchangeRateID) {
              try {
                await commonApi
                  .deleteApiCall(
                    "/exchangerates/" + exchangeRate.exchangeRateID
                  )
                  .then((response) => {
                    counter++;
                  });
              } catch (error) {
                alerts.showError(error);
              }

              if (counter === selectedCount) {
                alerts.showMessage("Exchange rate delete success");
                setSelectedRecords([]);
                getExchangeRateList();
              }
            }
          });
        }
      },
    });
  };

  useEffect(() => {
    getExchangeRateList();
  }, [sortStatus, page, pageSize, params]);

  return (
    <div>
      <ExchangeRateSearch setParamsFunction={setParams} />
      <Group mt="md" justify="flex-end">
        <Button
          onClick={() => {
            navigate("/ExchangeRate/Add");
          }}
        >
          Add
        </Button>
        <Button
          onClick={() => {
            if (selectedRecords.length > 0) {
              openDeleteModal(undefined, selectedRecords);
            } else {
              alerts.showClientError({
                message: "No selected record to delete",
              });
            }
          }}
        >
          Delete
        </Button>
      </Group>
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        pinLastColumn
        records={exchangeRates}
        idAccessor="exchangeRateID"
        isRecordSelectable={(record) => record.canBeDeleted === true}
        columns={[
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
            width: "15%",
          },
          {
            accessor: "detail",
            title: "Detail",
            textAlign: "left",
            sortable: true,
            width: "25%",
          },
          {
            accessor: "sourceCurrency.name",
            title: "Source Currency",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "targetCurrency.name",
            title: "Target Currency",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "rateValue",
            title: "Rate Value",
            textAlign: "left",
            sortable: true,
            width: "5%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.lg})`,
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (exchangeRate) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  disabled={!exchangeRate.canBeDeleted}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    openDeleteModal(exchangeRate);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/ExchangeRate/Edit/" + record.exchangeRateID);
        }}
        selectedRecords={selectedRecords}
        onSelectedRecordsChange={setSelectedRecords}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
