import { useEffect, useState } from "react";
import commonApi from "../../api/commonApi";
import CompanyGroup from "../../types/company/companyGroup";
import alerts from "../common/alerts";
import { Box, Button, Group, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

export default function CompanyGroupFormEdit() {
  const [companyGroup, setCompanyGroup] = useState<CompanyGroup>(
    {} as CompanyGroup
  );
  const form = useForm({
    initialValues: {
      name: "",
      companyGroupID: 0,
    },
    validate: {
      name: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
    },
  });

  const getCompanyGroupById = async () => {
    let data = null;
    try {
      let id = 0;
      data = await commonApi.getApiCall("/groups/" + id);
      if (data) setCompanyGroup(data);

      form.setValues({
        name: data.name,
        companyGroupID: data.companyGroupID,
      });
    } catch (error) {}
  };

  const onSubmitHandler = async (values: any) => {
    try {
      await commonApi
        .putApiCall("/groups/" + values.companyGroupID, values)
        .then((response) => {
          if (response === "") {
            alerts.showMessage("Company group name updated");
          }
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  useEffect(() => {
    getCompanyGroupById();
  }, []);

  return (
    <>
      <Box className="fade-in-block width33">
        <form
          onSubmit={form.onSubmit((values) => {
            onSubmitHandler(values);
          })}
        >
          <TextInput
            withAsterisk
            label="Name"
            placeholder="Name of company"
            {...form.getInputProps("name")}
          />
          <Group justify="flex-end" mt="md">
            <Button type="submit">Update</Button>
          </Group>
        </form>
      </Box>
    </>
  );
}
