import ReactDOM from "react-dom/client";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MantineProvider } from "@mantine/core";
import { createTheme } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import App from "./App";
import "@mantine/core/styles.layer.css";
import "@mantine/dates/styles.layer.css";
import "@mantine/notifications/styles.css";
import "mantine-datatable/styles.layer.css";
import AuthProvider from "./components/user/AuthContext";
import NavigationContext from "./navigation/NavigationContext";

const theme = createTheme({
  colors: {
    primaryColor: [
      "#04ADEE",
      "#04ADEE",
      "#04ADEE",
      "#04ADEE",
      "#04ADEE",
      "#04ADEE",
      "#04ADEE",
      "#04ADEE",
      "#04ADEE",
      "#04ADEE",
    ],
    secondaryColor: [
      "#999999",
      "#999999",
      "#999999",
      "#999999",
      "#999999",
      "#999999",
      "#999999",
      "#999999",
      "#999999",
      "#999999",
    ],
    tertiaryColor: [
      "#004777",
      "#004777",
      "#004777",
      "#004777",
      "#004777",
      "#004777",
      "#004777",
      "#004777",
      "#004777",
      "#004777",
    ],
    //   /** Extra colors */
    errorColor: [
      "#A30000",
      "#A30000",
      "#A30000",
      "#A30000",
      "#A30000",
      "#A30000",
      "#A30000",
      "#A30000",
      "#A30000",
      "#A30000",
    ],
    warningColor: [
      "#FF7700",
      "#FF7700",
      "#FF7700",
      "#FF7700",
      "#FF7700",
      "#FF7700",
      "#FF7700",
      "#FF7700",
      "#FF7700",
      "#FF7700",
    ],
  },
  fontFamily: "Roboto, sans-serif",
  scale: 0.9,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID as string}>
    <React.StrictMode>
      <MantineProvider theme={theme} defaultColorScheme="light">
        <Notifications />
        <ModalsProvider>
          <BrowserRouter>
            <AuthProvider>
              <NavigationContext>
                <App />
              </NavigationContext>
            </AuthProvider>
          </BrowserRouter>
        </ModalsProvider>
      </MantineProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
