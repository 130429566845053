import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import { useForm } from "@mantine/form";
import { Box, TextInput, NativeSelect } from "@mantine/core";
import OptionItem from "../../../types/common/optionItem";
import alerts from "../../common/alerts";
import AccountCategoryInformation from "../accountCategory/accountCategoryInformation";

export default function AccountFormAdd()
  {
    const navigate = useNavigate();   
    const [accountCategories, setAccountCategories] = useState<OptionItem[]>([]);
    const [accountCategoryID, setAccountCategoryID] = useState(0);
    const form = useForm({
        initialValues: {
            accountID: 0,
            name: "",
            accountCategoryID: 0,
            accountNumber1: "",
            accountNumber2: "",
            accountNumber3: "",
            accountNumber: ""     
        }        
      }); 
    const getAccountCategoryList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/account/categories");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { name: string; accountCategoryID: string; }) => ({label: s.name, value: s.accountCategoryID}));
        data2.splice(0, 0, {label: "Select..", value: "0"});
        setAccountCategories(data2);               
      }
    };
    const onAccountCategoryChange = (e: any) => {
      setAccountCategoryID(e.currentTarget.value);
    }    
    useEffect(() => {      
      getAccountCategoryList();
    }, 
    []);    
    const onSubmitHandler = async (values: any) => {
      try {
        await commonApi.postApiCall("/accounts", values).then(() => {
            alerts.showMessage("Account added");
            navigate("/Account/List");
        });
      } 
      catch (error) {
        alerts.showError(error);
      }
    };    
    return (
      <>
        <Box mx="auto">
          <form
            onSubmit={form.onSubmit((values) => {
                onSubmitHandler(values);
            })}>   
            <NativeSelect 
              withAsterisk
              label="Account Category" 
              className="searchFormElement"
              data={accountCategories}
              {...form.getInputProps("accountCategoryID")}
              onChange={(e) => {
                onAccountCategoryChange(e);
                form.getInputProps("accountCategoryID").onChange(e);
              }}/>
            <AccountCategoryInformation 
              accountCategoryID={accountCategoryID}
              form={form}
              showInput={true}/>
          </form>
        </Box>
      </>
    );
  }