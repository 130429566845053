import { useForm } from "@mantine/form";
import SearchSection from "../../common/Sections/Search/SearchSection";
import ButtonGroup from "../../common/ButtonGroup";
import { Checkbox, NativeSelect, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import OptionItem from "../../../types/common/optionItem";
import commonApi from "../../../api/commonApi";

export default function SiteSearch(props: any) {
  const form = useForm({
    initialValues: {
      search: "",
      disabled: false,
      addressID: 0,
      salesTaxScheduleID: 0,
      purchaseTaxScheduleID: 0,
    },
  });

  const [taxSchedules, setTaxSchedules] = useState<OptionItem[]>([]);
  const [siteAddress, setSiteAddress] = useState<OptionItem[]>([]);

  const getTaxSchedulesOptions = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/schedules");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setTaxSchedules(data2);
    }
  };

  const getAddressOptions = async () => {
    let data: any[] = [];
    try {
      //call service to get address
    } catch (error) {}

    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setSiteAddress(data2);
    }
  };

  useEffect(() => {
    getTaxSchedulesOptions();
    getAddressOptions();
  }, []);

  return (
    <form onSubmit={form.onSubmit((values) => props.setParamsFunction(values))}>
      <SearchSection
        buttonSection={
          <ButtonGroup
            cancelLabel="Reset"
            cancelClick={form.reset}
            okLabel="Search"
          />
        }
      >
        <TextInput
          label="Search"
          placeholder="general text search"
          {...form.getInputProps("search")}
        />
        {/* <NativeSelect
          name="addressID"
          label="Address"
          description="The address used by the site"
          data={siteAddress}
          {...form.getInputProps("addressID")}
        /> */}
        <NativeSelect
          name="salesTaxScheduleID"
          label="Sales Tax Schedule"
          description="The tax schedule to use for sales transactions"
          data={taxSchedules}
          {...form.getInputProps("salesTaxScheduleID")}
        />
        <NativeSelect
          name="purchaseTaxScheduleID"
          label="Purchase Tax Schedule"
          description="The tax schedule to use for purchase transactions"
          data={taxSchedules}
          {...form.getInputProps("purchaseTaxScheduleID")}
        />
        <Checkbox label="Disabled" {...form.getInputProps("disabled")} />
      </SearchSection>
    </form>
  );
}
