import { FC, ReactNode, createContext, useState } from "react";
import LoggedUser from "../../types/login/loggedUser";

export type AuthContextProps = {
  user: LoggedUser | null;
  updateUser: (user: LoggedUser) => void;
};

// export const AuthContext = createContext<AuthContextProps>({
//   user: null,
//   setUser: () => {},
// });

export const AuthContext = createContext<AuthContextProps | null>(null);

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<LoggedUser | null>(null);

  const updateUser = (user: LoggedUser) => {
    setUser(user);
  };

  return (
    <AuthContext.Provider value={{ user, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
