import { useNavigate } from "react-router-dom";
import Creditor from "../../types/creditor/creditor";
import { useEffect, useState } from "react";
import commonApi from "../../api/commonApi";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import PaginationSummary from "../common/Sections/Pagination/PaginationSummary";
import PaginationSection from "../common/Sections/Pagination/PaginationSection";
import { ActionIcon, Box, Button, Group } from "@mantine/core";
import { IconEdit, IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import CreditorSearch from "./creditorSearch";

export default function CreditorList() {
  const navigate = useNavigate();
  const [creditorList, setCreditorList] = useState<Creditor[]>([]);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [params, setParams] = useState({});
  const [totalRecords, setTotalRecords] = useState(1);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Creditor>>({
    columnAccessor: "",
    direction: "asc",
  });

  const getCreditorList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/creditors?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      setTotalRecords(data.length);
      setCreditorList(data);
    }
  };

  const downloadPDF = async () => {
    let fileData = await commonApi.getDownloadCall("/creditorspdf/GL_PL");

    triggerDownload(fileData);
  };

  const triggerDownload = (fileData: any) => {
    // Create a URL for the blob object
    const url = window.URL.createObjectURL(
      new Blob([fileData], { type: "application/pdf" })
    );

    // Create a temporary anchor tag to trigger download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "report.pdf"); // Choose the file name and extension
    document.body.appendChild(link);
    link.click();

    // Clean up by revoking the URL and removing the link
    window.URL.revokeObjectURL(url);
    link.remove();
  };

  useEffect(() => {
    getCreditorList();
  }, [sortStatus, page, pageSize, params]);

  return (
    <div className="fade-in-block width100">
      <Button onClick={downloadPDF}>Download PDF</Button>
      <CreditorSearch setParamsFunction={setParams} />
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        records={creditorList}
        idAccessor="creditorID"
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        columns={[
          {
            accessor: "actions",
            title: "",
            textAlign: "right",
            width: "1%",
            render: (creditor) => (
              <Group gap={4} justify="right" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    // showEditModal(bank.bankID, setIsSuccessUpdate);
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
              </Group>
            ),
          },
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "website",
            title: "Website",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "phone",
            title: "Phone",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "email",
            title: "Email",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "actions",
            title: "",
            textAlign: "right",
            width: "1%",
            render: (creditor) => (
              <Group gap={4} justify="right" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    // showDeleteModal(bank.bankID);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/Finance/Creditor/Edit/" + record.creditorID);
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
