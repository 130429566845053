import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import { useForm } from "@mantine/form";
import { Box, TextInput, Group, Button, NativeSelect } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import OptionItem from "../../../types/common/optionItem";
import alerts from "../../common/alerts";

export default function AccountCategoryFormEdit({
    id,
    setState
  }: {
    id?: string;
    setState?: Dispatch<SetStateAction<boolean>>;
  })
  {
    const navigate = useNavigate();
    const form = useForm();    
    const [accountCategoryTypes, setAccountCategoryTypes] = useState<OptionItem[]>([]);
    const {accountCategoryID} = useParams();
    let isPopup: boolean = true;
    if (id == null){
      isPopup = false;
      id = accountCategoryID;
    } 
    const getAccountCategoryById = async () => {
      try {
        let data = await commonApi.getApiCall("/account/categories/" + id);
        form.setValues(data);        
      } catch (error) {}
    };    
    const getAccountCategoryTypeList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/account/category/types");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { name: string; accountCategoryTypeID: string; }) => ({label: s.name, value: s.accountCategoryTypeID}));
        setAccountCategoryTypes(data2);               
      }
    };  
    useEffect(() => {      
      getAccountCategoryById();
      getAccountCategoryTypeList();
    }, 
    []);    
    const onSubmitHandler = async (values: any) => {
      try {
        await commonApi.putApiCall("/account/categories/" + id, values).then(() => {
          alerts.showMessage("Account Category updated");
          if (isPopup) {
            if (setState){
              setState(true);
            }
            closeAllModals();
          } 
          else {
            navigate("/Account/Category/List");
          }
        });
      } 
      catch (error) {
        alerts.showError(error);
      }
    };    
    return (
      <>
        <Box mx="auto">
          <form
            onSubmit={form.onSubmit((values) => {
              onSubmitHandler(values);
            })}>
            <TextInput
              withAsterisk
              label="Name"
              placeholder="Name of Account Category"
              {...form.getInputProps("name")}/>        
            <NativeSelect 
              withAsterisk
              label="Account Category Type" 
              className="searchFormElement"
              data={accountCategoryTypes}
              {...form.getInputProps("accountCategoryTypeID")}/>
            <Group justify="flex-end" mt="md">
              <Button
                onClick={(event) => {
                  if (isPopup) {
                    closeAllModals();
                  } else {
                    event.preventDefault();
                    navigate("/Account/Category/List");
                  }
                }}>
                Back
              </Button>
              <Button type="submit">Update</Button>
            </Group>
          </form>
        </Box>
      </>
    );
  }