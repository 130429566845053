import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import { useForm } from "@mantine/form";
import { Box, TextInput, Group, Button, NativeSelect } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import OptionItem from "../../../types/common/optionItem";
import alerts from "../../common/alerts";
import AccountCategoryInformation from "../accountCategory/accountCategoryInformation";

export default function AccountFormEdit({
    id,
    setState
  }: {
    id?: string;
    setState?: Dispatch<SetStateAction<boolean>>;
  })
  {
    const navigate = useNavigate();
    const form = useForm();    
    const [accountCategories, setAccountCategories] = useState<OptionItem[]>([]);
    const {accountID} = useParams();
    const [accountCategoryID, setAccountCategoryID] = useState(0);
    let isPopup: boolean = true;
    if (id == null){
      isPopup = false;
      id = accountID;
    } 
    const getAccountById = async () => {
      try {
        let data = await commonApi.getApiCall("/Accounts/" + id);
        setAccountCategoryID(data.accountCategoryID);
        form.setValues(data);        
      } catch (error) {}
    };    
    const getAccountCategoryList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/account/categories");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { name: string; accountCategoryID: string; }) => ({label: s.name, value: s.accountCategoryID}));
        setAccountCategories(data2);               
      }
    };
    const onAccountCategoryChange = (e: any) => {
      setAccountCategoryID(e.currentTarget.value);
    }    
    useEffect(() => {      
      getAccountById();
      getAccountCategoryList();
    }, 
    []);    
    const onSubmitHandler = async (values: any) => {
      try {
        await commonApi.putApiCall("/Accounts/" + id, values).then(() => {
          alerts.showMessage("Account updated");
          if (isPopup) {
            if (setState){
              setState(true);
            }
            closeAllModals();
          } 
          else {
            navigate("/Account/List");
          }
        });
      } 
      catch (error) {
        alerts.showError(error);
      }
    };    
    return (
      <>
        <Box mx="auto">
          <form
            onSubmit={form.onSubmit((values) => {
              onSubmitHandler(values);
            })}>
            <TextInput
              withAsterisk
              label="Name"
              placeholder="Name of Account"
              {...form.getInputProps("name")}/>
            <TextInput
              withAsterisk
              label="Account Number 1"
              placeholder="First part of account number"
              {...form.getInputProps("accountNumber1")}/>     
            <TextInput
              withAsterisk
              label="Account Number 2"
              placeholder="Second part of account number"
              {...form.getInputProps("accountNumber2")}/>                     
            <TextInput
              withAsterisk
              label="Account Number 3"
              placeholder="Third part of account number"
              {...form.getInputProps("accountNumber3")}/>              
            <NativeSelect 
              withAsterisk
              label="Account Category" 
              className="searchFormElement"
              data={accountCategories}
              {...form.getInputProps("accountCategoryID")}
              onChange={(e) => {
                onAccountCategoryChange(e);
                form.getInputProps("accountCategoryID").onChange(e);
              }}/>
            <AccountCategoryInformation 
              accountCategoryID={accountCategoryID}
              form={form}
              showInput={false}/>              
            <Group justify="flex-end" mt="md">
              <Button
                onClick={(event) => {
                  if (isPopup) {
                    closeAllModals();
                  } else {
                    event.preventDefault();
                    navigate("/Account/List");
                  }
                }}>
                Back
              </Button>
              <Button type="submit">Update</Button>
            </Group>
          </form>
        </Box>
      </>
    );
  }