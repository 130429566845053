import {
  TextInput,
  Button,
  Group,
  Checkbox,
  Fieldset,
  NativeSelect,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import OptionItem from "../../types/common/optionItem";
import commonApi from "../../api/commonApi";
import ButtonGroup from "../common/ButtonGroup";
import SearchSection from "../common/Sections/Search/SearchSection";

export default function AdminCompanySearch(props: any) {
  const [companyGroups, setcompanyGroups] = useState<OptionItem[]>([]);
  const getCompanyGroupList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/groups");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { name: string; companyGroupID: string }) => ({
          label: s.name,
          value: s.companyGroupID,
        })
      );
      data2.splice(0, 0, { label: "All", value: "0" });
      setcompanyGroups(data2);
    }
  };
  const [statusItems, setStatusItems] = useState<OptionItem[]>([]);
  useEffect(() => {
    setStatusItems([
      { label: "All", value: "" },
      { label: "Enabled", value: "false" },
      { label: "Disabled", value: "true" },
    ]);
    getCompanyGroupList();
  }, []);
  const form = useForm({
    initialValues: {
      search: "",
      disabled: "",
      companyGroupID: "0",
    },
  });
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        props.setParamsFunction(values);
      })}
    >
      <SearchSection
        buttonSection={
          <ButtonGroup
            cancelLabel="Reset"
            cancelClick={form.reset}
            okLabel="Search"
          />
        }
      >
        <TextInput
          label="Name"
          placeholder="search by company name"
          {...form.getInputProps("search")}
        />
        <NativeSelect
          label="Company Group"
          data={companyGroups}
          {...form.getInputProps("companyGroupID")}
        />
        <NativeSelect
          label="Status"
          data={statusItems}
          {...form.getInputProps("disabled")}
        />
      </SearchSection>
    </form>
  );
}
