import {
  Box,
  SimpleGrid,
  Fieldset,
  TextInput,
  NativeSelect,
  Group,
  Switch,
  Button,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddressFormAdd from "../../address/addressFormAdd";
import OptionItem from "../../../types/common/optionItem";
import commonApi from "../../../api/commonApi";
import alerts from "../../common/alerts";
import { closeAllModals } from "@mantine/modals";

export default function SiteFormEdit({
  id,
  setState,
}: {
  id?: string;
  setState?: Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const form = useForm();
  const [value, setValue] = useState("");
  const { siteID } = useParams();

  let isPopup: boolean = true;
  if (id == null) {
    isPopup = false;
    id = siteID;
  }

  const [taxSchedules, setTaxSchedules] = useState<OptionItem[]>([]);

  const getSiteById = async () => {
    try {
      let data = await commonApi.getApiCall("/inventory/sites/" + id);
      form.setValues(data);
    } catch (error) {}
  };

  const getTaxSchedulesOptions = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/schedules");
    } catch (error) {}
    if (data) {
      let data2: OptionItem[] = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setTaxSchedules(data2);
    }
  };
  const onSubmitHandler = async (values: any) => {
    try {
      await commonApi.putApiCall("/inventory/sites/" + id, values).then(() => {
        alerts.showMessage("Site updated");
        if (isPopup) {
          if (setState) {
            setState(true);
            closeAllModals();
          }
        }
        navigate("/Inventory/Site/List");
      });
    } catch (error) {
      alerts.showError(error);
    }
  };

  useEffect(() => {
    getSiteById();
    getTaxSchedulesOptions();
  }, [id]);

  return (
    <Box>
      <form
        onSubmit={form.onSubmit((values) => {
          onSubmitHandler(values);
        })}
      >
        <SimpleGrid cols={2}>
          <Fieldset legend="Details">
            <TextInput
              name="name"
              label="Name"
              required
              {...form.getInputProps("name")}
            />

            <TextInput
              name="code"
              label="Code"
              required
              {...form.getInputProps("code")}
            />

            <TextInput
              name="phone"
              label="Phone"
              required
              {...form.getInputProps("phone")}
            />

            <TextInput name="fax" label="Fax" {...form.getInputProps("fax")} />

            <TextInput
              name="email"
              label="Email"
              required
              {...form.getInputProps("email")}
            />

            <Switch
              name="disabled"
              label="Disabled"
              {...form.getInputProps("disabled", { type: "checkbox" })}
            />
          </Fieldset>
          <Fieldset legend="Additional Properties">
            <AddressFormAdd form={form} />
            <NativeSelect
              name="salesTaxScheduleID"
              label="Sales Tax Schedule"
              description="The tax schedule to use for sales transactions"
              data={taxSchedules}
              {...form.getInputProps("salesTaxScheduleID")}
            />
            <NativeSelect
              name="purchaseTaxScheduleID"
              label="Purchase Tax Schedule"
              description="The tax schedule to use for purchase transactions"
              data={taxSchedules}
              {...form.getInputProps("purchaseTaxScheduleID")}
            />
            <Group justify="flex-end" mt="md">
              <Button
                onClick={(event) => {
                  if (isPopup) {
                    closeAllModals();
                  } else {
                    event.preventDefault();
                    navigate("/Inventory/Site/List");
                  }
                }}
              >
                Back
              </Button>
              <Button type="submit">Update</Button>
            </Group>
          </Fieldset>
        </SimpleGrid>
      </form>
    </Box>
  );
}
