import { Dispatch, SetStateAction, useEffect, useState } from "react";
import commonApi from "../../api/commonApi";
import { BrowserRouter, useNavigate } from "react-router-dom";
import Role from "../../types/user/role";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import PaginationSection from "../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../common/Sections/Pagination/PaginationSummary";
import { ActionIcon, Button, Group, Box, Text } from "@mantine/core";
import { IconEdit, IconTrash, IconMoodConfuzed } from "@tabler/icons-react";
import alerts from "../common/alerts";
import { modals, openModal } from "@mantine/modals";
import RoleEdit from "./roleEdit";

export default function RoleList() {
  const navigate = useNavigate();
  const [roles, setRoles] = useState<Role[]>([]);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Role>>({
    columnAccessor: "name",
    direction: "asc",
  });
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [totalRecords, setTotalRecords] = useState(1);
  const [params, setParams] = useState({});
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<Role[]>([]);

  const getRoleList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/security/roles?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      console.log(data);
      setTotalRecords(data.length);
      setRoles(data);
    }
  };

  const showModal = ({
    role,
    setState,
  }: {
    role: Role;
    setState?: Dispatch<SetStateAction<boolean>>;
  }) => {
    openModal({
      modalId: "update",
      title: "Edit role information",
      children: (
        <BrowserRouter>
          <RoleEdit roleID={role.roleID} setState={setState} />
        </BrowserRouter>
      ),
    });
  };

  const openDeleteModal = (role?: Role, selectedRoles?: Role[]) => {
    modals.openConfirmModal({
      title: "Delete role",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this role/s.</Text>
      ),
      labels: { confirm: "Delete role", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        if (role) {
          if (role.roleID) {
            try {
              await commonApi
                .deleteApiCall("/security/roles/" + role.roleID)
                .then((response) => {
                  alerts.showMessage("Schedule delete success");
                  getRoleList();
                });
            } catch (error) {
              alerts.showError(error);
            }
          }
        }

        if (selectedRoles) {
          let selectedCount = selectedRoles.length;
          let counter = 0;
          selectedRoles.forEach(async (role) => {
            if (role.roleID) {
              try {
                await commonApi
                  .deleteApiCall("/security/roles/" + role.roleID)
                  .then((response) => {
                    counter++;
                  });
              } catch (error) {
                alerts.showError(error);
              }

              if (counter === selectedCount) {
                alerts.showMessage("Company user delete success");
                setSelectedRecords([]);
                getRoleList();
              }
            }
          });
        }
      },
    });
  };

  useEffect(() => {
    getRoleList();
  }, [sortStatus, page, pageSize, params]);
  return (
    <div>
      <Group mt="md" justify="flex-end">
        <Button
          onClick={() => {
            navigate("/Role/Add");
          }}
        >
          Add
        </Button>
        <Button
          onClick={() => {
            if (selectedRecords.length > 0) {
              openDeleteModal(undefined, selectedRecords);
            } else {
              alerts.showClientError({
                message: "No selected record to delete",
              });
            }
          }}
        >
          Delete
        </Button>
      </Group>
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        pinLastColumn
        records={roles}
        idAccessor="roleID"
        isRecordSelectable={(record) => record.canBeDeleted === true}
        onRowClick={({ record, index, event }) => {
          navigate("/Role/Edit/" + record.roleID);
        }}
        selectedRecords={selectedRecords}
        onSelectedRecordsChange={setSelectedRecords}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        columns={[
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "defaultForAppAdmin",
            title: "Default for Admin",
            textAlign: "left",
            sortable: true,
            render: (role) => (role.defaultForAppAdmin === true ? "Yes" : "No"),
          },
          {
            accessor: "defaultForCompanyAdmin",
            title: "Default for Company Admin",
            textAlign: "left",
            sortable: true,
            render: (role) =>
              role.defaultForCompanyAdmin === true ? "Yes" : "No",
          },
          {
            accessor: "defaultForAll",
            title: "Default for All",
            textAlign: "left",
            sortable: true,
            render: (role) => (role.defaultForAll === true ? "Yes" : "No"),
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (role) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showModal({ role, setState: setIsSuccessUpdate });
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  disabled={!role.canBeDeleted}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    openDeleteModal(role);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
