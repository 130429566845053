import { useParams } from "react-router-dom";
export default function PostingPeriodFormEdit({
    id,
  }: {
    id?: string;
  })
  {
    //id set via popup, postingPeriodID passed in via route
    const {postingPeriodID} = useParams();
    id = id ?? postingPeriodID;        
    return (<div>Posting Period Type Form Edit {id}</div>);
  }