import { Anchor, Button, Group } from "@mantine/core";

interface ButtonGroupProps {
  cancelLabel: string;
  cancelClick: () => void;
  okLabel: string;
}

export default function ButtonGroup({
  cancelLabel,
  cancelClick,
  okLabel,
}: ButtonGroupProps) {
  return (
    <Group justify="flex-end" mt="md">
      <Anchor size={"sm"} underline={"never"} onClick={cancelClick}>
        {cancelLabel}
      </Anchor>
      <Button type="submit">{okLabel}</Button>
    </Group>
  );
}
