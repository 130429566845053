import { AppShell, NavLink, ScrollArea } from "@mantine/core";
import { menu } from "../types/navigation/menu";
import { useNavigate } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import NavLinkIcon from "./navLinkIcon";
import commonApi from "../api/commonApi";

type NavBar2Props = {
  data: menu[];
  toggleMobileMenu: () => void;
};
export default function NavBar2({ data, toggleMobileMenu }: NavBar2Props) {
  const navigate = useNavigate();
  const [active, setActive] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState<menu[]>([]);
  const getClosestMenu = async () => {
    try {
      let data = await commonApi.getApiCall(
        "/navigation/breadcrumb?url=" + window.location.pathname
      );
      setBreadcrumbs(data);
      let selectableBreadcrumbs = data.filter(
        (x: { selectable: boolean }) => x.selectable
      );
      setActive(selectableBreadcrumbs[selectableBreadcrumbs.length - 1].url);
    } catch (error) {}
  };
  useEffect(() => {
    getClosestMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const generateNavBar = useCallback(
    (items: menu[]) => {
      let elements = items.map((item: menu) => {
        if (item.childMenus.length !== 0) {
          if (item.selectable) {
            //has child and is selectable
            return (
              <NavLink
                key={item.appRouteID}
                leftSection={NavLinkIcon(item.icon, true)}
                className="emphasis"
                label={item.menuTitle + " Menu"}
                opened={
                  breadcrumbs.filter((x: { url: string }) => x.url === item.url)
                    .length > 0 || undefined
                }
              >
                <NavLink
                  key={item.appRouteID}
                  leftSection={NavLinkIcon(item.icon, false)}
                  active={active === item.url}
                  opened={
                    breadcrumbs.filter(
                      (x: { url: string }) => x.url === item.url
                    ).length > 0 || undefined
                  }
                  onClick={(event) => {
                    event.preventDefault();
                    navigate(item.url);
                    toggleMobileMenu();
                  }}
                  label={item.menuTitle}
                ></NavLink>
                {generateNavBar(item.childMenus)}
              </NavLink>
            );
          } else {
            //has child but it's not selectable
            return (
              <NavLink
                key={item.appRouteID}
                leftSection={NavLinkIcon(item.icon, true)}
                className="emphasis"
                label={item.menuTitle + " Menu"}
                opened={
                  breadcrumbs.filter((x: { url: string }) => x.url === item.url)
                    .length > 0 || undefined
                }
              >
                {generateNavBar(item.childMenus)}
              </NavLink>
            );
          }
        } else {
          return (
            //has no child
            <NavLink
              key={item.appRouteID}
              leftSection={NavLinkIcon(item.icon, false)}
              label={item.menuTitle}
              active={
                breadcrumbs.filter((x: { url: string }) => x.url === item.url)
                  .length > 0
              }
              opened={
                breadcrumbs.filter((x: { url: string }) => x.url === item.url)
                  .length > 0 || undefined
              }
              onClick={(event) => {
                event.preventDefault();
                navigate(item.url);
                toggleMobileMenu();
              }}
            ></NavLink>
          );
        }
      });
      return elements;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [active, toggleMobileMenu]
  );
  return (
    <AppShell.Navbar p="md" style={{ gap: "10px" }}>
      <ScrollArea>{generateNavBar(data)}</ScrollArea>
    </AppShell.Navbar>
  );
}
