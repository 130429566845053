import { Button, Group, NativeSelect, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import OptionItem from "../../types/common/optionItem";
import ButtonGroup from "../common/ButtonGroup";
import SearchSection from "../common/Sections/Search/SearchSection";

export default function CompanyUserSearch(props: any) {
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [statusItems, setStatusItems] = useState<OptionItem[]>([]);
  const form = useForm({
    initialValues: {
      search: "",
      createdFromDate: "",
      createdToDate: "",
      disabled: "",
    },
  });

  useEffect(() => {
    setStatusItems([
      { label: "All", value: "" },
      { label: "Enabled", value: "false" },
      { label: "Disabled", value: "true" },
    ]);
  }, []);

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        let fromDateStr = fromDate ? fromDate.toDateString() : "";
        let toDateStr = toDate ? toDate.toDateString() : "";
        values.createdFromDate = fromDateStr;
        values.createdToDate = toDateStr;
        props.setParamsFunction(values);
      })}
    >
      <SearchSection
        buttonSection={
          <ButtonGroup
            cancelLabel="Reset"
            cancelClick={form.reset}
            okLabel="Search"
          />
        }
      >
        <TextInput
          label="Name"
          placeholder="search by name/email/phone"
          {...form.getInputProps("search")}
        />
        <DateInput
          value={fromDate}
          onChange={setFromDate}
          label="Created from date"
          placeholder="from date"
        />
        <DateInput
          value={toDate}
          onChange={setToDate}
          label="Created to date"
          placeholder="to date"
        />
        <NativeSelect
          label="Status"
          data={statusItems}
          {...form.getInputProps("disabled")}
        />
      </SearchSection>
    </form>
  );
}
