import dayjs from "dayjs";

function dateMinusTimezoneOffset(date: any){
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
}
function dateDisplay(date: any){
    if (date === null){
        return "";
    }
    return dayjs(date).format("MMM DD YYYY")
}
function dateTimeDisplay(date: any){
    if (date === null){
        return "";
    }
    return dayjs(date).format("MMM DD YYYY HH:mm")
}
function decimalDisplay(number: any, hideZero: boolean){
    if (hideZero && number === 0){
        return "-";
    }
    return (Math.round(number * 100) / 100).toFixed(2);;
}
const extensionMethods = { 
    dateMinusTimezoneOffset,
    dateDisplay,
    dateTimeDisplay,
    decimalDisplay
};
export default extensionMethods;