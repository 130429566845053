import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import commonApi from "../../../api/commonApi";
import LedgerDetailView from "../../../types/generalLedger/ledgerDetailView";
import PaginationSection from "../../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../../common/Sections/Pagination/PaginationSummary";
import LedgerDetailSearch from "./ledgerDetailSearch";
import { ActionIcon, Box, Group, Text } from "@mantine/core";
import { IconEdit, IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import { modals, openModal } from "@mantine/modals";
import { BrowserRouter, useNavigate } from "react-router-dom";
import LedgerFormView from "./ledgerFormView";
import extensionMethods from "../../../common/extensionMethods";
import alerts from "../../common/alerts";

export default function LedgerDetailList() {
  const navigate = useNavigate();
  const [ledgerDetailViews, setLedgerDetailViews] = useState<
    LedgerDetailView[]
  >([]);
  const [currencyCode, setCurrencyCode] = useState("");
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [params, setParams] = useState({});
  const [totalRecords, setTotalRecords] = useState(1);
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<LedgerDetailView>
  >({
    columnAccessor: "",
    direction: "asc",
  });
  const getLedgerDetailViewList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/LedgerDetailViews?" + new URLSearchParams(params).toString()
      );
      if (data) {
        setTotalRecords(data.length);
        setLedgerDetailViews(data);
        if (data.length != 0) {
          setCurrencyCode(data[0].targetCurrencyCode);
        }
      }
    } catch (error) {
      alerts.showClientError(error);
    }
  };
  useEffect(() => {
    getLedgerDetailViewList();
  }, [sortStatus, page, pageSize, params]);
  function showViewModal(ledgerID: number) {
    openModal({
      modalId: "edit",
      title: "Ledger View Popup",
      size: "100vw",
      children: (
        <BrowserRouter>
          <LedgerFormView id={ledgerID.toString()} />
        </BrowserRouter>
      ),
    });
  }
  function creditTotal() {
    var output: number = 0;
    if (ledgerDetailViews) {
      ledgerDetailViews.forEach((element) => {
        output += element.creditTargetValue;
      });
    }
    return output;
  }
  function debitTotal() {
    var output: number = 0;
    if (ledgerDetailViews) {
      ledgerDetailViews.forEach((element) => {
        output += element.debitTargetValue;
      });
    }
    return output;
  }
  function balanceTotal() {
    var output: number = 0;
    if (ledgerDetailViews) {
      ledgerDetailViews.forEach((element) => {
        output += element.balanceTargetValue;
      });
    }
    return output;
  }
  return (
    <div className="fade-in-block width100">
      <LedgerDetailSearch setParamsFunction={setParams} />
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        records={ledgerDetailViews}
        idAccessor="LedgerDetailID"
        minHeight={200}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        columns={[
          {
            accessor: "name",
            title: "Detail",
            textAlign: "left",
            sortable: true,
            render: (ledgerDetailView) => (
              <>
                {ledgerDetailView.subLedgerName}
                <div className="smallText">{ledgerDetailView.name}</div>
              </>
            ),
          },
          {
            accessor: "accountNumber",
            title: "Account",
            textAlign: "left",
            sortable: true,
            width: "20%",
            render: (ledgerDetailView) => (
              <>
                {ledgerDetailView.accountNumber}
                <div className="smallText">{ledgerDetailView.accountName}</div>
              </>
            ),
          },
          {
            accessor: "postingYearName",
            title: "Year",
            textAlign: "left",
            sortable: true,
            width: "8%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "postingPeriodName",
            title: "Period",
            textAlign: "left",
            sortable: true,
            width: "8%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "postingDate",
            title: "Date",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: ({ postingDate }) =>
              extensionMethods.dateDisplay(postingDate),
            width: "8%",
          },
          {
            accessor: "subLedgerTypeName",
            title: "Type",
            textAlign: "left",
            sortable: true,
            width: "8%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: (ledgerDetailView) =>
              ledgerDetailView.subLedgerTypeName +
              " " +
              ledgerDetailView.subLedgerTypeSourceID,
          },
          {
            accessor: "targetSourceTotal",
            title: "Target Credit",
            textAlign: "right",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: (ledgerDetailView) =>
              extensionMethods.decimalDisplay(
                ledgerDetailView.creditTargetValue,
                true
              ) +
              (ledgerDetailView.creditTargetValue === 0
                ? ""
                : " " + ledgerDetailView.targetCurrencyCode),
            width: "7.5%",
            footer:
              extensionMethods.decimalDisplay(creditTotal(), false) +
              " " +
              currencyCode,
          },
          {
            accessor: "debitTargetTotal",
            title: "Target Debit",
            textAlign: "right",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: (ledgerDetailView) =>
              extensionMethods.decimalDisplay(
                ledgerDetailView.debitTargetValue,
                true
              ) +
              (ledgerDetailView.debitTargetValue === 0
                ? ""
                : " " + ledgerDetailView.targetCurrencyCode),
            width: "7.5%",
            footer:
              extensionMethods.decimalDisplay(debitTotal(), false) +
              " " +
              currencyCode,
          },
          {
            accessor: "balanceTargetTotal",
            title: "Target Balance",
            textAlign: "right",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: (ledgerDetailView) =>
              extensionMethods.decimalDisplay(
                ledgerDetailView.balanceTargetValue,
                true
              ) +
              (ledgerDetailView.balanceTargetValue === 0
                ? ""
                : " " + ledgerDetailView.targetCurrencyCode),
            width: "7.5%",
            footer:
              extensionMethods.decimalDisplay(balanceTotal(), false) +
              " " +
              currencyCode,
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (ledgerDetailView) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showViewModal(ledgerDetailView.ledgerID);
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/LedgerDetail/View/" + record.ledgerID);
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
