import React, { Dispatch, SetStateAction, useState } from "react";
// import "antd/dist/antd.css";
// import "./index.css";
import { Tree } from "antd";
import type { DataNode } from "antd/es/tree";
import { Button } from "@mantine/core";

export default function AppRouteCheckbox({
  dataNodes,
  selectedIds,
  setState,
}: {
  dataNodes: DataNode[];
  selectedIds?: number[];
  setState?: Dispatch<SetStateAction<number[]>>;
}) {
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<number[]>(
    selectedIds ? selectedIds : []
  );
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue: any) => {
    setCheckedKeys(checkedKeysValue);
    if (setState) setState(checkedKeysValue);
  };

  const onSelect = (selectedKeysValue: React.Key[], info: any) => {
    setSelectedKeys(selectedKeysValue);
  };

  return (
    <>
      <Tree
        checkable
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onCheck={onCheck}
        checkedKeys={checkedKeys}
        onSelect={onSelect}
        selectedKeys={selectedKeys}
        treeData={dataNodes}
      />
    </>
  );
}
