import { Box, Button, Group, Select, Switch, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Company from "../../types/company/company";
import commonApi from "../../api/commonApi";
import { closeAllModals } from "@mantine/modals";
import Currency from "../../types/company/currenty";
import CompanyGroup from "../../types/company/companyGroup";
import alerts from "../common/alerts";

type SelectType = {
  value: string;
  label: string;
  disabled: boolean;
};

export default function AdminCompanyFormEdit({
  id,
  setState,
}: {
  id?: number;
  setState?: Dispatch<SetStateAction<boolean>>;
}) {
  const { companyID } = useParams();
  const navigate = useNavigate();
  const [currencyList, setCurrencyList] = useState<Currency[]>([]);
  const [currencyData, setCurrencyData] = useState<SelectType[]>([]);
  const [groupData, setGroupData] = useState<SelectType[]>([]);
  const [company, setCompany] = useState<Company>({} as Company);
  const [groupId, setGroupId] = useState("");
  const [currencyId, setCurrencyId] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [canBeDisabled, setCanBeDisabled] = useState(false);

  const form = useForm({
    initialValues: {
      name: "",
      companyId: 0,
      companyGroupID: 0,
      createdBy: 1,
      currencyId: 0,
      disabled: false,
    },
    validate: {
      name: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      companyGroupID: (value) =>
        value === 0 ? "Please select company group" : null,
      currencyId: (value) =>
        value === 0 ? "Please select currency value" : null,
    },
  });

  const getCompanyById = async () => {
    let data = null;
    try {
      if (id) data = await commonApi.getApiCall("/companies/" + id);
      if (data) {
        setCompany(data);
        setCanBeDisabled(data.canBeDisabled);
      }

      form.setValues({
        name: data.name,
        companyId: data.companyID,
        companyGroupID: data.companyGroupID,
        createdBy: 1,
        currencyId: data.currencyID,
        disabled: data.disabled,
      });

      setGroupId(data.companyGroupID.toString());
      setCurrencyId(data.currencyID.toString());
      setDisabled(data.disabled);
    } catch (error) {}
  };

  const onSubmitHandler = async (values: any) => {
    try {
      await commonApi
        .putApiCall("/companies/" + values.companyId, values)
        .then(() => {
          if (isPopup) {
            if (setState) {
              setState(true);
              closeAllModals();
            }
          } else {
            navigate("/Admin/Company/List");
          }
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  //get company group
  let groupList: SelectType[] = [];
  const getCompanyGrpList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/groups");
    } catch (error) {
      alerts.showError(error);
    }

    if (data) {
      data.map((group: CompanyGroup) => {
        let d: SelectType = {
          label: group.name,
          value: group.companyGroupID.toString(),
          disabled: false,
        };
        groupList.push(d);
      });
      setGroupData(groupList);
    }
  };

  //get currency
  let list: SelectType[] = [];
  const getCurrencies = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/currencies");
    } catch (error) {}

    if (data) {
      setCurrencyList(data);
      data.map((currency: Currency) => {
        let d: SelectType = {
          label: currency.name,
          value: currency.systemCurrencyID.toString(),
          disabled: false,
        };

        list.push(d);
      });
      setCurrencyData(list);
    }
  };

  useEffect(() => {
    if (companyID) {
      id = parseInt(companyID);
      setIsPopup(false);
    } else {
      setIsPopup(true);
    }

    getCompanyGrpList();
    getCurrencies();
    getCompanyById();
  }, []);

  return (
    <Box className={!isPopup ? "fade-in-block width33" : ""}>
      <form
        onSubmit={form.onSubmit((values) => {
          values.companyGroupID = parseInt(groupId);
          values.currencyId = parseInt(currencyId);
          values.disabled = disabled;
          onSubmitHandler(values);
        })}
      >
        <TextInput
          withAsterisk
          label="Name"
          placeholder="Name of company"
          {...form.getInputProps("name")}
        />
        <Select
          label="Company Group"
          withAsterisk
          placeholder="Company Group"
          data={groupData}
          allowDeselect={false}
          value={groupId}
          clearable
          searchable
          nothingFoundMessage="Nothing found..."
          onChange={(val) => {
            val !== null ? setGroupId(val) : setGroupId("");
          }}
        />
        <Select
          label="Currency"
          withAsterisk
          placeholder="Currency"
          data={currencyData}
          allowDeselect={false}
          value={currencyId}
          clearable
          searchable
          nothingFoundMessage="Nothing found..."
          onChange={(val) => {
            val !== null ? setCurrencyId(val) : setCurrencyId("");
          }}
        />
        <Switch
          defaultChecked
          style={{ paddingTop: "10px" }}
          disabled={!canBeDisabled}
          label="Disabled"
          checked={disabled}
          onChange={(event) => setDisabled(event.currentTarget.checked)}
        />
        <Group justify="flex-end" mt="md">
          <Button
            onClick={(event) => {
              if (isPopup) {
                closeAllModals();
              } else {
                event.preventDefault();
                navigate("/Admin/Company/List");
              }
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Update</Button>
        </Group>
      </form>
    </Box>
  );
}
