import { NOTIFICATION_TYPE, Store } from "react-notifications-component";
import IdToken from "../types/idToken";
import { jwtDecode } from "jwt-decode";

const applicationStorage = localStorage;
const addToStorage = (name: string, value: string) => {
  applicationStorage.setItem(name, value);
};

const removeFromStorage = (name: string) => {
  applicationStorage.removeItem(name);
};

const getValueFromStorage = (name: string): string => {
  return applicationStorage.getItem(name) as string;
};

const getClientID = (): string => {
  return process.env.CLIENT_ID as string;
};

const isValidLogin = () => {
  //return true if a userCredential token is present in local storage
  const token = getValueFromStorage("userCredential");
  if (token) {
    return true;
  } else {  
    return false;
  }
};

const isTokenExpired = () => {
  const token = getValueFromStorage("userCredential");
  let isExpired = true;
  if (isValidLogin()) {
    const idToken: IdToken = jwtDecode(token);
    if (idToken && idToken.exp < Date.now() / 1000) {
      isExpired = true;
    } else {
      isExpired = false;
    }
  };
  return isExpired;
}

const showNotification = (
  title: string,
  msg: string,
  type: NOTIFICATION_TYPE
) => {
  Store.addNotification({
    title: title,
    message: msg,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 10000,
      onScreen: false,
      showIcon: true,
      click: false,
    },
  });
};

const Utility = {
  addToStorage,
  removeFromStorage,
  getValueFromStorage,
  getClientID,
  showNotification,
  isValidLogin,
  isTokenExpired,
};

export default Utility; 
