import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Group,
  NativeSelect,
  Select,
  TextInput,
} from "@mantine/core";
import { useSetState } from "@mantine/hooks";
import OptionItem from "../../../types/common/optionItem";
import Currency from "../../../types/company/currenty";
import alerts from "../../common/alerts";

type SelectType = {
  value: string;
  label: string;
  disabled: boolean;
};

export default function BankAccountFormAdd() {
  const navigate = useNavigate();
  const form = useForm();
  const getBankAccountForCreation = async () => {
    try {
      let data = await commonApi.getApiCall("/banks/accounts/new");
      form.setValues(data);
    } catch (error) {}
  };

  const [currencyOptions, setCurrencyOptions] = useState<OptionItem[]>([]);
  const getCurrencyOption = async () => {
    let data = null;
    try {
      commonApi.setPageNumber(1);
      commonApi.setPageSize(100);
      data = await commonApi.getApiCall("/currencies");
    } catch (error) {}

    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setCurrencyOptions(data2);
    }
  };

  const [bankOptions, setBankOptions] = useState<OptionItem[]>([]);
  const getBankOptions = async () => {
    let data = null;
    try {
      commonApi.setPageNumber(1);
      commonApi.setPageSize(100);
      data = await commonApi.getApiCall("/banks");
    } catch (error) {}

    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setBankOptions(data2);
    }
  };

  const [accountOptions, setAccountOptions] = useState<OptionItem[]>([]);
  const getAccountOptions = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/accounts");
    } catch (error) {}

    if (data) {
      let data2 = data.map(
        (s: { dropDownName: string; dropDownValue: string }) => ({
          label: s.dropDownName,
          value: s.dropDownValue,
        })
      );
      data2.splice(0, 0, { label: "-- Select --", value: "0" });
      setAccountOptions(data2);
    }
  };

  useEffect(() => {
    getBankAccountForCreation();
    getCurrencyOption();
    getBankOptions();
    getAccountOptions();
  }, []);

  const onSubmitHandler = async (values: any) => {
    try {
      var data = await commonApi.postApiCall("/banks/accounts", values);
      alerts.showMessage("Bank added");
      navigate("/BankAccount/Edit/" + data.bankAccountID);
    } catch (error) {
      alerts.showError(error);
    }
  };

  return (
    <>
      <form
        onSubmit={form.onSubmit((values) => {
          onSubmitHandler(values);
        })}
      >
        <Box className="fade-in-block width">
          <NativeSelect
            label="Bank"
            withAsterisk
            data={bankOptions}
            {...form.getInputProps("bankID")}
          />
          <NativeSelect
            label="Account"
            withAsterisk
            data={accountOptions}
            {...form.getInputProps("accountID")}
          />
          <NativeSelect
            label="Currency"
            withAsterisk
            data={currencyOptions}
            {...form.getInputProps("currencyID")}
          />
          <TextInput
            withAsterisk
            label="Bank Account Name"
            placeholder="Enter Bank Account Name"
            {...form.getInputProps("name")}
          />
          <TextInput
            withAsterisk
            label="Bank Account Number"
            placeholder="Enter Bank Account Number"
            {...form.getInputProps("bankAccountNumber")}
          />
          <TextInput
            withAsterisk
            label="Bank Account BSB"
            placeholder="Enter Bank Account BSB"
            {...form.getInputProps("bankAccountBsb")}
          />
          <Group justify="flex-end" mt="md">
            <Button
              onClick={(event) => {
                navigate("/BankAccount/List");
              }}
            >
              Back
            </Button>
            <Button type="submit">Add</Button>
          </Group>
        </Box>
      </form>
    </>
  );
}
