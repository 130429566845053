import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useState, useEffect } from "react";
import commonApi from "../../../api/commonApi";
import PostingPeriod from "../../../types/generalLedger/postingPeriod";
import PaginationSection from "../../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../../common/Sections/Pagination/PaginationSummary";
import PostingPeriodSearch from "./postingPeriodSearch";
import { ActionIcon, Box, Group } from "@mantine/core";
import { IconEdit, IconMoodConfuzed } from "@tabler/icons-react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { openModal } from "@mantine/modals";
import PostingPeriodFormEdit from "./postingPeriodFormEdit";
import dayjs from "dayjs";

export default function PostingPeriodList() {
  const navigate = useNavigate();
  const [postingPeriods, setPostingPeriods] = useState<PostingPeriod[]>([]);
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [params, setParams] = useState({});
  const [totalRecords, setTotalRecords] = useState(1);
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<PostingPeriod>
  >({
    columnAccessor: "",
    direction: "asc",
  });
  const getPostingPeriodList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/posting/periods?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      setTotalRecords(data.length);
      setPostingPeriods(data);
    }
  };

  useEffect(() => {
    getPostingPeriodList();
  }, [sortStatus, page, pageSize, params]);

  function showModal(postingPeriodID: number) {
    openModal({
      modalId: "edit",
      title: "Showing posting period type information",
      children: (
        <BrowserRouter>
          <PostingPeriodFormEdit id={postingPeriodID.toString()} />
        </BrowserRouter>
      ),
    });
  }

  return (
    <div className="fade-in-block width100">
      <PostingPeriodSearch setParamsFunction={setParams} />
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        records={postingPeriods}
        idAccessor="PostingPeriodID"
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        columns={[
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "startDate",
            title: "Period Start",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: ({ startDate }) => dayjs(startDate).format("MMM DD YYYY"),
          },
          {
            accessor: "endDate",
            title: "Period End",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: ({ endDate }) => dayjs(endDate).format("MMM DD YYYY"),
          },
          {
            accessor: "postingYearName",
            title: "Posting Year",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "status",
            title: "Status",
            textAlign: "center",
            sortable: true,
            width: "5%",
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (postingPeriod) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showModal(postingPeriod.postingPeriodID);
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/PostingPeriod/Edit/" + record.postingPeriodID);
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
