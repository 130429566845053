import { Container } from "@mantine/core";
import commonApi from "../../../../api/commonApi";

type PaginationSectionProps = {
  page: number;
  pageSize: number;
  recordCount: number;
};

const PaginationSummary = ({
  page,
  pageSize,
  recordCount,
}: PaginationSectionProps) => {
  const totalRecords = recordCount === 0 ? 0 : commonApi.getRecordCount();
  const from = (page - 1) * pageSize + 1;
  const to = pageSize * page;

  return (
    <Container mt="lg" fluid px="0">
      <span>{`Showing ${from} to ${
        to > totalRecords ? totalRecords : to
      } of ${totalRecords} entries`}</span>
    </Container>
  );
};
export default PaginationSummary;
