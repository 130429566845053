import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Account from "../../types/generalLedger/account";
import { useForm } from "@mantine/form";
import commonApi from "../../api/commonApi";
import alerts from "../common/alerts";
import {
  Box,
  Button,
  Group,
  NumberInput,
  Select,
  TextInput,
} from "@mantine/core";
import RateDetail from "../../types/tax/rateDetail";

type SelectType = {
  value: string;
  label: string;
};

export default function RateDetailAdd() {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [accountSelectData, setAccountSelectData] = useState<SelectType[]>([]);
  const [accountId, setAccountId] = useState("");

  const getAccountsList = async () => {
    let data = null;
    let accountList: SelectType[] = [];
    try {
      await commonApi.getApiCall("/accounts").then((response) => {
        data = response;
        if (data) {
          data.map((account: Account) => {
            let a: SelectType = {
              label: account.name,
              value: account.accountID.toString(),
            };
            accountList.push(a);
          });
          setAccountSelectData(accountList);
          setAccounts(data);
        }
      });
    } catch (error) {
      alerts.showError(error);
    }
  };

  const form = useForm({
    initialValues: {
      accountID: 0,
      name: "",
      ratePercentage: 0.0,
      rateDetailID: 0,
    },
    validate: {
      name: (value) =>
        value.length < 2 ? "Name must have at least 2 characters" : null,
      accountID: (value) => (value === 0 ? "Please select account" : null),
      ratePercentage: (value) =>
        value === 0 ? "Please type amount greater than 0" : null,
    },
  });

  const onSubmitHandler = async (values: any) => {
    let rateDetail: RateDetail = {
      name: values.name,
      ratePercentage: values.ratePercentage,
      accountID: values.accountID,
    };

    try {
      await commonApi.postApiCall("ratedetails", rateDetail).then((resp) => {
        navigate("/Tax/RateDetail/List");
      });
    } catch (error) {
      alerts.showError(error);
    }
  };

  useEffect(() => {
    getAccountsList();
  }, []);

  return (
    <Box className="fade-in-block width33">
      <form
        onSubmit={form.onSubmit((values) => {
          onSubmitHandler(values);
        })}
      >
        <Select
          label="Account"
          placeholder="account"
          data={accountSelectData}
          allowDeselect={true}
          withAsterisk
          clearable
          searchable
          nothingFoundMessage="Nothing found..."
          {...form.getInputProps("accountID")}
        />
        <TextInput
          withAsterisk
          label="Name"
          placeholder="name of rate detail"
          {...form.getInputProps("name")}
        />
        <NumberInput
          label="Rate Percentage"
          placeholder="rate percentage"
          allowNegative={false}
          decimalScale={2}
          {...form.getInputProps("ratePercentage")}
        />
        <Group justify="flex-end" mt="md">
          <Button
            onClick={(event) => {
              event.preventDefault();
              navigate("/Tax/RateDetail/List");
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </Box>
  );
}
