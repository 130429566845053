import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import commonApi from "../../api/commonApi";
import PaginationSection from "../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../common/Sections/Pagination/PaginationSummary";
import CompanyUserSearch from "./companyUserSearch";
import { Group, Button, Box, ActionIcon, Text } from "@mantine/core";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { IconEdit, IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import { modals, openModal } from "@mantine/modals";
import CompanyUserFormEdit from "./companyUserFormEdit";
import CompanyUser from "../../types/company/companyUser";
import extensionMethods from "../../common/extensionMethods";
import alerts from "../common/alerts";

export default function CompanyUserList() {
  const navigate = useNavigate();
  const [companyUsers, setCompanyUsers] = useState<CompanyUser[]>([]);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [totalRecords, setTotalRecords] = useState(1);
  const [params, setParams] = useState({});
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<CompanyUser>
  >({
    columnAccessor: "",
    direction: "asc",
  });
  const [selectedRecords, setSelectedRecords] = useState<CompanyUser[]>([]);

  const showModal = ({
    companyUser,
    action,
    setState,
  }: {
    companyUser: CompanyUser;
    action: "edit" | "delete";
    setState?: Dispatch<SetStateAction<boolean>>;
  }) => {
    let buttonText = "Close";
    switch (action) {
      case "edit":
        buttonText = "Update";
        break;
      case "delete":
        buttonText = "Delete";
        break;
    }
    openModal({
      modalId: action,
      title: action === "edit" ? "Edit user information" : "Deleting company",
      children: (
        <BrowserRouter>
          {action === "edit" ? (
            <CompanyUserFormEdit
              companyUserId={companyUser.companyUserID}
              setState={setState}
            />
          ) : (
            "Additional company information"
          )}
        </BrowserRouter>
      ),
    });
  };

  const openDeleteModal = (
    companyUser?: CompanyUser,
    selectedUsers?: CompanyUser[]
  ) => {
    modals.openConfirmModal({
      title: "Delete user",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this user/s.</Text>
      ),
      labels: { confirm: "Delete user", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        if (companyUser) {
          if (companyUser.companyUserID) {
            try {
              await commonApi
                .deleteApiCall("/companies/users/" + companyUser.companyUserID)
                .then((response) => {
                  alerts.showMessage("Company user delete success");
                  getCompanyUserList();
                });
            } catch (error) {
              alerts.showError(error);
            }
          }
        }

        if (selectedUsers) {
          let selectedCount = selectedUsers.length;
          let counter = 0;
          selectedUsers.forEach(async (user) => {
            if (user.companyUserID) {
              try {
                await commonApi
                  .deleteApiCall("/companies/users/" + user.companyUserID)
                  .then((response) => {
                    counter++;
                  });
              } catch (error) {
                alerts.showError(error);
              }

              if (counter === selectedCount) {
                alerts.showMessage("Company user delete success");
                setSelectedRecords([]);
                getCompanyUserList();
              }
            }
          });
        }
      },
    });
  };

  const getCompanyUserList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/companies/userviews?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}

    if (data) {
      setTotalRecords(data.length);
      setCompanyUsers(data);
    }
  };

  useEffect(() => {
    getCompanyUserList();
  }, [isSuccessUpdate, sortStatus, page, pageSize, params]);

  return (
    <div>
      <CompanyUserSearch setParamsFunction={setParams} />
      <Group mt="md" justify="flex-end">
        <Button
          onClick={() => {
            navigate("/Company/Users/Add");
          }}
        >
          Add
        </Button>
        <Button
          onClick={() => {
            if (selectedRecords.length > 0) {
              openDeleteModal(undefined, selectedRecords);
            } else {
              alerts.showClientError({
                message: "No selected record to delete",
              });
            }
          }}
        >
          Delete
        </Button>
      </Group>
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        pinLastColumn
        idAccessor="companyUserID"
        records={companyUsers}
        isRecordSelectable={(record) => record.canBeDeleted === true}
        columns={[
          {
            accessor: "userName",
            title: "Username",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "email",
            title: "Email",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "companyName",
            title: "Company Name",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "createdByUserName",
            title: "Created By",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "createdDate",
            title: "Created Date",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: ({ createdDate }) =>
              extensionMethods.dateDisplay(createdDate),
          },
          {
            accessor: "updatedDate",
            title: "Updated Date",
            textAlign: "left",
            sortable: true,
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
            render: ({ updatedDate }) =>
              extensionMethods.dateDisplay(updatedDate),
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (companyUser) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showModal({
                      companyUser,
                      action: "edit",
                      setState: setIsSuccessUpdate,
                    });
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  disabled={!companyUser.canBeDeleted}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    openDeleteModal(companyUser);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/Company/Users/Edit/" + record.companyUserID);
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        selectedRecords={selectedRecords}
        onSelectedRecordsChange={setSelectedRecords}
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
