import {
  Box,
  TextInput,
  Switch,
  Group,
  Button,
  Checkbox,
  Fieldset,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { closeAllModals } from "@mantine/modals";
import CompanyUser from "../../types/company/companyUser";
import { useListState } from "@mantine/hooks";
import Role from "../../types/user/role";
import CompanyRoleUser from "../../types/company/companyRoleUser";
import alerts from "../common/alerts";
import commonApi from "../../api/commonApi";

export default function CompanyUserFormEdit({
  companyUserId,
  setState,
}: {
  companyUserId?: number;
  setState?: Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { userID } = useParams();
  const [user, setUser] = useState<CompanyUser>({} as CompanyUser);
  const [disabled, setDisabled] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [roleData, setRoleData] = useState<Role[]>([]);
  const [roleValues, handlers] = useListState<Role>([]);
  const [canBeDisabled, setCanBeDisabled] = useState(false);
  const [selectedRoles, setSeletedRoles] = useState<CompanyRoleUser[]>([]);

  if (companyUserId === undefined) {
    companyUserId = location.state ? location.state.companyUserId : 0;
  }

  const form = useForm({
    initialValues: {
      username: "",
      userId: 0,
      email: "",
      phone: "",
      disabled: false,
      companyUserId: parseInt(userID as string),
      companyID: 0,
    },
    validate: {
      username: (value) =>
        value.length < 2 ? "Name must have at least 2 characters" : null,
      email: (value) =>
        value.length < 2
          ? "Email address must have at least 2 characters"
          : null,
      phone: (value) =>
        value.length < 2
          ? "Phone number must have at least 2 characters"
          : null,
    },
  });

  const onSubmitHandler = async (values: any) => {
    try {
      let companyUser: CompanyUser = {
        companyUserID: values.companyUserId,
        companyID: values.companyID,
        userID: values.userId,
        default: true,
        email: values.email,
        userName: values.username,
        phone: values.phone,
        disabled: disabled,
        roleUser: selectedRoles,
      };

      if (selectedRoles.length > 0) {
        await commonApi
          .putApiCall(
            "/companies/users/" + companyUser.companyUserID,
            companyUser
          )
          .then((resp) => {
            if (resp === "") {
              if (isPopup) {
                if (setState) {
                  setState(true);
                  closeAllModals();
                }
              } else {
                navigate("/User/List");
              }
            }
          });
      } else {
        alerts.showClientError({
          message: "Please select atleast 1 role for this user",
        });
      }
    } catch (error) {
      alerts.showError(error);
    }
  };

  const getCompanyUserById = async () => {
    let data = null;
    try {
      if (companyUserId)
        data = await commonApi.getApiCall("/companies/users/" + companyUserId);
      if (data) {
        setUser(data);
        setCanBeDisabled(data.canBeDisabled);
        setDisabled(data.disabled);
      }

      data.roleUser.map((item: any) => {
        const roleItem: CompanyRoleUser = {
          roleID: item.roleID,
        };
        selectedRoles.push(roleItem);
      });

      form.setValues({
        userId: data.userID,
        username: data.userName,
        disabled: data.disabled,
        phone: data.phone,
        email: data.email,
        companyID: data.companyID,
        companyUserId: data.companyUserID,
      });
    } catch (error) {}
  };

  const getRoleList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/users/roles");
    } catch (error) {}

    if (data) {
      setRoleData(data);
      let chekboxItems: Role[] = [];
      data.map((item: Role) => {
        let chkbox: Role = {
          checked: false,
          roleID: item.roleID,
          name: item.name,
        };
        chekboxItems.push(chkbox);
      });

      handlers.setState(chekboxItems);
    }
  };

  useEffect(() => {
    if (userID) {
      companyUserId = parseInt(userID);
      setIsPopup(false);
    } else {
      setIsPopup(true);
    }

    getCompanyUserById();
    getRoleList();
  }, []);

  const roleItems = roleValues.map((value, index) => {
    const currentUserRole = selectedRoles.find(
      (item) => item.roleID === value.roleID
    );
    if (currentUserRole) value.checked = true;

    return (
      <Checkbox
        mt="xs"
        ml={33}
        checked={value.checked}
        label={value.name}
        key={value.roleID}
        onChange={(event) => {
          handlers.setItemProp(index, "checked", event.currentTarget.checked);
          let roleIndex = selectedRoles.findIndex(
            (i) => i.roleID === value.roleID
          );

          if (event.currentTarget.checked) {
            const role: CompanyRoleUser = {
              roleID: value.roleID ? value.roleID : 0,
            };

            if (roleIndex < 0) {
              selectedRoles.push(role);
              setSeletedRoles(selectedRoles);
            }
          } else {
            const newSelectedRole = selectedRoles.filter(
              (x) => x.roleID != value.roleID
            );
            setSeletedRoles(newSelectedRole);
          }
        }}
      />
    );
  });

  return (
    <Box className={!isPopup ? "fade-in-block width33" : ""}>
      <form
        onSubmit={form.onSubmit((values) => {
          values.disabled = disabled;
          onSubmitHandler(values);
        })}
      >
        <TextInput
          withAsterisk
          label="Name"
          placeholder="name of user "
          {...form.getInputProps("username")}
        />
        <TextInput
          withAsterisk
          label="Email"
          placeholder="email address"
          {...form.getInputProps("email")}
        />
        <TextInput
          withAsterisk
          label="Phone"
          placeholder="phone number"
          {...form.getInputProps("phone")}
        />
        <Switch
          checked={disabled}
          disabled={!canBeDisabled}
          label="Disabled"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
          onChange={(event) => setDisabled(event.currentTarget.checked)}
        />
        <Fieldset legend="User Roles"> {roleItems}</Fieldset>

        <Group justify="flex-end" mt="md">
          <Button
            onClick={(event) => {
              if (isPopup) {
                closeAllModals();
              } else {
                event.preventDefault();
                navigate("/User/List");
              }
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Update</Button>
        </Group>
      </form>
    </Box>
  );
}
