import { SimpleGrid } from "@mantine/core";
// import GetNativeSelector from "./helpers/GetNativeSelector";
import CollapsibleWithActionButton from "./CollapsibleWithActionButton";
import styles from "./Search.module.css";
import ButtonGroup from "../../ButtonGroup";

type SearchProps = {
  searchLabel?: string;
  children: React.ReactNode;
  buttonSection: React.ReactNode;
};

export default function SearchSection({
  searchLabel = "Search Item",
  children,
  buttonSection,
}: SearchProps) {
  return (
    <CollapsibleWithActionButton
      closeCollapsibleLabel={"Close Search"}
      openCollapsibleLabel={searchLabel}
    >
      <SimpleGrid cols={4} classNames={{ root: styles.searchForm }}>
        {children}
      </SimpleGrid>
      {/* <ButtonGroup
        cancelLabel="Reset"
        cancelClick={form.reset}
        okLabel="Search"
      /> */}
      {buttonSection}
    </CollapsibleWithActionButton>
  );
}
