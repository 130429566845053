import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BrowserRouter, useNavigate, useParams } from "react-router-dom";
import commonApi from "../../api/commonApi";
import { useForm } from "@mantine/form";
import { Box, TextInput, NumberInput, NativeSelect, Button, Group, Text, ActionIcon } from "@mantine/core";
import alerts from "../common/alerts";
import { closeAllModals, modals, openModal } from "@mantine/modals";
import JournalFormEdit from "./journalFormEdit";
import OptionItem from "../../types/common/optionItem";
import extensionMethods from "../../common/extensionMethods";

export default function JournalDetailFormEdit({
    id,
    setState
  }: {
    id?: string;
    setState?: Dispatch<SetStateAction<boolean>>;
  })
  {
    const navigate = useNavigate();
    const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);  
    const form = useForm({
      initialValues: {
        journalDetailID: 0,
        journalID: 0,
        name: "",
        creditSourceValue: 0,
        debitSourceValue: 0,
        accountID: 0,
        canUpdate: false
      }        
    });  
    const {journalDetailID} = useParams();
    let isPopup: boolean = true;
    if (id == null){
      isPopup = false;
      id = journalDetailID;
    } 
    const getJournalDetailById = async () => {
      try {
        let data = await commonApi.getApiCall("/journal/details/" + id);
        form.setValues({
          journalDetailID: data.journalDetailID,
          journalID: data.journalID,
          name: data.name,
          creditSourceValue: data.creditSourceValue,
          debitSourceValue: data.debitSourceValue,
          accountID: data.accountID,
          canUpdate: data.canUpdate});
      } catch (error) {}
    };    
    const [accounts, setAccounts] = useState<OptionItem[]>([]);
    const getAccountList = async () => {
      let data = null;
      try {
        data = await commonApi.getApiCall("/accounts");
      } catch (error) {}
      if (data) {
        let data2: OptionItem[] = data.map((s: { name: string; accountNumber: string, accountID: string; }) => ({label: s.accountNumber + " " + s.name, value: s.accountID}));
        setAccounts(data2);               
      }
    };
    useEffect(() => {      
        getJournalDetailById();
        getAccountList();
    }, 
    []);    
    const onSubmitHandler = async (values: any) => {
      try {
        await commonApi.putApiCall("/journal/details/" + id, values).then(() => {
          alerts.showMessage("Journal detail updated");
          if (isPopup) {
            if (setState){
              setState(true);
            }
            openModal({
                modalId: "edit",
                title:"Showing journal detail information",
                size:"100vw",
                children: (
                  <BrowserRouter>
                    <JournalFormEdit id={values.journalID.toString()} setState={setState} />
                  </BrowserRouter>
                ),
              });
          } 
          else {
            navigate("/Journal/Edit/" + values.journalID);
          }
        });
      } 
      catch (error) {
        alerts.showError(error);
      }
    };    
    return (
      <>
        <form
          onSubmit={form.onSubmit((values) => {
            onSubmitHandler(values);
          })}>
            <Box>
                <TextInput
                  withAsterisk
                  label="Name"
                  placeholder="Description of journal line"
                  {...form.getInputProps("name")}/>
                <NativeSelect 
                  withAsterisk
                  label="Account" 
                  data={accounts}
                  {...form.getInputProps("accountID")}/>
                <NumberInput
                  withAsterisk
                  label="Credit Value"
                  placeholder="Credit value of journal line"
                  allowDecimal={true}
                  decimalScale={2}
                  fixedDecimalScale
                  {...form.getInputProps("creditSourceValue")}/>
                <NumberInput
                  withAsterisk
                  label="Debit Value"
                  placeholder="Debit value of journal line"
                  allowDecimal={true}
                  decimalScale={2}
                  fixedDecimalScale
                  {...form.getInputProps("debitSourceValue")}/>
                <Group justify="flex-end" mt="md">
                    <Button
                        onClick={(event) => {
                        if (isPopup) {
                          if (setState){
                            setState(true);
                          }
                          openModal({
                            modalId: "edit",
                            title:"Showing journal detail information",
                            size:"100vw",
                            children: (
                              <BrowserRouter>
                                <JournalFormEdit id={form.values.toString()} setState={setState} />
                              </BrowserRouter>
                            ),
                          });
                        } else {
                            event.preventDefault();
                            navigate("/Journal/Edit/" + form.values.journalID);
                        }
                        }}>
                        Back
                    </Button>
                    <Button disabled={form.values.canUpdate ? false : true} type="submit">Update</Button>
                </Group>
            </Box>
        </form>
      </>
    );
  }