import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { Box, Button, Group, Select, Switch, TextInput } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import commonApi from "../../api/commonApi";
import UserView from "../../types/company/UserView";
import alerts from "../common/alerts";
import CompanyGroup from "../../types/company/companyGroup";

type SelectType = {
  value: string;
  label: string;
  disabled: boolean;
};

export default function AdminUserFormEdit({
  id,
  setState,
}: {
  id?: number;
  setState?: Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { userID } = useParams();
  const [disabled, setDisabled] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [canBeDisabled, setCanBeDisabled] = useState(false);
  const [user, setUser] = useState<UserView>({} as UserView);
  const [groupData, setGroupData] = useState<SelectType[]>([]);
  const [groupId, setGroupId] = useState("");

  const form = useForm({
    initialValues: {
      name: "",
      userId: 0,
      email: "",
      phone: "",
      disabled: false,
      companyGroupID: 0,
    },
    validate: {
      name: (value) =>
        value.length < 2 ? "Name must have at least 2 characters" : null,
      email: (value) =>
        value.length < 2
          ? "Email address must have at least 2 characters"
          : null,
      phone: (value) =>
        value.length < 2
          ? "Phone number must have at least 2 characters"
          : null,
      companyGroupID: (value) =>
        value === 0 ? "Please select company group" : null,
    },
  });

  const getUserById = async () => {
    let data = null;
    try {
      if (id) data = await commonApi.getApiCall("/admin/users/" + id);
      if (data) {
        setUser(data);
        setCanBeDisabled(data.canBeDisabled);
        setDisabled(data.disabled);
      }

      form.setValues({
        userId: data.userID,
        name: data.name,
        disabled: data.disabled,
        phone: data.phone,
        email: data.email,
        companyGroupID: data.companyGroupID,
      });
      setGroupId(data.companyGroupID.toString());
    } catch (error) {}
  };

  let groupList: SelectType[] = [];
  const getCompanyGrpList = async () => {
    let data = null;
    try {
      data = await commonApi.getApiCall("/groups");
    } catch (error) {
      alerts.showError(error);
    }

    if (data) {
      data.map((group: CompanyGroup) => {
        let d: SelectType = {
          label: group.name,
          value: group.companyGroupID.toString(),
          disabled: false,
        };
        groupList.push(d);
      });
      setGroupData(groupList);
    }
  };

  const onSubmitHandler = async (values: any) => {
    try {
      let user: UserView = {
        userID: values.userId,
        companyGroupID: values.companyGroupID,
        email: values.email,
        name: values.name,
        phone: values.phone,
        disabled: disabled,
      };

      await commonApi
        .putApiCall("/admin/users/" + user.userID, user)
        .then((resp) => {
          if (resp === "") {
            if (isPopup) {
              if (setState) {
                setState(true);
                closeAllModals();
              }
            } else {
              navigate("/Admin/User/List");
            }
          } else {
            if (resp.response.status !== 200) {
              alerts.showError({
                message: resp.response.data.message,
              });
            }
          }
        });
    } catch (error) {
      alerts.showError(error);
    }
  };

  useEffect(() => {
    if (userID) {
      id = parseInt(userID);
      setIsPopup(false);
    } else {
      setIsPopup(true);
    }
    getCompanyGrpList();
    getUserById();
  }, []);

  return (
    <Box className={!isPopup ? "fade-in-block width33" : ""}>
      <form
        onSubmit={form.onSubmit((values) => {
          values.companyGroupID = parseInt(groupId);
          values.disabled = disabled;
          onSubmitHandler(values);
        })}
      >
        <Select
          label="Company Group"
          withAsterisk
          placeholder="Company Group"
          data={groupData}
          allowDeselect={false}
          clearable
          searchable
          nothingFoundMessage="Nothing found..."
          value={groupId}
          onChange={(val) => {
            val !== null ? setGroupId(val) : setGroupId("");
          }}
        />
        <TextInput
          withAsterisk
          label="Name"
          placeholder="name of user "
          {...form.getInputProps("name")}
        />
        <TextInput
          withAsterisk
          label="Email"
          placeholder="email address"
          {...form.getInputProps("email")}
        />
        <TextInput
          withAsterisk
          label="Phone"
          placeholder="phone number"
          {...form.getInputProps("phone")}
        />
        <Switch
          checked={disabled}
          disabled={!canBeDisabled}
          label="Disabled"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
          onChange={(event) => setDisabled(event.currentTarget.checked)}
        />

        <Group justify="flex-end" mt="md">
          <Button
            onClick={(event) => {
              if (isPopup) {
                closeAllModals();
              } else {
                event.preventDefault();
                navigate("/Admin/User/List");
              }
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Update</Button>
        </Group>
      </form>
    </Box>
  );
}
