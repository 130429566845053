import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import commonApi from "../../../api/commonApi";
import AccountCategoryView from "../../../types/generalLedger/accountCategoryView";
import PaginationSection from "../../common/Sections/Pagination/PaginationSection";
import PaginationSummary from "../../common/Sections/Pagination/PaginationSummary";
import AccountCategorySearch from "./accountCategorySearch";
import { ActionIcon, Box, Group, Text } from "@mantine/core";
import { IconEdit, IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { modals, openModal } from "@mantine/modals";
import AccountCategoryFormEdit from "./accountCategoryFormEdit";
import alerts from "../../common/alerts";

export default function AccountCategoryList() {
  const navigate = useNavigate();
  const [accountCategories, setAccountCategories] = useState<
    AccountCategoryView[]
  >([]);
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [params, setParams] = useState({});
  const [totalRecords, setTotalRecords] = useState(1);
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<AccountCategoryView>
  >({
    columnAccessor: "",
    direction: "asc",
  });
  const getAccountCategoryViewList = async () => {
    let data = null;
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = await commonApi.getApiCall(
        "/accountCategoryViews?" + new URLSearchParams(params).toString()
      );
    } catch (error) {}
    if (data) {
      setTotalRecords(data.length);
      setAccountCategories(data);
    }
  };

  useEffect(() => {
    getAccountCategoryViewList();
  }, [isSuccessUpdate, sortStatus, page, pageSize, params]);

  function showEditModal(
    accountCategoryID: number,
    setState?: Dispatch<SetStateAction<boolean>>
  ) {
    openModal({
      modalId: "edit",
      title: "Account Category Popup",
      children: (
        <BrowserRouter>
          <AccountCategoryFormEdit
            id={accountCategoryID.toString()}
            setState={setState}
          />
        </BrowserRouter>
      ),
    });
  }

  function showDeleteModal(accountCategoryID: number) {
    modals.openConfirmModal({
      title: "Delete account category",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this account category/s.
        </Text>
      ),
      labels: { confirm: "Delete account category", cancel: "Back" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: async () => {
        try {
          await commonApi
            .deleteApiCall("/Account/Categories/" + accountCategoryID)
            .then(() => {
              alerts.showMessage("Account Category deleted");
              getAccountCategoryViewList();
            });
        } catch (error) {
          alerts.showError(error);
        }
      },
    });
  }

  return (
    <div className="fade-in-block width100">
      <AccountCategorySearch setParamsFunction={setParams} />
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        records={accountCategories}
        idAccessor="AccountCategoryID"
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        columns={[
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "accountCategoryTypeName",
            title: "Account Category Type",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "typicalBalanceName",
            title: "Typical Balance",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.lg})`,
          },
          {
            accessor: "postingTypeName",
            title: "Posting Type",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.lg})`,
          },
          {
            accessor: "actions",
            title: "Actions",
            textAlign: "center",
            width: "2%",
            render: (accountCategoryView) => (
              <Group gap={10} justify="space-around" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showEditModal(
                      accountCategoryView.accountCategoryID,
                      setIsSuccessUpdate
                    );
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    showDeleteModal(accountCategoryView.accountCategoryID);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/Account/Category/Edit/" + record.accountCategoryID);
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
