import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import commonApi from "../../api/commonApi";
import { purchaseOrder } from "../../types/purchasing/purchaseOrder";
import PaginationSection from "../common/Sections/Pagination/PaginationSection";
import { ActionIcon, Box, Group } from "@mantine/core";
import { IconEdit, IconMoodConfuzed, IconTrash } from "@tabler/icons-react";
import PaginationSummary from "../common/Sections/Pagination/PaginationSummary";

export default function PurchaseOrderList() {
  const navigate = useNavigate();
  const [purchaseOrderList, setPurchaseOrderList] = useState<purchaseOrder[]>(
    []
  );
  const [page, setPage] = useState(commonApi.getPageNumber);
  const [pageSize, setPageSize] = useState(commonApi.getPageSize);
  const [params, setParams] = useState({});
  const [totalRecords, setTotalRecords] = useState(1);
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<purchaseOrder>
  >({
    columnAccessor: "",
    direction: "asc",
  });

  const getPurchaseOrderList = async () => {
    let data: any[] = [];
    try {
      commonApi.setColumnName(sortStatus.columnAccessor);
      commonApi.setSortDirection(sortStatus.direction);
      commonApi.setPageNumber(page);
      commonApi.setPageSize(pageSize);
      data = [];
    } catch (error) {}
    if (data) {
      setTotalRecords(data.length);
      setPurchaseOrderList(data);
    }
  };

  useEffect(() => {
    getPurchaseOrderList();
  }, [sortStatus, page, pageSize, params]);

  return (
    <div className="fade-in-block width100">
      {/* <CreditorSearch setParamsFunction={setParams} /> */}
      <PaginationSection
        page={page}
        setPageFunction={setPage}
        pageSize={pageSize}
        setPageSizeFunction={setPageSize}
        recordCount={totalRecords}
      />
      <DataTable
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        textSelectionDisabled
        records={purchaseOrderList}
        idAccessor="creditorID"
        minHeight={150}
        noRecordsIcon={
          <Box p={4} mb={4}>
            <IconMoodConfuzed size={36} strokeWidth={1.5} />
          </Box>
        }
        noRecordsText="No records found"
        columns={[
          {
            accessor: "actions",
            title: "",
            textAlign: "right",
            width: "1%",
            render: (creditor) => (
              <Group gap={4} justify="right" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="blue"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    // showEditModal(bank.bankID, setIsSuccessUpdate);
                  }}
                >
                  <IconEdit size={16} />
                </ActionIcon>
              </Group>
            ),
          },
          {
            accessor: "poNumber",
            title: "Purchase Order Number",
            textAlign: "left",
            sortable: true,
          },
          {
            accessor: "name",
            title: "Name",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "documentType",
            title: "Document Type",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "date",
            title: "Date",
            textAlign: "left",
            sortable: true,
            width: "15%",
            visibleMediaQuery: (theme) =>
              `(min-width: ${theme.breakpoints.md})`,
          },
          {
            accessor: "actions",
            title: "",
            textAlign: "right",
            width: "1%",
            render: (creditor) => (
              <Group gap={4} justify="right" wrap="nowrap">
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="red"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    // showDeleteModal(bank.bankID);
                  }}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        onRowClick={({ record, index, event }) => {
          navigate("/Finance/Creditor/Edit/" + record.creditorID);
        }}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      />
      <PaginationSummary
        page={page}
        pageSize={pageSize}
        recordCount={totalRecords}
      />
    </div>
  );
}
