import { useEffect, useState } from "react";
import commonApi from "../../../api/commonApi";
import { Button, Group, TextInput } from "@mantine/core";
import { useNavigate } from "react-router-dom";

export default function AccountCategoryInformation(props: any){
    const navigate = useNavigate();    
    const [accountCategoryID, setAccountCategoryID] = useState(0);
    const [accountCategory, setAccountCategory] = useState<any>({} as any);
    const getAccountCategory = async () => {
        try {
            if (props.accountCategoryID != 0){
                let data = await commonApi.getApiCall("/Account/Categories/" + props.accountCategoryID);
                setAccountCategory(data);
                setAccountCategoryID(props.accountCategoryID);      
                if (props.showInput === true){
                    props.form.setValues({
                        accountNumber1: data.nextAccountNumber1,
                        accountNumber2: data.nextAccountNumber2,
                        accountNumber3: data.nextAccountNumber3,
                        name: "New " + data.name + " " + data.accountCategoryType.name + " account"
                    });  
                }                        
            }
            else {
                setAccountCategoryID(0);
            }
        } catch (error) {}
      };  
    useEffect(() => {      
        getAccountCategory();
    }, 
    [props.accountCategoryID]);       
    if (accountCategoryID === 0){ 
        return <div className="information">waiting for account category..</div>
    }
    else{
        let output = (
            <div className="information">
                <b>{accountCategory.name}</b> belongs to the <b>{accountCategory.accountCategoryType.name}</b> account category type.
                <br/>{accountCategory.accountCategoryType.detail}
                <br/>{accountCategory.accountCategoryType.name} are a <b>{accountCategory.accountCategoryType.typicalBalance.name}</b> account category type so debits will <b>{accountCategory.accountCategoryType.typicalBalance.debitMultiplier == 1 ? "increase": "decrease"}</b> the value and credits will <b>{accountCategory.accountCategoryType.typicalBalance.creditMultiplier == 1 ? "increase": "decrease"}</b> the value.
                <br/>{accountCategory.name} {accountCategory.accountCategoryType.name} are also a <b>{accountCategory.accountCategoryType.postingType.name}</b> account.                
                <br/>The suggested account number range for <b>{accountCategory.accountCategoryType.name}</b> is between {accountCategory.accountCategoryType.startRange}-{accountCategory.nextAccountNumber2}-{accountCategory.nextAccountNumber3} and {accountCategory.accountCategoryType.endRange}-{accountCategory.nextAccountNumber2}-{accountCategory.nextAccountNumber3}.
            </div>              
        );
        if (props.showInput === true){
            return (
                <>
                    {output}
                    <TextInput
                        withAsterisk
                        label="Account Number 1"
                        placeholder="First part of account number"
                        {...props.form.getInputProps("accountNumber1")}/>     
                    <TextInput
                        withAsterisk
                        label="Account Number 2"
                        placeholder="Second part of account number"
                        {...props.form.getInputProps("accountNumber2")}/>                     
                    <TextInput
                        withAsterisk
                        label="Account Number 3"
                        placeholder="Third part of account number"
                        {...props.form.getInputProps("accountNumber3")}/>      
                    <TextInput
                        withAsterisk
                        label="Name"
                        placeholder="Name of Account"
                        {...props.form.getInputProps("name")}/>         
                    <Group justify="flex-end" mt="md">
                        <Button
                            onClick={(event) => {
                                navigate("/Account/List")
                            }}>
                            Back
                        </Button>
                        <Button type="submit">Add</Button>
                    </Group>  
                </>
            );
        }
        return (
            <>
                {output}        
            </>
        );
    }
}