import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import { Box, Group, Button } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import LedgerView from "../../../types/generalLedger/ledgerView";
import Label from "../../common/label";
import ReadOnly from "../../common/readOnly";
import extensionMethods from "../../../common/extensionMethods";
import { IconMoodConfuzed } from "@tabler/icons-react";
import { DataTable } from "mantine-datatable";

export default function LedgerFormView({ id }: { id?: string }) {
  const navigate = useNavigate();
  const { ledgerID } = useParams();
  let isPopup: boolean = true;
  if (id == null) {
    isPopup = false;
    id = ledgerID;
  }
  const [ledgerView, setLedgerView] = useState<LedgerView>({} as LedgerView);
  const getLedgerViewById = async () => {
    try {
      let data = await commonApi.getApiCall("/ledgerViews/" + id);
      setLedgerView(data);
    } catch (error) {}
  };
  useEffect(() => {
    getLedgerViewById();
  }, []);
  return (
    <>
      <Box className="fade-in-block width33">
        <h3>General Ledger Header</h3>
        <Label name="Sub Ledger Type" />
        <ReadOnly
          value={
            <NavLink
              to={
                ledgerView.subLedgerTypeUrl +
                "/" +
                ledgerView.subLedgerTypeSourceID
              }
            >
              {ledgerView.subLedgerTypeName +
                " #" +
                ledgerView.subLedgerTypeSourceID}
            </NavLink>
          }
        />
        <Label name="Sub Ledger Name" />
        <ReadOnly value={ledgerView.subLedgerName} />
        <Label name="Exchange Rate" />
        <ReadOnly
          value={
            ledgerView.exchangeRateName +
            " " +
            extensionMethods.decimalDisplay(ledgerView.exchangeRateValue, false)
          }
        />
        <Label name="Date" />
        <ReadOnly
          value={extensionMethods.dateDisplay(ledgerView.subLedgerDate)}
        />
        <Label name="Period" />
        <ReadOnly value={ledgerView.postingPeriodName} />
        <Label name="Year" />
        <ReadOnly value={ledgerView.postingYearName} />
        <Label name="Company Name" />
        <ReadOnly value={ledgerView.companyName} />
      </Box>
      <Box className="fade-in-block width66">
        <h3>General Ledger Lines</h3>
        <Label
          name={
            "These are the general ledger lines sourced from the posted " +
            ledgerView.subLedgerTypeName
          }
        />
        <DataTable
          withTableBorder
          borderRadius="sm"
          withColumnBorders
          striped
          highlightOnHover
          textSelectionDisabled
          records={ledgerView.ledgerDetailViews}
          idAccessor="ledgerDetailID"
          minHeight={150}
          noRecordsIcon={
            <Box p={4} mb={4}>
              <IconMoodConfuzed size={36} strokeWidth={1.5} />
            </Box>
          }
          noRecordsText="No records found"
          columns={[
            {
              accessor: "name",
              title: "Name",
              textAlign: "left",
              sortable: false,
              width: "25%",
              visibleMediaQuery: (theme) =>
                `(min-width: ${theme.breakpoints.md})`,
            },
            {
              accessor: "accountNumber",
              title: "Account",
              textAlign: "left",
              sortable: false,
              render: (ledgerDetailView) => (
                <NavLink to={"/Account/Edit/" + ledgerDetailView.accountID}>
                  {ledgerDetailView.accountNumber}
                  <div className="smallText">
                    {ledgerDetailView.accountName}
                  </div>
                </NavLink>
              ),
            },
            {
              accessor: "creditSourceValue",
              title: "Source Credit",
              textAlign: "right",
              sortable: false,
              width: "10%",
              render: (ledgerDetailView) =>
                extensionMethods.decimalDisplay(
                  ledgerDetailView.creditSourceValue,
                  true
                ),
              footer:
                extensionMethods.decimalDisplay(
                  ledgerView.creditSourceTotal,
                  false
                ) +
                " " +
                ledgerView.sourceCurrencyCode,
            },
            {
              accessor: "debitSourceValue",
              title: "Source Debit",
              textAlign: "right",
              sortable: false,
              width: "10%",
              render: (ledgerDetailView) =>
                extensionMethods.decimalDisplay(
                  ledgerDetailView.debitSourceValue,
                  true
                ),
              footer:
                extensionMethods.decimalDisplay(
                  ledgerView.debitSourceTotal,
                  false
                ) +
                " " +
                ledgerView.sourceCurrencyCode,
            },
            {
              accessor: "creditTargetValue",
              title: "Target Credit",
              textAlign: "right",
              sortable: false,
              width: "10%",
              visibleMediaQuery: (theme) =>
                `(min-width: ${theme.breakpoints.md})`,
              render: (ledgerDetailView) =>
                extensionMethods.decimalDisplay(
                  ledgerDetailView.creditTargetValue,
                  true
                ),
              footer:
                extensionMethods.decimalDisplay(
                  ledgerView.creditTargetTotal,
                  false
                ) +
                " " +
                ledgerView.targetCurrencyCode,
            },
            {
              accessor: "debitTargetValue",
              title: "Target Debit",
              textAlign: "right",
              sortable: false,
              width: "10%",
              visibleMediaQuery: (theme) =>
                `(min-width: ${theme.breakpoints.md})`,
              render: (ledgerDetailView) =>
                extensionMethods.decimalDisplay(
                  ledgerDetailView.debitTargetValue,
                  true
                ),
              footer:
                extensionMethods.decimalDisplay(
                  ledgerView.debitTargetTotal,
                  false
                ) +
                " " +
                ledgerView.targetCurrencyCode,
            },
          ]}
        />
        <div className="log">
          Created by {ledgerView.createdByName}{" "}
          {extensionMethods.dateTimeDisplay(ledgerView.createdDate)}
        </div>
      </Box>
      <Group justify="flex-end" mt="md">
        <Button
          onClick={(event) => {
            if (isPopup) {
              closeAllModals();
            } else {
              event.preventDefault();
              navigate(
                ledgerView.subLedgerTypeUrl +
                  "/" +
                  ledgerView.subLedgerTypeSourceID
              );
            }
          }}
        >
          Back
        </Button>
      </Group>
    </>
  );
}
