import { useDisclosure } from "@mantine/hooks";
import { AppShell } from "@mantine/core";
import "./App.css";
import Header from "./navigation/header";
import RouteSwitcher from "./navigation/routerSwitcher";
import { useEffect, useState } from "react";
import Utility from "./common/utils";
import { menu } from "./types/navigation/menu";
import Navbar2 from "./navigation/navbar2";
import PageHeader from "./navigation/pageHeader";
import commonApi from "./api/commonApi";
import { closeAllModals } from "@mantine/modals";
import { useAuth } from "./components/user/useAuth";
import Login from "./components/LoginModal";
import { useNavigate } from "react-router-dom";

function App() {
  const [opened, { toggle }] = useDisclosure();
  const navigate = useNavigate();  
  const token = Utility.getValueFromStorage("userCredential");
  const [menus, setMenus] = useState([] as menu[]);
  const [isInvalid, setIsInvalid] = useState(false);
  const { logout } = useAuth();

  const loadMenus = () => {
    commonApi
      .getApiCall("/navigation/menu")
      .then((data) => {
        setMenus(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (!isInvalid) {
      loadMenus();
    }
  }, [isInvalid]);

  useEffect(() => {
    if (isInvalid) {
      logout();
    }
  }, [isInvalid]);

  useEffect(() => {
    if (!token) {
      console.log("Invalid Login");
      setIsInvalid(true);
      //logout();
    } else {
      const intervalId = setInterval(() => {
        if (Utility.isTokenExpired()) {
          closeAllModals();
          console.log("Token Expired");
          setIsInvalid(true);
        }
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [token]);

  const loginReady = () => {
    setIsInvalid(false);
    navigate(window.location);
  };

  return (
    <div className="App" style={{ marginTop: "20px" }}>
      <AppShell
        header={{ height: 60 }}
        navbar={{
          width: 300,
          breakpoint: "sm",
          collapsed: { mobile: !opened },
        }}
        padding="md"
      >
        <Header toggle={toggle} opened={opened} />
        <Navbar2 data={menus} toggleMobileMenu={toggle} />
        <AppShell.Main>
          <PageHeader />
          <RouteSwitcher />
        </AppShell.Main>
        <AppShell.Footer zIndex={opened ? "auto" : 201}>
          <div className="App center">Development Version for Staging</div>
        </AppShell.Footer>
      </AppShell>
      <Login needLogin={isInvalid} loginReady={() => loginReady()} />
    </div>
  );
}

export default App;
