import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import commonApi from "../../../api/commonApi";
import { useForm } from "@mantine/form";
import { Box, TextInput, NativeSelect, Button, Group, Textarea } from "@mantine/core";
import OptionItem from "../../../types/common/optionItem";
import alerts from "../../common/alerts";

export default function AccountCategoryTypeFormAdd()
  {
    const navigate = useNavigate();   
    const [postingTypes, setPostingTypes] = useState<OptionItem[]>([]);
    const [typicalBalances, setTypicalBalances] = useState<OptionItem[]>([]);
    const form = useForm({
        initialValues: {
            accountCategoryTypeID: 0,
            name: "",
            detail: "",
            startRange: 1,
            endRange: 1000,
            typicalBalanceID: 1,
            postingTypeID: 1
        }        
      }); 
      const getPostingTypeList = async () => {
        let data = null;
        try {
          data = await commonApi.getApiCall("/system/postingtypes");
        } catch (error) {}
        if (data) {
          let data2: OptionItem[] = data.map((s: { name: string; systemPostingTypeID: string; }) => ({label: s.name, value: s.systemPostingTypeID}));
          setPostingTypes(data2);               
        }
      };  
      const getTypicalBalanceList = async () => {
        let data = null;
        try {
          data = await commonApi.getApiCall("/system/typicalbalances");
        } catch (error) {}
        if (data) {
          let data2: OptionItem[] = data.map((s: { name: string; systemTypicalBalanceID: string; }) => ({label: s.name, value: s.systemTypicalBalanceID}));
          setTypicalBalances(data2);               
        }
      };   
    useEffect(() => {      
        getPostingTypeList();
        getTypicalBalanceList();
    }, 
    []);    
    const onSubmitHandler = async (values: any) => {
      try {
        await commonApi.postApiCall("/account/category/types", values).then(() => {
            alerts.showMessage("Account Category Type added");
            navigate("/Account/Category/Type/List");
        });
      } 
      catch (error) {
        alerts.showError(error);
      }
    };    
    return (
      <>
        <Box mx="auto">
          <form
            onSubmit={form.onSubmit((values) => {
                onSubmitHandler(values);
            })}>   
            <TextInput
              withAsterisk
              label="Name"
              placeholder="Name of Account Category Type"
              {...form.getInputProps("name")}/>        
            <Textarea
              withAsterisk
              autosize
              minRows={2}
              label="Detail"
              placeholder="Detail to describe the Account Category Type"
              {...form.getInputProps("detail")}/>     
            <TextInput
              withAsterisk
              label="Start of Account Number Range"
              placeholder="Range Start"
              {...form.getInputProps("startRange")}/>   
            <TextInput
              withAsterisk
              label="End of Account Number Range"
              placeholder="Range End"
              {...form.getInputProps("endRange")}/>  
            <NativeSelect 
              withAsterisk
              label="Typical Balance" 
              className="searchFormElement"
              data={typicalBalances}
              {...form.getInputProps("typicalBalanceID")}/>
            <br/>
            <NativeSelect 
              withAsterisk
              label="Posting Type" 
              className="searchFormElement"
              data={postingTypes}
              {...form.getInputProps("postingTypeID")}/>
            <Group justify="flex-end" mt="md">
                <Button
                    onClick={(event) => {
                        navigate("/Account/Category/Type/List")
                    }}>
                    Back
                </Button>
                <Button type="submit">Add</Button>
            </Group> 
          </form>
        </Box>
      </>
    );
  }